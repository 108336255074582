import {NavLink} from "react-router-dom";
import { ReactComponent as NavJobsIcon } from '../../assets/images/icons/navJobs.svg';
import { ReactComponent as NavAgenciesIcon } from '../../assets/images/icons/navAgencies.svg';
import { ReactComponent as BackIcon } from '../../assets/images/icons/back.svg';
import { ReactComponent as SettingsIcon } from '../../assets/images/icons/settings.svg';
import { ReactComponent as HousingIcon} from "../../assets/images/icons/localContract.svg";

import './NavSearch.scss';

const NavSearch = ({ toggle, title, showResultItems, showMobileFilter, toggleMobileFilters, filters, hideSubNav }) => {

  return (
    <div  className="container-navbar-search-jobs">
      {!hideSubNav && (<nav className="sub-nav-search">
        <div className="navbar-search-jobs container-search">
          <div className="navbar-search-jobs--item">
            <NavLink className="link" activeClassName="selected" to="/nurse/jobs/search">Jobs</NavLink>
          </div>
          <div className="navbar-search-jobs--item">
            <a className="link" href="/nurse/agencies">Agencies</a>
          </div>
          <div className="navbar-search-jobs--item">
            <a className="link" href="/nurse/resources/housing">Housing</a>
          </div>
        </div>
      </nav>)}

      <nav className="container-fluid container-navbar-search-jobs mobile-nav only-mobile-md">
        <div className="navbar-search-jobs container-search">
          <div className="full-width text-center banner-search">
            {!showResultItems
              ? <div className="text">Set Your Criteria</div>
              : <>
                {
                  <>
                    <div className="text-start banner-search--item back" onClick={toggle}> <BackIcon className={'icon-white'} /> <span>BACK</span> </div>
                    <div className="banner-search--item text"> { title } </div>
                    <div className="banner-search--item settings text-end"> <SettingsIcon onClick={toggleMobileFilters} className={'icon-white'} />  </div>
                  </>
                }
              </>
            }
          </div>
          {!hideSubNav && (<>
            <div className={`navbar-search-jobs--item`}>
              <a aria-current="page" className="link selected" href="/nurse/jobs/search">
                <NavJobsIcon className="icon" fill="000" /> <span className="title">Jobs</span>
              </a>
            </div>
            <div className={`navbar-search-jobs--item`}>
              <a className="link" href="/nurse/agencies">
                <NavAgenciesIcon className="icon" fill="000"  /><span className="title">Agencies</span>
              </a>
            </div>
            <div className={`navbar-search-jobs--item `}>
              <a className="link" href="/nurse/resources/housing">
                <HousingIcon className="icon" fill="000" /> <span className="title">Housing</span>
              </a>
            </div>
          </>)}
        </div>
      </nav>
    </div>
  );
}

export default NavSearch;
