import React, { useEffect } from 'react';
import {DropdownSelectAsync} from '@orionhcs/wanda-js-components';
import {useDispatch, useSelector} from "react-redux";
import { getLocations, locationSelector, setLocationsOptionsDropdown } from '../../redux/slices/locations';
import { ReactComponent as IconLocation } from "../../assets/images/icons/location.svg";
import {JobsSelector, setFilters} from "../../redux/slices/jobs";
import {debounce} from "underscore";

import './LocationDropdown.scss';
import {
  populateLocationJobsCount,
  triggerTotalJobsCountForLocation,
} from '../../redux/slices/jobsCounter';
import { parseFilters } from '../../helpers/utils';

const LocationDropdown = ({ value, onChange, isControlled = false, triggerOnMenuClose = true }) => {
  const { optionsDropdown } = useSelector(locationSelector);
  const { filters: { profession_id, location } = {}, filters } = useSelector(JobsSelector);
  const dispatch = useDispatch();

  useEffect(async () => {
    await getOptionsLocations('', null, true);
  }, []);

  const getOptionsLocations = async (input, callback, skipValidation = false) => {
    if (!skipValidation && (!input || input.length === '')) {
      return Promise.resolve({ options: [] });
    }

    let locationJobsCount = null;
    let promisses = [];
    promisses.push(getLocations(input, true));

    if (!isControlled) {
      const params = parseFilters(filters);
      promisses.push(dispatch(triggerTotalJobsCountForLocation(params)));
    }
    const responses = await Promise.all(promisses);
    const locationLookUp = responses[0];
    const jobsCountLookUp = responses.length>1 ? responses[1] : null;

    const locationsWithCount = await populateLocationJobsCount(locationLookUp, jobsCountLookUp);

    await dispatch(setLocationsOptionsDropdown(locationsWithCount));

    if (callback) {
      callback(locationsWithCount);
    }
  }

  const onSelectOption = async (value) => {
    if (isControlled) {
      onChange(value ? value : null, '', 'locations')
    } else {
      dispatch(setFilters({
        location: value
      }));
    }
  };

  const onMenuClose = async () => {
    if (triggerOnMenuClose) {
      if (!isControlled) {
        await getOptionsLocations('', null, true);
      }
    }
  };

  const getOptions = debounce(getOptionsLocations, 800);

  const getValue = () => {
    if (isControlled) {
      return value;
    } else {
      return location;
    }
  }

  return (
    <DropdownSelectAsync
      isMulti
      noOptionsMessage={() => null}
      closeMenuOnSelect={true}
      hideSelectedOptions={false}
      icon={<IconLocation />}
      placeholder={"Enter Location"}
      classname={"location"}
      name="location"
      loadOptions={getOptions}
      defaultOptions={optionsDropdown}
      onChange={onSelectOption}
      onMenuClose={onMenuClose}
      value={getValue()}
      maxMenuHeight={150}
      cacheOptions={false}
    />
  );
};

export default LocationDropdown;
