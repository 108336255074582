import { createSlice } from '@reduxjs/toolkit';
import { Config } from '../../config';
import { parseFilters, parseQueryStringToFilters } from '../../helpers/utils';
import SuperFetch from '../../helpers/superFetch';
import {
  populateJobsCount,
  triggerTotalJobsCountForSpecialties,
} from './jobsCounter';

export const initialState = {
  filters: {
    typeJob: 'domestic',
    profession_id: null,
    specialty_ids: null,
    location: null,
    date: null,
    is_asap: false,
    rate: null,
    shift: [],
    assignment_length: [],
    hospital: null,
    agency: null,
    recruiter_ids: null,
    order_by: null,
    order: 'desc',
    sort_dropdown: null,
    initialRequest: true,
  },

  isLoading: true,
  data: [],
  jobsCount: null,
  errors: false,
  // Paginate
  page: 1,
  limit: 20,
  total: 0,
  totalPP: 0,
  payPackages: 0,
  queryStringJobs: null,
  // Job Search Page settings
  hideMobileResults: true,
  showProfessionUser: true,
  isRedirection: false,
  // For Homepage
  fetchingTotalPP: true,
  fetchingHighestPay: true,
  fetchingFeaturedJobs: true,
  totalPayPackages: 0,
  highestPayAmount: 0,
  featuredJobs: [],
  jobTypes: [],
};

const jobs = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    saveShift: (state, { payload }) => {
      state.filters.shift = payload;
      state.filters.initialRequest = false;
      state.isRedirection = false;
    },
    saveAssigment: (state, { payload }) => {
      state.filters.assignment_length = payload;
      state.filters.initialRequest = false;
      state.isRedirection = false;
    },
    clearTopFilters: (state, { payload = {} }) => {
      const { profession_id, typeJob } = payload;

      state.filters = {
        ...state.filters,
        typeJob: typeJob || 'domestic',
        profession_id: profession_id || null,
        specialty_ids: null,
        location: null,
        date: null,
        is_asap: false,
        rate: null,
        shift: [],
        assignment_length: [],
        hospital: null,
        agency: null,
        recruiter_ids: null,
        initialRequest: false,
        order_by: null,
        order: 'desc',
        sort_dropdown: null,
      };
      state.isRedirection = false;
    },
    clearSidebarFilters: (state, { payload }) => {
      state.filters = {
        ...state.filters,
        order_by: null,
        order: 'desc',
        rate: null,
        shift: [],
        assignment_length: [],
        hospital: null,
        agency: null,
        recruiter_ids: null,
        sort_dropdown: null,
        initialRequest: false,
      };
      state.isRedirection = false;
    },
    setFilters: (state, { payload }) => {
      state.filters = {
        ...state.filters,
        ...payload,
        initialRequest: false,
      };
      state.isRedirection = false;
    },
    setInitialFilters: (state, { payload }) => {
      state.filters = {
        ...state.filters,
        ...payload,
      };
      state.isRedirection = false;
    },
    fetchJobsLoading: (state) => {
      state.isLoading = true;
    },
    fetchJobsSuccess: (state, { payload }) => {
      state.data = payload.jobs;
      state.jobsCount = payload.jobsCount;
      state.isLoading = false;
      state.errors = false;
      state.total = payload.total;
      state.totalPP = payload.totalPP;
      state.payPackages = payload.payPackages;
      state.limit = payload.limit;
      state.page = payload.page;
      state.isRedirection = false;
    },
    fetchJobsFailure: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload;
    },
    setJobTypes: (state, { payload }) => {
      state.jobTypes = payload;
    },
    setHideMobileResults: (state, { payload }) => {
      state.hideMobileResults = payload;
    },
    setShowProfessionUser: (state, { payload }) => {
      state.showProfessionUser = payload;
    },
    setQueryStringJobs: (state, { payload }) => {
      state.queryStringJobs = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setIsRedirection: (state, { payload }) => {
      state.isRedirection = payload;
    },
    setFetchingTotalPP: (state, { payload }) => {
      state.fetchingTotalPP = payload.value;
    },
    setFetchingHighestPay: (state, { payload }) => {
      state.fetchingHighestPay = payload.value;
    },
    setFetchingFeaturedJobs: (state, { payload }) => {
      state.fetchingFeaturedJobs = payload.value;
    },
    fetchTotalPayPackages: (state, { payload }) => {
      state.totalPayPackages = payload.total;
      state.fetchingTotalPP = false;
    },
    fetchHighestPaymentAmount: (state, { payload }) => {
      state.highestPayAmount = payload.amount;
      state.fetchingHighestPay = false;
    },
    fetchFeaturedJobs: (state, { payload }) => {
      state.featuredJobs = payload.data;
      state.fetchingFeaturedJobs = false;
    }
  },
});

// A selector
export const JobsSelector = (state) => state.jobs;

// Actions
export const {
  saveShift,
  saveAssigment,
  clearTopFilters,
  clearSidebarFilters,
  setFilters,
  setInitialFilters,
  fetchJobsLoading,
  fetchJobsSuccess,
  fetchJobsFailure,
  setHideMobileResults,
  setShowProfessionUser,
  setJobTypes,
  setQueryStringJobs,
  setPage,
  setIsRedirection,
  setFetchingTotalPP,
  setFetchingHighestPay,
  fetchTotalPayPackages,
  fetchHighestPaymentAmount,
  fetchFeaturedJobs,
  setFetchingFeaturedJobs,
} = jobs.actions;

export const setShift = (data) => (dispatch, getState) => {
  const state = getState();
  const { shift } = state.jobs.filters;

  let newShift;
  if (shift.find((item) => item === data)) {
    newShift = shift.filter((item) => item !== data);
  } else {
    newShift = [...shift, data];
  }

  dispatch(saveShift(newShift));
};

export const setAssignment = (data) => (dispatch, getState) => {
  const state = getState();
  const { assignment_length } = state.jobs.filters;

  let newData;
  if (assignment_length.find((item) => item === data)) {
    newData = assignment_length.filter((item) => item !== data);
  } else {
    newData = [...assignment_length, data];
  }

  dispatch(saveAssigment(newData));
};

export const triggerSearchJobs = (params, isMobile = false) => {
  return async (dispatch, getState) => {
    if (!isMobile) dispatch(fetchJobsLoading());

    const state = getState();
    const { filters, data } = state.jobs;
    const newFilters = {
      ...filters,
      ...params,
      msf_off: true,
    };

    try {
      const parsedFilters = parseFilters(newFilters);
      const url = `${Config.WAPI_URL}/jobs?${parsedFilters}${
        localStorage.getItem('tokenId')
          ? `user_id=${localStorage.getItem('tokenId')}&`
          : ''
      }`;

      const jobsPromise = SuperFetch.get(url);
      const jobsCountPromise = dispatch(
        triggerTotalJobsCountForSpecialties(parsedFilters)
      );

      const responses = await Promise.all([jobsPromise, jobsCountPromise]);

      const response = responses[0];
      const specialtiesJobsCount = responses[1];

      const payload = {
        total: response.data.total,
        totalPP: response.data.totalPPCount,
        payPackages: response.data.payPackages,
        page: response.data?.params?.page || params.page,
        limit: response.data?.params?.limit || params.limit,
        jobsCount: {
          ...response.data.jobsCount,
          specialties: specialtiesJobsCount,
        },
      };

      if (isMobile) {
        payload.jobs = [...data, ...response.data.jobs];
      } else {
        payload.jobs = response.data.jobs;
      }

      dispatch(fetchJobsSuccess(payload));
      dispatch(setQueryStringJobs(parsedFilters));
      await dispatch(populateJobsCount(payload.jobsCount));
    } catch (error) {
      console.log(error);
      dispatch(fetchJobsFailure('Something was wrong!'));
    }
  };
};

export const setFiltersFromQueryString = (queryFilters, page) => {
  return async (dispatch, getState) => {
    const filters = await parseQueryStringToFilters(queryFilters);
    dispatch(setPage(page));
    dispatch(setInitialFilters({ ...filters }));
    dispatch(
      triggerSearchJobs({
        page,
        limit: 20,
      })
    );
  };
};

export const searchJobs = (page) => {
  return async (dispatch) => {
    dispatch(setPage(page));
    dispatch(
      triggerSearchJobs({
        page,
        limit: 20,
      })
    );
  };
};

export const getTotalPayPackages = () => {
  return async (dispatch) => {
    try {
      const url = `${Config.WAPI_URL}/jobs/total`;
      const response = await SuperFetch.get(url);

      const payload = {
        total: response.data.total,
      };

      dispatch(fetchTotalPayPackages(payload));
    } catch (error) {
      console.log(error);
      dispatch(setFetchingTotalPP({ value: false }));
    }
  };
};

export const getHighestPaymentAmount = (paymentType = 'weekly') => {
  return async (dispatch) => {
    try {
      const url = `${Config.WAPI_URL}/jobs/highest/${paymentType}`;
      const response = await SuperFetch.get(url);

      const payload = {
        amount: response.data.amount,
      };

      dispatch(fetchHighestPaymentAmount(payload));
    } catch (error) {
      console.log(error);
      dispatch(setFetchingHighestPay({ value: false }));
    }
  };
};

export const getFeaturedJobs = () => {
  return async (dispatch) => {
    try {
      const url = `${Config.WAPI_URL}/jobs/featured`;
      const response = await SuperFetch.get(url);

      if (response.success) {
        const payload = { data: response.data };
        dispatch(fetchFeaturedJobs(payload));
      } else {
        console.log(response.message);
        dispatch(setFetchingFeaturedJobs({ value: false }));
      }
    } catch (error) {
      console.log(error);
      dispatch(setFetchingFeaturedJobs({ value: false }));
    }
  };
};

export const getJobTypes = () => {
  return async (dispatch) => {
    try {
      const url = `${Config.WAPI_URL}/jobs/types`;
      const response = await SuperFetch.get(url);

      if (response.success) {
        dispatch(setJobTypes(response.data));
      }
    } catch (error) {
      console.error(error);
    }
  };
};

// The reducer
export default jobs.reducer;
