import {useHistory} from "react-router-dom";
import {paramsToUrl} from "../helpers/utils";
import {useSelector} from "react-redux";
import {JobsSelector} from "../redux/slices/jobs";

const useUpdateParams = () => {
  const history = useHistory();
  const { filters } = useSelector(JobsSelector);

  function updateParams(newFilters) {
    history.push({
      pathname: '/nurse/jobs/search',
      search: paramsToUrl( { ...filters, ...newFilters}, 1)
    });
  }

  function redirectToSearchPage(newFilters) {
    history.push({
      pathname: '/nurse/jobs/search',
      search: paramsToUrl( { ...filters, ...newFilters}, 1)
    });
  }

  function redirectToLocationPage(city) {
    window.location.replace(`/jobs/travel/${city}`);
  }

  return [
    updateParams,
    redirectToSearchPage,
    redirectToLocationPage
  ];
};

export default useUpdateParams;
