import React from 'react';
import { format } from 'date-fns';

import { ReactComponent as CalendarIcon } from '../../assets/images/icons/calendar.svg';
import { ReactComponent as AssignmentIcon } from '../../assets/images/icons/duration-fade.svg';
import { ReactComponent as CheckSimilarJobs } from '../../assets/images/pages/landing/check-similar-jobs.svg';
import { ReactComponent as UncheckSimilarJobs } from '../../assets/images/pages/landing/uncheck-similar-jobs.svg';
import { ReactComponent as CheckmarkIcon } from '../../assets/images/icons/checkmarkWhite.svg';
import {
  formatPayRate,
  getExperienceLevel,
  getIconShift,
  getProfession,
} from '../../helpers/utils';
 import Rate from '../Rate';
import RCTooltip from '../RCTooltip';
import { startDateNoTz } from '../../helpers/utils';

import css from './SimilarJobCard.module.scss';

const SimilarJobCard = ({ data, checked, onShowDetailsClick, onClickJobCard, cssClass = '' }) => {
  if (!data) {
    return null;
  }

  return (
    <div
      className={`${css.root} ${checked ? css.checked : ''} ${cssClass}`}
      data-isViewDetail={false}
      onClick={(e) => onClickJobCard(e, data.job_id)}
    >
      {checked ? (
        <span className={css['corner-check-icon']}>
          <CheckmarkIcon />
        </span>
      ) : (
        <span className={css['corner-uncheck-icon']}>
          <UncheckSimilarJobs />
        </span>
      )}
      <div className={css['main-info']}>
        <div style={{ minWidth: '66px' }}>
          {data.agency_logo ? (
            <img alt={data.agency_name} className={css.logo} src={data.agency_logo} />
          ) : (
            <strong>{data.agency_name}</strong>
          )}
        </div>
        <div className={css['description-job']}>
          <div className={css['main-desc']}>
            <div className={css.profession}>{getProfession(data.profession_id)}</div>
            <RCTooltip
              id={`specialities-${data?.id}`}
              msg={data?.specialty.map((specialty) => (
                <>
                  <span className={css['middle-dot']}>•</span>
                  {specialty}
                </>
              ))}
            >
              <div className={css.specialties}>
                <span style={{ marginRight: '2px' }}>•</span>
                {data.specialty.join('•')}
              </div>
            </RCTooltip>
          </div>
          <div className={css.location}>{`${data.location_city}, ${data.location_state}`}</div>
          <div className={css['features-job']}>
            <div>
              <AssignmentIcon className={css['features-icon']} fill="#ABABAB" />{' '}
              <>
                {data.job_type === 1 || data.job_type === 3 ? (
                  <>{data.assignment_length} weeks</>
                ) : (
                  <>
                    {data.job_type === 4 || data.job_type === 5 ? (
                      <>
                        {data.assignment_length > 0 ? (
                          <>
                            {data.assignment_length}
                            {data.assignment_length_type === 2 ? (
                              <>{data.assignment_length > 1 ? ' days' : ' day'}</>
                            ) : (
                              <>{data.assignment_length > 1 ? ' weeks' : ' week'}</>
                            )}
                          </>
                        ) : (
                          'Ongoing'
                        )}
                      </>
                    ) : (
                      <>{getExperienceLevel(+data.experience_level)}</>
                    )}
                  </>
                )}
              </>
            </div>
            <div>
              {' '}
              {getIconShift(data.shift_name, css['features-icon'])}
              <>
                {data.shift === 'Rotating' ? 'Rot' : data.shift === 'Midshift' ? 'Mid' : data.shift}{' '}
                {data.shifts_per_week} <small>x</small> {parseFloat(data.hours_per_shift)} hr
              </>
            </div>
            <div>
              <CalendarIcon className={css['features-icon']} />{' '}
              {data.is_asap ? 'ASAP' : format(startDateNoTz(data.start_date), 'MMM d, yyyy')}
            </div>
          </div>
        </div>
      </div>
      <div className={css['price-info']}>
        {/*<div className={css['view-details']}>
          <button
            data-isViewDetail={true}
            onClick={(e) => onShowDetailsClick(e, data)}
            type="button"
            style={{ padding: '5px' }}
          >
            {' '}
            View Details <CaretDown stroke="#475467" data-isViewDetail={true} />{' '}
          </button>
        </div>*/}
        <div className={css['price-container']}>
          <Rate data={data} />
        </div>
      </div>
    </div>
  );
};

export default SimilarJobCard;
