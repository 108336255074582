import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

import Home from "./pages/Home";
import Search from "./pages/Search";
import Preferences from "./pages/Preferences";
import TravelNursingCities from "./pages/TravelNursingCities";
import SignUpQuestions from './components/SignUpQuestions';

function App() {
  return (
    <>
      <SignUpQuestions />
      <Router onUpdate={() => window.scrollTo(0, 0)}>
        <QueryParamProvider ReactRouterRoute={Route}>
          <Switch>
            <Route component={Home} exact path="/" />
            <Route component={Home} exact path="/nurse" />
            <Route component={Home} exact path="/nurse/emailconfirm/:email" />
            <Route component={Home} exact path="/nurse/confirm/:email/:token" />

            <Route component={Search} exact path="/nurse/agencies" />
            <Route component={Preferences} path="/nurse/accountsettings/preference" />

            <Route component={Search} exact path="/nurse/jobs/search" />
            <Route component={Search}  path="/jobs/:wildcards" />
            <Route component={Search}  path="/agency/:agency/jobs" />

            <Route component={TravelNursingCities} exact path="/nurse/jobs/travel-nursing-destinations" />
          </Switch>
        </QueryParamProvider>
      </Router>
    </>
  );
}

export default App;
