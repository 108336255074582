import css from './no-similar-jobs.module.scss';
import { Button } from '@orionhcs/wanda-js-components';
import { ReactComponent as SearchIcon } from '../../assets/images/icons/searchIcon.svg';

const NoSimilarJobs = ({ onBrowseAllJobs }) => (
  <div className="d-flex justify-content-center">
    <div className={`${css['root']}`}>
      <div className={`${css['container']} d-flex flex-column align-items-center`}>
        <div className={`${css['search-icon']}`}>
          <SearchIcon />
        </div>
        <div className={`${css['text']}`}>Expand your search criteria to get the perfect job.</div>
        <div className={`col-12 d-flex ${css['action-buttons']}`}>
          <Button
            className={`${css['submit-btn']} py-2 px-5`}
            type="submit"
            onClick={onBrowseAllJobs}
            height="56px"
            width="276px"
          >
            BROWSE ALL JOBS
          </Button>
        </div>
      </div>
    </div>
  </div>
);

export default NoSimilarJobs;
