import React from 'react';
import MaskedInput from 'react-text-mask';
import { ReactComponent as Verified } from '../../../assets/images/icons/icon-green-circle-checkmark.svg';
import ReactTooltip from 'react-tooltip';

import css from './MaskedInput.module.scss';

const InputMask = ({
  value,
  onChange,
  name,
  className,
  mask,
  maskPlaceholder,
  style,
  disabled,
  errors,
  label = false,
  labelClass = '',
  required = false,
  verified = false,
  tooltip ='',
  ...rest
}) => {
  const onInputChange = (e) => {
    onChange && onChange(e.target.value);
  };

  const maskRegExp = {
    phone: [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  };

  return (
    <div className={`relative custom-input-text ${errors ? 'with-erros' : ''}`}>
      {label && (
        <label className={`${labelClass || ''} label`} htmlFor={name}>
          {label}{required && <span className="required-asterisk">*</span>}
          {tooltip && 
            <span data-tip data-for='input-masked-tooltip'>
              <img width='20px' height='20px' src={'/images/icons/icon-info.png'} className={'tooltip-icon tooltip-icon-verified'} />
            </span>}
        </label>
      )}
      {tooltip && (
        <>
          <ReactTooltip 
            className="rc-tooltip"
            effect="solid"
            place="top"
            multiline={true}
            id='input-masked-tooltip'>
            {tooltip}
          </ReactTooltip>
        </>
      )}
      <MaskedInput
        className={`${css['mask-input']} ${className} ${disabled ? css['disabled-input'] : ''}`}
        disabled={disabled}
        id={name}
        mask={maskRegExp[mask]}
        name={name}
        onChange={onInputChange}
        type="text"
        value={value}
        {...rest}
      />
      {errors && <div className="invalid-message" dangerouslySetInnerHTML={{__html: errors.message}} />}
      {verified && <Verified style={{width:'24px', height:'24px', position: 'absolute', bottom:'7px', right:'7px'}}/>}
    </div>
  );
};

export default InputMask;
