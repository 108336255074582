import {createSlice} from "@reduxjs/toolkit";
import {Config} from "../../config";
import SuperFetch from "../../helpers/superFetch";
import {getHospitalsAsync, getHospitalsAsyncByName, getSpecialtiesAsync} from "../../helpers/utils";

export const initialState = {
  preference: {
    isLoading: true,
    data: null,
    errors: false,
  },
}

const accountSettings = createSlice({
  name: 'accountSettings',
  initialState,
  reducers: {
    fetchPreference: state => {
      state.preference.isLoading = true
    },
    fetchPreferenceSuccess: (state, {payload}) => {
      state.preference.isLoading = true
      state.preference.errors = false
      state.preference.data = payload
    },
    fetchPreferenceFailure: (state, {payload}) => {
      state.preference.isLoading = false
      state.preference.errors = payload
    },
  },
})

// A selector
export const AccountSettingsSelector = state => state.accountSettings;

// Actions
export const {
  fetchPreference,
  fetchPreferenceSuccess,
  fetchPreferenceFailure,
} = accountSettings.actions;

export const getPreference = () => {
  return async (dispatch, getState) => {
    dispatch(fetchPreference());
    try {
      const url = `${Config.WAPI_URL}/accountsettings/preference`;
      const response = await SuperFetch.get(url);

      if (response.data) {
        const { specialties, locations, facility, start_date } = response.data

        if (specialties && specialties.length > 0) {
          response.data.specialties = await getSpecialtiesAsync(specialties, '');
        }

        if (locations && locations.length > 0) {
          let data = [];
          locations.forEach((item) => {
            data = [ ...data, { value: item.trim().replace('**', ', '), label: item.trim().replace('**', ', '), urlValue: item.trim() }];
          });

          response.data.locations =  data;
        }

        if (facility && facility.length > 0) {


          if (typeof facility[0] === 'string') {
            const facilities = facility.map((item) => item.split(',')[0]);
            response.data.facility = await getHospitalsAsyncByName(facilities);

          } else {
            let data = [];
            facility.forEach( (item) => {
                data = [ ...data, { value: item.id, label: item.name }];
            });
            response.data.facility = data;
          }
          console.log( response.data.facility)
        }

        response.data.start_date = Math.floor(start_date * 1000);
        response.data.is_asap = response.data.start_date === 0;
        response.data.job_proposals = response.data.job_proposals ? response.data.job_proposals : false;
      }


      dispatch(fetchPreferenceSuccess(response.data));
    } catch (error) {
      console.log(error)
      dispatch(fetchPreferenceFailure(error));
    }
  }
};

export const savePreference = async (data) => {
  try {
    const url = `${Config.WAPI_URL}/accountsettings/preference`;
    const response = await SuperFetch.post(url, data);

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// The reducer
export default accountSettings.reducer;
