import { useEffect } from 'react';
import { ReactComponent as LoadingSpinner } from '../../assets/images/loading/spinner.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getHighestPaymentAmount, getTotalPayPackages, JobsSelector } from '../../redux/slices/jobs';
import CandidateAppManager from "./CandidateAppManager";

import './StatCounter.style.scss';

const StatCounter = ({ showWanderlyApp }) => {
  const { fetchingTotalPP, fetchingHighestPay, totalPayPackages, highestPayAmount } = useSelector(JobsSelector);
  const dispatch = useDispatch();

  useEffect(async () => {
    await dispatch(getTotalPayPackages());
    await dispatch(getHighestPaymentAmount());
  }, []);

  if ((totalPayPackages === 0 && !fetchingTotalPP) && (highestPayAmount === 0 && !fetchingHighestPay)) {
    return null
  }

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const getTotalPayPackagesAvailable = () => {
    return <>
      {totalPayPackages === 0 && !fetchingTotalPP ? null : (<div className="stats-counter-box">
        <div className={'stats-counter-content'}>
          {fetchingTotalPP ? (
            <LoadingSpinner width={50} stroke={'#ffffff'} />
          ) : (
            numberWithCommas(totalPayPackages)
          )}
        </div>
        <div className="stats-counter-title">PAY PACKAGES AVAILABLE</div>
      </div>)}
    </>
  }

  const getHighestWeeklyPay = () => {
    return <>
      {highestPayAmount === 0 && !fetchingHighestPay ? null : <div className="stats-counter-box">
        <div className={'stats-counter-content'}>
          {fetchingHighestPay ? <LoadingSpinner width={50} stroke={'#ffffff'} /> : <>
            <sup className={'stats-counter-sign'}>$</sup> {numberWithCommas(+highestPayAmount.toFixed(0))}
          </>}
        </div>
        <div className="stats-counter-title">HIGHEST WEEKLY PAY</div>
      </div>}
    </>
  }

  return (<>
    { showWanderlyApp
      ? ( <section className="wanderly-app-section">
        <div className="home-container-fluid">
          <div className="row pt-4 pb-5 pt-md-3 pb-md-0 stat-counter-section">
            <div className="col-12 col-md-7 pe-sm-3 stats-counter stats-counter-main">
              {getTotalPayPackagesAvailable()}
              {getHighestWeeklyPay()}
            </div>
            <div className="col-12 col-md-5 pt-sm-3 ps-sm-0 pe-sm-5">
              <CandidateAppManager />
            </div>
          </div>
        </div>
      </section>)
      :
      (<section className="stat-counter-section">
        <div className="align-center stats-counter container py-4">
          {getTotalPayPackagesAvailable()}
          {getHighestWeeklyPay()}
       </div>
      </section>)
    }
  </>)
};

export default StatCounter;