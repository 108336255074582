import Tooltip from 'rc-tooltip';
import React, { useCallback, useState } from 'react';

const RCTooltip = ({ id, msg, children }) => {
  const [show, setShow] = useState(false);

  const onMouseEnter = useCallback(() => setShow(true), []);
  const onMouseLeave = useCallback(() => setShow(false), []);

  return (
    <Tooltip
      destroyTooltipOnHide={!show}
      id={id}
      onMouseEnter={() => onMouseEnter()}
      onMouseLeave={() => onMouseLeave()}
      overlay={msg}
      placement="top"
      prefixCls="custom-tooltip"
      showArrow
      visible={show}
    >
      {children}
    </Tooltip>
  );
};

export default RCTooltip;
