import React from "react";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {WrapperPagination} from "../Pagination";
import {goToTop, paramsToUrl} from "../../helpers/utils";
import {triggerSearchJobs} from "../../redux/slices/jobs";

const DesktopCards = ({ jobCards, currentPage, total, limit, filters }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const changePage = (page) => {
    history.push({ search: paramsToUrl(filters, page) });
    dispatch(triggerSearchJobs({ limit: 20, page }))
    goToTop();
  }

  return (
    <>
      {jobCards}
      {
        total > 1 && <WrapperPagination
          current={currentPage}
          pageSize={limit}
          total={total}
          onPageChange={(page) => {
            changePage(page)
          }}
          onSizeChange={( page, perPage ) => dispatch(triggerSearchJobs({ limit: perPage, page }))}
          itemsName="jobs"
        />
      }
    </>
  );
}

export default DesktopCards;
