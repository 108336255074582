import css from './Citycard.module.scss'

const CityCard = ({ city }) => {

  return (<a href={`/jobs/${city.slug}`}  className={`flex flex-column ${css['city-card']}`}>
    <div className={`img-container`}>
      <img src={city.thumb_image || "/images/search/cities/san-diego.png"} alt={city.name}/>
    </div>
    <div className={`flex flex-column info-container`}>
      <div className={css['city-name']}>
        {city.name}{city.state && `, ${city.state}`}
      </div>
      <div className={css['city-jobs']}>
        {city.total_jobs} Jobs
      </div>
    </div>
  </a>)
}

export default CityCard;
