import { configureStore } from '@reduxjs/toolkit';

import jobs from './slices/jobs';
import jobsCounter from './slices/jobsCounter';
import job from './slices/job';
import professions from './slices/professions';
import locations from './slices/locations';
import specialties from './slices/specialties';
import nurse from './slices/nurse';
import notifications from './slices/notifications';
import recruiters from './slices/recruiters';
import accountSettings from './slices/accountSettings';

export default configureStore({
  reducer: {
    job,
    jobs,
    jobsCounter,
    professions,
    locations,
    specialties,
    nurse,
    recruiters,
    accountSettings,
    notifications,
  },
});
