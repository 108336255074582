import { createSlice } from '@reduxjs/toolkit';

import { Config } from '../../config';
import SuperFetch from '../../helpers/superFetch';

export const initialState = {
  inbox: 0,
  errors: false,
  loading: false,
};

// A slice for recipes with our 3 reducers
const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    // Chats
    fetchNotifications: (state) => {
      state.loading = true;
    },
    fetchNotificationsSuccess: (state, { payload }) => {
      state.inbox = payload.inbox + payload.proposals;
      state.loading = false;
      state.errors = false;
    },
    fetchNotificationsFailure: (state) => {
      console.log(state, ' in failutre');
      state.loading = false;
      state.errors = true;
    },
  },
});

// A selector
export const NotificationsSelector = (state) => state.notifications;

// Three actions generated from the slice
export const {
  fetchNotifications,
  fetchNotificationsSuccess,
  fetchNotificationsFailure,
} = notificationsSlice.actions;

export const getNotifications = () => async (dispatch) => {
  dispatch(fetchNotifications());

  const url = `${Config.WAPI_URL}/notifications/totals/inbox`;

  try {
    const { data } = await SuperFetch.get(url);
    dispatch(fetchNotificationsSuccess(data));
  } catch (err) {
    fetchNotificationsFailure(err);
  }
};

// The reducer
export default notificationsSlice.reducer;
