// eslint-disable-next-line consistent-return
import {getCookie} from "./auth";
import {Config} from "../config";

const base = async (method, url, body = null) => {
  const headers = {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
    'X-CSRF-TOKEN': window.Laravel ? window.Laravel.csrfToken : null,
    'X-Requested-With': 'XMLHttpRequest',
    'X-Wanda-Referrer': window.location.href,
    'X-Wanda-Cookie' : getCookie(`wandawanda${Config.ENV}`),
    'X-WANDA-UTM-PARAMS' : getCookie(`utm_params`),
    'X-Wanda-Appname': 'wap'
  };

  const wanda_tracking_id = getCookie('wtid');
  const trackedUrl = new URL(url, window.location.href);
  if(wanda_tracking_id){
    trackedUrl.searchParams.append('wtid', wanda_tracking_id);
  }

  try {
    const token = localStorage.getItem('accessToken');
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
  } catch (error) {
    console.log(error);
  }

  const options = {
    method,
    headers,
  };

  // Add JWT token
  if (body) options.body = JSON.stringify(body);

  try {
    return (await fetch(trackedUrl, options)).json();
  } catch (error) {
    console.error(error);
  }
};

const SuperFetch = {};

['get', 'post', 'put', 'delete', 'patch'].forEach((method) => {
  SuperFetch[method] = base.bind(null, method);
});

// eslint-disable-next-line import/prefer-default-export
export default SuperFetch;
