import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {DropdownSelectAsync} from '@orionhcs/wanda-js-components';
import {JobsSelector, setFilters} from "../../redux/slices/jobs";
import {Config} from "../../config";
import SuperFetch from "../../helpers/superFetch";
import {debounce} from "underscore";

import './FaciltyDropdown.style.scss';

const FacilityDropdown = ({ value, onChange, isControlled = false }) => {
  const [optionsDropdown, setOptionsDropdown] = useState([]);
  const {filters: { hospital }  = {} } = useSelector(JobsSelector);
  const dispatch = useDispatch();

  const getFacilities = async (input, callback) => {
    if (!input || input.length === '') {
      return Promise.resolve({ options: [] });
    }

    const url = `${Config.WAPI_URL}/jobs/suggestion/hospital/?text=${input}`;
    const response = await SuperFetch.get(url);

    console.log();
    const options = Object.values(response.data).map((l) => ({ value: l.id, label: l.name }))
    setOptionsDropdown(options);
    callback(options);
  }

  const onSelectOption = (value) => {
    if (isControlled) {
      onChange( value ? value : null, '', 'facility')
    } else {
      dispatch(setFilters({
        hospital: value
      }));
    }

  };

  const resetOptions = () => setOptionsDropdown([]);
  const getOptions = debounce(getFacilities, 800);

  const getValue = () => {
    if (isControlled) {
      return value;
    } else {
      return hospital;
    }
  }

  return (
    <DropdownSelectAsync
      isMulti
      noOptionsMessage={() => null}
      closeMenuOnSelect={true}
      hideSelectedOptions={false}
      icon={null}
      placeholder={"Enter Facility Name"}
      classname={"facility"}
      name="facility"
      loadOptions={getOptions}
      cacheOptions={false}
      defaultOptions={optionsDropdown}
      onChange={onSelectOption}
      value={getValue()}
      maxMenuHeight={150}
      onMenuClose={resetOptions}
    />
  );
};

export default FacilityDropdown;
