import {createSlice} from "@reduxjs/toolkit";
import {Config} from "../../config";
import SuperFetch from "../../helpers/superFetch";

export const initialState = {
  optionsDropdown: [],
  selected: null
}

// A slice for recipes with our 3 reducers
const locationSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    setLocationsOptionsDropdown : (state, {payload}) => {
      state.optionsDropdown =  payload;
    }
  },
})

// A selector
export const locationSelector = state => state.locations;

// Action
export const {
  setLocationsOptionsDropdown,
} = locationSlice.actions;

// The reducer
export default locationSlice.reducer;

// Asynchronous thunk action
export const getLocations = async (term, skipEmptyValidation = false) => {
    if (!skipEmptyValidation && (!term || term.length === '')) {
      return;
    }

    try {
      const url = `${Config.WAPI_URL}/jobs/suggestion/location/?text=${term}`;
      const response = await SuperFetch.get(url);

      return response.data.map((item) => {
        return {
          value: item,
          label: item,
          urlValue: item.replace(', ', '**')
        };
      });
    } catch (error) {
      console.log(error)
    }
}
