import React from 'react';
import Select, { components } from 'react-select';
import { DropdownSelectAsyncCustomStyles } from '../../helpers/utils';

import './DropdownControl.scss';
import _uniqueId from 'lodash/uniqueId';

const Control = ({ children, ...props }) => {
  const { icon = null } = props.selectProps;
  const { isMulti } = props;
  const newIcon = icon
    ? {
        ...icon,
        props: {
          style: {
            marginTop: '-4px',
            marginLeft: '10px',
            width: '15px',
            height: '15px',
          },
        },
      }
    : null;

  return (
    <components.Control {...props} className={isMulti ? 'is-multi' : ''}>
      {icon && <span style={{ display: 'flex' }}>{newIcon}</span>}
      {children}
    </components.Control>
  );
};

const Checkbox = ({
  onChange,
  isChecked,
  className,
  disabled = false,
  color = '',
}) => {
  const id = _uniqueId('checkbox-');
  return (
    <label htmlFor={id} className={`checkbox ${className}`.trim()}>
      <input
        type="checkbox"
        id={id}
        disabled={disabled}
        checked={isChecked}
        onChange={onChange}
      />
    </label>
  );
};

const Option = (props) => {
  const { isMulti, selectProps, data } = props;

  return (
    <components.Option {...props} className={'option'}>
      {isMulti && (
        <Checkbox
          onChange={() => null}
          isChecked={props.isSelected}
          color={selectProps.optionColor}
          className={'checkbox'}
        />
      )}
      <label className={'ellipsis-text p-right-10'}>{props.label}</label>{' '}
      {data.count || +data.count === 0 ? (
        <span className={'jobs-count'}>{data.count}</span>
      ) : null}
    </components.Option>
  );
};

const DropdownControl = React.forwardRef(
  ({ customComponents, classname, ...props }) => {
    return (
      <Select
        hideSelectedOptions={false}
        classNamePrefix={`${classname} dropdown`}
        multi={false}
        simpleValue={true}
        closeMenuOnSelect={true}
        isSearchable={false}
        isClearable={false}
        styles={DropdownSelectAsyncCustomStyles}
        maxMenuHeight={150}
        components={{
          Control,
          Option,
          ...customComponents,
        }}
        {...props}
      />
    );
  }
);

export default DropdownControl;
