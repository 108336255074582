import React from 'react';
import { ReactComponent as WanderlyWhiteLogo } from '../../assets/images/logos/wanderly-white.svg';


export const MobileNav = (props) => {
  let backTitle = props.backTitle || 'BACK';
  let pageTitle = props.pageTitle || '';
  let backClick = props.backClick || null;

  return (
    <div className="mobile-mode">
      <nav className="navbar navbar-default navbar-fixed-top modal" role="navigation">
        <div>
          <div className="text-center relative">
            <div className="left-content">
              {backClick ?
                <a className="btn btn-link btn-sm btn-back" onClick={() => backClick()}>
                  <i className="glyphicon glyphicon-chevron-left"/>
                  <span>{backTitle}</span>
                </a>
                : null}
            </div>
            <div className="mobile-page-title">
              <WanderlyWhiteLogo className="nav-menu-logo" />
            </div>
          </div>
        </div>
      </nav>
    </div>);
};

