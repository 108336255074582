import { ReactComponent as NextArrowIcon } from '../../assets/images/icons/carouselNextTeal.svg';
import { ReactComponent as PrevArrowIcon } from '../../assets/images/icons/carouselPrevTeal.svg';

const NextArrow = ({ onClick }) => (
  <div className="carousel-next" onClick={onClick}>
    <NextArrowIcon />
  </div>
);

const PrevArrow = ({ onClick }) => (
  <div className="carousel-prev" onClick={onClick}>
    <PrevArrowIcon />
  </div>
);

export const sliderSettings = {
  centerMode: true,
  rows: 1,
  centerPadding: 0,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  dotsClass: 'carousel-dots mt-4 mb-0',
  slidesToShow: 5,
  slidesToScroll: 5,
  dots: true,
  responsive: [
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        dots: false,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: false,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: false,
      },
    },
    {
      breakpoint: 1802,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
        dots: true,
      },
    }
  ]
}