import Slider from 'react-slick';
import { ReactComponent as NextArrowIcon } from '../../assets/images/icons/carouselNextTeal.svg';
import { ReactComponent as PrevArrowIcon } from '../../assets/images/icons/carouselPrevTeal.svg';
import './BlogsSection.style.scss';

const NextArrow = ({ onClick }) => (
  <div className="carousel-next" onClick={onClick}>
    <NextArrowIcon />
  </div>
);

const PrevArrow = ({ onClick }) => (
  <div className="carousel-prev" onClick={onClick}>
    <PrevArrowIcon />
  </div>
);

const MAX_TAGS = 2;

const Blogs = ({ posts }) => {
  const settings = {
    centerMode: true,
    rows: 1,
    centerPadding: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    dotsClass: 'carousel-dots mt-4 mb-0',
    slidesToShow: 4,
    dots: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  return (
    <section className="blogs-section">
      <div className="home-container-fluid">
        {posts && posts.length && (
          <div className="py-4">
            <h2 className="text-center font-weight-bold pb-4 m-0">
              On the Blog
            </h2>
            <Slider {...settings}>
              {posts.map(({ featured_image, title, link, category }, i) => (
                <div className="home-carousel-item p-0" key={i}>
                  <a
                    className={'item-container'}
                    href={link}
                    style={{
                      backgroundImage: `url(${featured_image.size_medium})`,
                      backgroundSize: 'cover'
                    }}
                  >
                    <div className="info">
                      <h3 className="font-weight-bold p-0">{title.rendered}</h3>
                      {category &&
                        category.length &&
                        category.map(({ name }, index) => (
                          <div key={index}>
                            {index + 1 <= MAX_TAGS && (
                              <span className="p-0 m-0">
                                {name}
                                {index + 1 < MAX_TAGS && <span>, </span>}
                              </span>
                            )}
                          </div>
                        ))}
                    </div>
                  </a>
                </div>
              ))}
            </Slider>
          </div>
        )}
      </div>
    </section>
  );
};

export default Blogs;
