import {hasAccessToken} from "./auth";

export const switchNavFooter = () => {
  const currentActiveFooterElement = document.querySelector(".nurse-navbar-mobile ul li .active");

  if (currentActiveFooterElement) {
    currentActiveFooterElement.classList.toggle('active');

    const jobsItemFooterMenu = document.querySelector(".nurse-navbar-mobile ul .jobs-item-menu span");
    jobsItemFooterMenu.classList.toggle('active');
  }

  const navBarMain = document.querySelector('.navbar-main');
  if (navBarMain) {
    navBarMain.classList.toggle('hidden');
  }

  const elementBody = document.querySelector('#desktop-mode');
  if (elementBody) {
    elementBody.classList.add('search-mobile-padding-bottom');
    elementBody.style.removeProperty('padding-bottom');
  }

  const bannerWanderly =  document.getElementById("banner-wanderly");
  if (bannerWanderly) {
    bannerWanderly.setAttribute( 'style', 'display: none !important' );
  }

  addClass('.main-footer', 'visibility-hidden');
}

export const setHomepageElements = () => {
  const element = document.getElementById("nurse");
  if (element) {
    element.classList.add('relative');
  }

  const navBar = document.querySelector(".navbar-main");
  if (navBar) {
    navBar.style.backgroundColor = '#00b5cf';
    navBar.setAttribute( 'style', 'display: none !important' );
  }

  const navBarMobile = document.querySelector(".navbar-fixed-top");
  if (navBarMobile) {
    navBarMobile.style.backgroundColor = '#00b5cf';
    navBarMobile.setAttribute( 'style', 'display: none !important' );
  }

  const bannerWanderly =  document.getElementById("banner-wanderly");
  if (bannerWanderly) {
    bannerWanderly.style.removeProperty('display');
  }

  addClass('.home-async-mobile', 'active');
  removeClass('.search-async-mobile', 'active');
  addClass('.home-nav-icon', 'active');
  removeClass('.search-nav-icon', 'active');
  removeClass('.main-footer', 'visibility-hidden');
  removeClass('.search-async', 'menu-active');
  removeClass('.search-async', 'menu-active');

  if (!hasAccessToken()) {
    addClass('.footer', 'visibility-hidden');
  }

  document.title = "Compare Healthcare Jobs From Leading Agencies";
}

export const setSearchPageElements = () => {
  const navBar =  document.querySelector(".navbar-main");
  if (navBar) {
    navBar.style.removeProperty('display');
    navBar.style.backgroundColor = '#FAC00A';
  }

  const navBarMobile =  document.querySelector(".navbar-fixed-top");
  if (navBarMobile) {
    navBarMobile.style.backgroundColor = '#FAC00A';
  }

  removeClass('.footer', 'visibility-hidden');
}

export const addClass = (selector, classToAdd) => {
  const element = document.querySelector(selector);
  if (element) {
    element.classList.add(classToAdd);
  }
}

export const removeClass = (selector, classToAdd) => {
  const element = document.querySelector(selector);
  if (element) {
    element.classList.remove(classToAdd);
  }
}

export const toogleClassToMenu = (selector, classname) => {
  const element = document.querySelector(selector);
  if (element) {
    element.classList.toggle(classname)
  }
}

export const activeSearchMenu = () => {
  if (hasAccessToken()) {
    addClass('.search-async', 'menu-active');
  } else {
    addClass('.search-async', 'menu-active');
  }

  toggleMobileMenuClasses();
}

export const toggleMobileMenuClasses = () => {
  toogleClassToMenu('.search-async-mobile', 'active');
  toogleClassToMenu('.home-async-mobile', 'active');

  toogleClassToMenu('.home-nav-icon', 'active');
  toogleClassToMenu('.search-nav-icon', 'active');
}

export const addRelativeFooter = () => {
  const element = document.querySelector('.main-footer');
  if (element) {
    element.setAttribute( 'style', 'position: relative !important');
  }

  const logoMobile = document.querySelector('.nav-menu-mobile');
  if (logoMobile) {
    logoMobile.setAttribute( 'style', 'display: none !important');
  }

  const elementBody = document.querySelector('#desktop-mode');
  if (elementBody) {
    elementBody.classList.add('no-padding');
  }
}
