import React from 'react';
import { ReactComponent as TravelIcon } from '../../assets/images/icons/travel.svg';
import { ReactComponent as LocalContractIcon } from '../../assets/images/icons/localContract.svg';
import { ReactComponent as PermanentIcon } from '../../assets/images/icons/permanent.svg';
import { ReactComponent as IRPIcon } from '../../assets/images/icons/irp.svg';
import { ReactComponent as PerDiemIcon } from '../../assets/images/icons/perDiem.svg';

const Tab = ({
  type,
  icon,
  label,
  displayIcon,
  selectedTypeJob = 'travel',
  setTypeOfJob,
}) => (
  <button
    className={`btn ${selectedTypeJob === type ? 'btn--active' : ''}`}
    type="button"
    onClick={() => {
      setTypeOfJob(type);
    }}
  >
    {displayIcon() && icon(selectedTypeJob, type)}
    <span>{label()}</span>
  </button>
);

const JOB_TYPES = [
  {
    type: 'domestic',
    icon: (selectedTypeJob, type) => (
      <TravelIcon
        className={`icon icon-black ${
          selectedTypeJob === type ? 'icon-white' : ''
        }`}
      />
    ),
    label: 'Travel',
  },
  {
    type: 'permanent',
    icon: (selectedTypeJob, type) => (
      <PermanentIcon
        className={`icon icon-black ${
          selectedTypeJob === type ? 'icon-white' : ''
        }`}
      />
    ),
    label: 'Permanent',
  },
  {
    type: 'local',
    icon: (selectedTypeJob, type) => (
      <LocalContractIcon
        className={`icon icon-black ${
          selectedTypeJob === type ? 'icon-white' : ''
        }`}
      />
    ),
    label: 'Local',
  },
  {
    type: 'irp',
    icon: (selectedTypeJob, type) => (
      <IRPIcon
        className={`icon icon-black ${
          selectedTypeJob === type ? 'icon-white' : ''
        }`}
      />
    ),
    label: 'Internal Resource Pool',
  },
  {
    type: 'perDiem',
    icon: (selectedTypeJob, type) => (
      <PerDiemIcon
        className={`icon icon-black ${
          selectedTypeJob === type ? 'icon-white' : ''
        }`}
      />
    ),
    label: 'Per Diem',
  },
];

const TypeJobsContainer = ({
  selectedTypeJob,
  setTypeOfJob,
  isLargeScreen,
}) => (
  <div className="type-jobs-container">
    {JOB_TYPES.map(({ type, label, icon }) => {
      return (
        <Tab
          label={() => (type === 'irp' && !isLargeScreen ? 'IRP' : label)}
          selectedTypeJob={selectedTypeJob}
          type={type}
          icon={icon}
          setTypeOfJob={setTypeOfJob}
          displayIcon={() => {
            return !!isLargeScreen;
          }}
        />
      );
    })}
  </div>
);

export default TypeJobsContainer;
