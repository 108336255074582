import React, {useEffect, useRef, useState} from "react";

const useOutsideAlerter = ( wrapperRef ) => {
  const [isClickOutside, setIsClickOutside] = useState(false);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsClickOutside(true)
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  function resetStatus() {
    setIsClickOutside(false);
  }

  return [
    isClickOutside,
    resetStatus
  ];
};

export default useOutsideAlerter;
