import {createSlice} from "@reduxjs/toolkit";
import {Config} from "../../config";
import SuperFetch from "../../helpers/superFetch";
import { fetchProfessionsSuccess } from './professions';
import { setSpecialitiesOptionsDropdown } from './specialties';
import { setLocationsOptionsDropdown } from './locations';
import { removeURLParameter } from '../../helpers/utils';

export const initialState = {
  data: [],
  totalPPCount: 0,
  isLoading: true
}

const jobsCounter = createSlice({
  name: 'jobsCounter',
  initialState,
  reducers: {
    fetchJobsTotalCountSuccess: (state, {payload}) => {
      state.data = payload.data;
      state.totalPPCount = payload.totalPPCount;
    },
    fetchingLoading: state => {
      state.isLoading = true
    },
    finishedFetchingLoading: state => {
      state.isLoading = false
    },
  },
})

// A selector
export const jobsCounterSelector = state => state.jobsCounter;

// Actions
export const {
  fetchJobsTotalCountSuccess,
  fetchingLoading,
  finishedFetchingLoading
} = jobsCounter.actions;

export const triggerTotalJobsCount = (params = 'job_type=1') => {
  return async (dispatch) => {
    dispatch(fetchingLoading())
    try {
      const url = `${Config.WAPI_URL}/jobs/count?${params}`;
      const response = await SuperFetch.get(url);

      const payload = {
        data: response.data.jobsCount,
        totalPPCount: response.data.totalPPCount
      };

      await dispatch(fetchJobsTotalCountSuccess(payload));
      dispatch(finishedFetchingLoading())
      return response.data.jobsCount;
    } catch (error) {
      console.log(error);
      dispatch(finishedFetchingLoading())
    }
  }
};

export const triggerTotalJobsCountForLocation = (queryParams = '') => {
  return async (dispatch, getState) => {
    if (!queryParams) {
      return null;
    }

    let params = '';
    params = removeURLParameter('?' + queryParams, 'location');
    params = removeURLParameter('?' + params, 'searched_location');

    try {
      const url = `${Config.WAPI_URL}/jobs/count?${params}`;
      const response = await SuperFetch.get(url);

      return response.data.jobsCount.locations
    } catch (error) {
      console.log(error)
    }
  }
};

export const triggerTotalJobsCountForSpecialties = (queryParams = '') => {
  return async (dispatch, getState) => {
    if (!queryParams) {
      return null;
    }

    let params = '';
    params = removeURLParameter('?' + queryParams, 'specialty_ids');

    try {
      const url = `${Config.WAPI_URL}/jobs/count?${params}`;
      const response = await SuperFetch.get(url);

      return response.data.jobsCount.specialties
    } catch (error) {
      console.log(error)
    }
  }
};

export const populateJobsCount = (totalJobsCount = null, skipLocationCountValidation = false) => {
  return async (dispatch, getState) => {

    if (!totalJobsCount) {
      return false;
    }
    const state = getState();

    const { filters: { profession_id } = {} } = state.jobs;
    const selectedProfession = profession_id ? profession_id?.value : null;

    try {
      // Logic to populate
      // Professions
      const { professionsOriginal } = state.professions;
      const newProfessions = professionsOriginal.map((item) => {
        let count = totalJobsCount.professions[item.value] || 0;
        return {
          ...item,
          count,
        };
      }).filter(l => l.count > 0);

      await dispatch(fetchProfessionsSuccess(newProfessions.sort((a, b) => {
        return b.count - a.count;
      })));

      // Specialties
      const { optionsDropdownOriginal: specialties } = state.specialties;

      const newSpecialties = specialties.map((item) => {
        const count = totalJobsCount.specialties[`${item.label}-${item.profession.toLowerCase()}`] || 0;
        return {
          ...item,
          fakeLabel: `${selectedProfession ? item.label : `${item.profession} - ${item.label}`}`,
          count,
        };
      }).filter(l => l.count > 0);

      dispatch(setSpecialitiesOptionsDropdown(newSpecialties.sort((a, b) => {
        return b.count - a.count;
      })));

      // Locations
      const { optionsDropdown: locations } = state.locations;
      const newLocations = locations.map((item) => {
        const key = item.label.replace(', ', '');
        const key2 = item.label.replace(', ', ' ');
        const count = totalJobsCount.locations[`${key}`] || totalJobsCount.locations[`${key2}`] || 0;

        return {
          ...item,
          count,
        };
      }).filter(l => skipLocationCountValidation || l.count > 0);

      dispatch(setLocationsOptionsDropdown(newLocations.sort((a, b) => {
        return b.count - a.count;
      })));

    } catch (error) {
      console.log(error)
    }
  }
};

export const populateLocationJobsCount = (locationsFound, totalJobsCount = null) => {
  if (!totalJobsCount) {
    return locationsFound;
  }

  try {
    // Locations
    const newLocations = locationsFound.map((item) => {
      const key = item.label.replace(', ', '');
      const key2 = item.label.replace(', ', ' ');
      const key3 = item.label;
      const count = totalJobsCount[`${key}`] || totalJobsCount[`${key2}`] || totalJobsCount[`${key3}`] || 0;
      return {
        ...item,
        count,
      };
    });
    return newLocations.sort((a, b) => {
      return b.count - a.count;
    }).filter(l => l.count > 0);

  } catch (error) {
    console.log(error)
  }
};

// The reducer
export default jobsCounter.reducer;