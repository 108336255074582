import React, { useEffect, useReducer, useState } from 'react';
import { Checkbox } from '@orionhcs/wanda-js-components';
import { MobileNav } from './MobileNav';
import { Controller, useForm } from 'react-hook-form';
import { ReactComponent as LoadingSpinner } from '../../assets/images/loading/spinner.svg';
import BackArrowSvg from '../../assets/images/icons/arrow-narrow.svg';

import './signupquestions.scss';
import Input from '../Form/Input/Input';
import ProfessionDropdown from '../Form/ProfessionDropdown';
import SpecialtyDropdown from '../Form/SpecialtyDropdown';
import { ReactComponent as AddIcon } from '../../assets/images/icons/add-file.svg';
import { ReactComponent as IconSpecialties } from '../../assets/images/icons/icon-search.svg';
import { ReactComponent as IconDelete } from '../../assets/images/icons/trashcan-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import {getProfessionData, getUserData, NurseSelector} from "../../redux/slices/nurse";
import InputMask from '../Form/InputMask';
import SuperFetch from '../../helpers/superFetch';
import _ from 'lodash';
import { setSpecialitiesOptionsDropdown } from '../../redux/slices/specialties';
import { hasAccessToken } from "../../helpers/auth";

const SignUpQuestions = () => {
  const STEP_ONE_FIELDS  = {
    first_name: {
      key: 'first_name',
      name: 'first_name',
      colClass: 'col-6 col-sm-6',
      type: 'input',
      label: 'First Name',
      placeHolder: 'Enter your name',
      rules: {
        required: 'This field is required',
      },
      typeText: 'text',
    },
    last_name: {
      key: 'last_name',
      name: 'last_name',
      colClass: 'col-6 col-sm-6',
      type: 'input',
      label: 'Last Name',
      placeHolder: 'Enter your name',
      rules: {
        required: 'This field is required',
      },
      typeText: 'text',
    },
    phone: {
      key: 'phone',
      name: 'phone',
      colClass: 'col-12',
      type: 'maskedInput',
      label: 'Phone Number',
      mask: 'phone',
      maskPlaceHolder: '(555)-555-5555',
      required: true,
      isOptional: false,
      rules: {
        required: 'This field is required',
        minLength: {
          value: 12,
          message: 'Please specify a valid phone number.',
        },
        maxLength: {
          value: 12,
          message: 'Please specify a valid phone number.',
        },
        pattern: {
          value:  /^(\+?1-?)?(\([2-9]([02-9]\d|1[02-9])\)|[2-9]([02-9]\d|1[02-9]))-?[2-9]\d{2}-?\d{4}$/i,
          message: 'Please specify a valid phone number.',
        },
      },
    },
    sms_notification: {
      key: 'sms_notification',
      name: 'sms_notification',
      colClass: 'col-12 mt-3 mb-3',
      type: 'checkbox',
      label: 'Subscribe to receive job alerts SMS from Wanderly LLC.',
      placeHolder: '',
      required: false,
      rules: {},
      typeText: 'checkbox',
      isOptional: true,
    },
    email: {
      key: 'email',
      name: 'email',
      colClass: 'col-12',
      type: 'input',
      label: 'Email',
      placeHolder: 'Enter your email',
      required: true,
      isOptional: false,
      rules: {
        required: 'This field is required',
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          message: 'Please specify a valid email.',
        },
      },
      typeText: 'email',
    },
    profession: {
      key: 'profession',
      colClass: 'col-7 col-md-6',
      type: 'profession',
      label: 'What is your profession?',
      required: true,
      rules: {
        validate: (value) => typeof value !== 'undefined',
        required: 'This field is required',
      },
    },
    years_experience_profession: {
      key: 'years_experience_profession',
      name: 'years_experience_profession',
      colClass: 'col-5 col-md-6',
      cssClass: 'years-exp',
      type: 'input',
      label: 'Years Experience',
      placeHolder: '',
      required: true,
      rules: {
        required: 'This field is required',
        min: 1,
      },
      typeText: 'text',
    },
  };

  const dispatch = useDispatch();
  const [isPhone, setIsPhone] = useState(true);
  const [showQuestions, setShowQuestions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [stepsOne, setStepsOne] = useState(STEP_ONE_FIELDS);
  const params = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (hasAccessToken()) {
      dispatch(getUserData());
    }
  }, [dispatch]);
  
  let {
    auth: { data: nurseData, isLoading: nurseDataLoading },
  } = useSelector(NurseSelector);

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    const theCandidateData = nurseData || window.candidateDataProfile;

    // window.candidateDataProfile value it's set at resources/views/search/index.blade.php
    // We made on this way in order to improve Sign Up questions displaying
    if (theCandidateData && token && !isValidProfile(theCandidateData)) {
      const { profile } = theCandidateData;
      const {
        first_name,
        last_name,
        years_exp,
        profession_id,
        specialties,
        sms_notification,
      } = profile;

      const {
        phone_verified,
        email_verified,
        phone_number,
        email,
      } = theCandidateData;

      const _stepsOne = _.cloneDeep(stepsOne);

      if (!email_verified) {
        delete _stepsOne.phone;
        delete _stepsOne.sms_notification;
        setIsPhone(false);
      } else if (!phone_verified) {
        delete _stepsOne.email;
      }
      const cleaned = phone_number?.replace(/[()\s-]+/g, '');
      const formatted = cleaned?.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
      setValue('email', email || '');
      setValue('phone', formatted || '');
      setValue('first_name', first_name || '');
      setValue('last_name', last_name || '');
      setValue('years_experience_profession', years_exp);
      setValue('profession', profession_id || '');
      setValue('sms_notification', sms_notification || false);
      setFormValues({
        selectedProfession: profession_id,
      });
      setStepsOne(_stepsOne);

      let _specialties = [];
      if (specialties && specialties.length) {
        specialties.forEach((specialty, index) => {

          _specialties.push({ value: index, isClearable: false });

          const { speciality: _s } = specialty;
          setValue(`specialties[${index}]`, {
            label: _s.name,
            profession: _s.profession.name,
            profession_id: _s.profession.id,
            value: specialty.speciality_id,
          });
          setValue(`specialties_experience[${index}]`, specialty.years_exp);

        });

      } else {

        _specialties.push({ value: 0, isClearable: false });
        setValue(`specialties[0]`, null);
        setValue(`specialties_experience[0]`, null);
      }

      setFormValues({
        specialties: _specialties,
      });

      setShowQuestions(true);
    }

  }, [nurseData]);

  const isValidProfile = (nurseData) => {
    if (typeof window.validProfile !== 'undefined' && window.validProfile) {
      return true;
    }

    const {profile} = nurseData;

    const {
      first_name,
      last_name,
      profession_id,
      specialties,
    } = profile;

    const {
      phone_number,
      email
    } = nurseData;

    return first_name && last_name && profession_id && specialties?.length && phone_number && email;
  }

  const dismissModal = () => {
    const redirect = localStorage.getItem('loginRedirect');

    if (redirect) {
      localStorage.removeItem('loginRedirect');
      window.location.href = redirect;
    } else {
      setShowQuestions(false);
    }
  }

  const onCodeverified = () => {
    localStorage.setItem('SUQModal', true);
    setStepActive(3);
  }

  const initialValues = {
    specialties: [],
    selectedProfession: null,
    userSpecialties: [],
    profile: null,
  };

  const [formValues, setFormValues] = useReducer(
    (curValues, newValues) => ({ ...curValues, ...newValues }),
    initialValues
  );
  const [stepActive, setStepActive] = useState(1);
  const [errorMessage, setErrorMessage] = useState('');

  const isStepActive = (currentStep) => {
    return !!(currentStep === stepActive); 
  }

  const isStepDone = (currentStep) => {
    return !!(currentStep < stepActive); 
  }

  const {
    handleSubmit,
    setError,
    formState: { errors, isValid },
    setValue,
    getValues,
    trigger,
    reset,
    control,
    unregister,
  } = useForm({ mode: 'onChange' });

  const onSubmit = (dataForm) => {
    if (stepActive === 1) {
      handleStepOne(dataForm);
    } else {
      if (params && params.get('interestedIn')) {
        window.location.reload();
      } else {
        dismissModal();
      }
    }
  };

  const handleStepOne =  async (dataForm) => {
    setIsLoading(true);
    const {
      first_name,
      last_name,
      years_experience_profession,
      profession,
      phone,
      email,
      specialties,
      specialties_experience,
      sms_notification
    } = dataForm;
    const specialty = {};
    const specialty_experience = {};
    formValues.specialties.forEach((s) => {
      const _s = specialties[s.value];
      specialty[_s.value] = `${_s.label}`
      specialty_experience[_s.value] = specialties_experience[s.value]
      return s;
    });

    const _phone =  phone?.replace(/[()\s-]+/g, '');
    const data = {
      first_name,
      last_name,
      home_phone_number: _phone,
      email,
      years_exp: years_experience_profession,
      profession_id: profession,
      licensed_states: [],
      specialty,
      specialty_experience,
      sms_notification
    }
    const saveProfile = await SuperFetch.post('/nurse/nurseProfiles', data);
    if (saveProfile?.code === 200) {
      updateNavBar({first_name, last_name});
      setStepActive(2);
    } else if (saveProfile?.success === false && saveProfile?.message?.errors) {
      for (let key in saveProfile?.message?.errors) {
        const fieldName = key === 'home_phone_number' ? 'phone' : key;
        setError(fieldName, { type: 'custom', message: saveProfile?.message?.errors[key] })
      }
    }
    setIsLoading(false);
  }

  const updateNavBar = ({first_name, last_name}) => {
    const _$ = window.jQuery;
    const retrievedUserObject = localStorage.getItem('userAuth');
    const parsedUser = JSON.parse(retrievedUserObject) || {};
    parsedUser.first_name = first_name;
    parsedUser.last_name = last_name;
    localStorage.setItem('userAuth',JSON.stringify(parsedUser || ''));

    _$('.username').html(`${first_name} ${last_name}`);
    _$('.sb-avatar-letters').html(`${first_name[0]}${last_name[0]}`);
  }

  const onSelectOption = async (value, select, name) => {
    const currentProfession = getValues('profession');
    if (name === 'profession' && value !== currentProfession) {
      formValues.specialties.forEach((s) => {
        setValue(`specialties[${s.value}]`, null);
        setValue(`specialties_experience[${s.value}]`, null);
  
        return s;
      });
  
      setFormValues({ selectedProfession: value });
      dispatch(setSpecialitiesOptionsDropdown([]));
    }
    if (name.includes('specialties') && value && value.profession_id !== currentProfession){
      setFormValues({
        selectedProfession: value.profession_id,
      });
      setValue('profession', value.profession_id);
    }

    setValue(name, value);
    await trigger(name);
  };

  const getErrors = (name, type) => {
    if (!errors?.[type]) {
      return null;
    }

    return Object.values(errors?.[type]).find(
      (error) => error?.ref?.name === name
    );
  };

  const addSpecialtyToList = async () => {
    const oldSpecialties = formValues.specialties;
    const newSpecialtyId = oldSpecialties.length * Date.now();

    setValue(`specialties[${newSpecialtyId}]`, null);
    setValue(`specialties_experience[${newSpecialtyId}]`, null);

    const newSpecialties = [
      ...oldSpecialties,
      { value: newSpecialtyId, isClearable: false },
    ];

    setFormValues({
      specialties: newSpecialties,
    });

  };

  const removeSpecialty = (specialtyToDelete) => {
    const oldSpecialties = formValues.specialties;

    const newSpecialties = oldSpecialties.filter(
      (specialty) => specialtyToDelete !== specialty.value
    );

    unregister(`specialties[${specialtyToDelete}]`);
    unregister(`specialties_experience[${specialtyToDelete}]`);

    newSpecialties.forEach((s) => {
      const dataDropdown = getValues(`specialties[${s.value}]`);
      const experience = getValues(`specialties_experience[${s.value}]`);

      setValue(`specialties[${s.value}]`, dataDropdown);
      setValue(`specialties_experience[${s.value}]`, experience);

      return s;
    });

    setFormValues({
      specialties: newSpecialties,
    });
  };

  const renderField = (field, props) => {
    switch (field.type) {
      case 'input':
        return (
          <Input
            className={`${errors[field.key] ? 'invalid-input' : ''} input-text
            }`}
            defaultValue={props.field.value}
            errors={errors[field.key]}
            isOptional={field.isOptional}
            isRequired={!field.isOptional}
            label={field.label}
            labelClass={`label ${
              field.key === 'years_experience_profession'
                ? 'label-exp'
                : ''
            }`}
            maxLength={field.key === 'years_experience_profession' ? 2 : false}
            name={field.name}
            onChange={async (e, value) => {
              setValue(props.field.name, value);
              await trigger(props.field.name);
            }}
            onlyNumbers={field.key === 'years_experience_profession'}
            allowZero={false}
            placeholder={field.placeHolder}
            type={field.typeText}
          />
        );
      case 'profession':
        return (
          <ProfessionDropdown
            className={`${errors[field.key] ? 'input-with-error' : ''}`}
            errors={errors[field.key]}
            isControlled
            label={field.label}
            labelClass={'label'}
            onChange={onSelectOption}
            showIndicator={false}
            showPlaceholder={false}
            value={props.field.value}
          />
        );
      case 'maskedInput':
          return (
            <InputMask
              className={`input-text`}
              errors={errors[field.key]}
              label={field.label}
              labelClass={'label'}
              mask={field.mask}
              name={props.field.name}
              onChange={async (value) => {
                const cleanValue = value.replaceAll('_', '').trim();
                setValue(props.field.name, cleanValue);
                await trigger(props.field.name);
              }}
              placeholder={field.maskPlaceHolder}
              value={props.field.value}
              isOptional={field.isOptional}
              required={!field.isOptional}
            />
          );
      case 'checkbox':
        return (
          <Checkbox
            className={`${errors[field.key] ? 'invalid' : ''} checkbox-authorized`}
            color="green"
            id="selectAll-checkbox"
            isRequired={!field.isOptional}
            label={field.label}
            name={field.name}
            onChange={async (e) => {
              setValue(field.name, e.target.checked);
              await trigger(field.name);
            }}
            value={field.name}
            isChecked={props.field.value}
          />
        );
      default:
        return null;
    }
  };

  return (
    showQuestions ?
    <>
      <div
        id="signUpQuestionsModal"
        className="modal fade modal-wanderly-chat profile-modal in"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="minimumInformationModalModalLabel"
        data-keyboard="false"
        data-backdrop="static"
        style={{ display: 'block' }}
      >
        <div className="modal-dialog" role="document">
          {isLoading && <div className={`loading-spinner`}> <LoadingSpinner /> </div>}
          <MobileNav backClick={false} />
          <div className="modal-content questionary">
            <div className="modal-header">
              </div>
              <div className="modal-body text-center" style={{ margin: 0 }}>
                <div className="container-title">
                {stepActive === 1 &&  <>
                  <div className="title">
                      Tell us a bit about yourself.
                  </div>
                  <div className="fine-print">
                      Your information is hidden from recruiters unless<div className="clearfix visible-xs" /> you apply for a job or send it to them directly.
                  </div>
                </>}
                </div>
                <div id="sign-up-questions-modal-content">
                  <form
                    className={`questions-form`}
                    onSubmit={handleSubmit(onSubmit)}
                    >
                    {stepActive === 1 && 
                    <>
                      <div className="row">
                        {Object.values(stepsOne)
                          .map((f) => {
                              return <div className={f.colClass}>
                                <Controller
                                  control={control}
                                  defaultValue={getValues(f.key)}
                                  name={f.key}
                                  render={(props) => renderField(f, props)}
                                  rules={f.rules}
                                />
                              </div>
                          })}
                      </div>
                  
                      {formValues.specialties.length > 0 &&
                        formValues.specialties.map((specialty, index) => (
                          <div key={specialty.value} className="row">
                            <div
                              className={`col-7 col-md-6 container-field`}
                            >
                              <Controller
                                control={control}
                                defaultValue={getValues(`specialties[${specialty.value}]`)}
                                name={`specialties[${specialty.value}]`}
                                render={(props) => (
                                  <>
                                    <div className="">
                                      <div
                                        className={`specialty-container-input`}
                                      >
                                        <SpecialtyDropdown
                                          className={`${getErrors(
                                            `specialties[${specialty.value}]`,
                                            'specialties'
                                          ) && 'input-with-error'} input-dropdown`}
                                          errors={getErrors(
                                            `specialties[${specialty.value}]`,
                                            'specialties'
                                          )}
                                          icon={<IconSpecialties />}
                                          isClearable={true}
                                          isRequired={true}
                                          isControlled
                                          isMulti={false}
                                          label="Specialty"
                                          labelClass={'label'}
                                          name={`specialties[${specialty.value}]`}
                                          onChange={onSelectOption}
                                          profession={formValues.selectedProfession}
                                          showIcon
                                          showPlaceholder={false}
                                          value={props.field.value}
                                          showCounters={false}
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                                rules={{
                                  validate: (value) => typeof value !== 'undefined',
                                  required: 'This field is required',
                                }}
                              />
                            </div>
              
                            <div
                              className={`col-5  col-md-6 container-field`}
                            >
                              <div className="flex">
                                <Controller
                                  control={control}
                                  defaultValue={getValues(
                                    `specialties_experience[${specialty.value}]`
                                  )}
                                  name={`specialties_experience[${specialty.value}]`}
                                  render={(props) => (
                                    <>
                                      <Input
                                        className={`input-text`}
                                        classNameContainer={`input-text-container`}
                                        defaultValue={props.field.value}
                                        errors={getErrors(
                                          `specialties_experience[${specialty.value}]`,
                                          'specialties_experience'
                                        )}
                                        isOptional={false}
                                        isRequired
                                        label="Years Experience"
                                        labelClass={`label label-exp`}
                                        maxLength={2}
                                        name={`specialties_experience[${specialty.value}]`}
                                        onChange={async (e, value) => {
                                          setValue(props.field.name, value);
                                          await trigger();
                                        }}
                                        onlyNumbers
                                        allowZero={false}
                                        type="text"
                                      />
                                    </>
                                  )}
                                  rules={{
                                    required: 'This field is required',
                                    maxLength: {
                                      value: 2,
                                      message: 'Only allow 2 numbers.',
                                    },
                                    min: {
                                          value: 1,
                                          message: `Years Experience 1`,
                                        }
                                  }}
                                />
                                {formValues.specialties.length > 1 && (
                                  <IconDelete
                                    className={'delete-specialty-icon'}
                                    onClick={() => removeSpecialty(specialty.value)}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        ))
                      }
                  
                      <div className="col-12 text-left">
                        <button
                          className={`link-button add-specialties-btn`}
                          onClick={addSpecialtyToList}
                          type="button"
                        >
                          <AddIcon fill="#06759A" />{' '}
                          <span className={'vcenter'}>ADD MORE SPECIALTIES</span>
                        </button>
                      </div>
                  
                      {errorMessage && (
                        <div className="col-12 justify-center mt-2 alert alert-danger mb-4">
                          {errorMessage}
                        </div>
                      )}
                      <div class="container-buttons">
                        <div class="clearfix container-btn-continue">
                          <button 
                            className="col-xs-12 btn btn-primary cta_after_login_continue_button"
                            type="submit"
                            disabled={isLoading}
                            >
                            CONTINUE
                          </button>
                        </div>
                      </div>
                    </>
                    }
                    {stepActive === 2 && 
                    <>
                      <div class="container-complete">
                        <div class="m-top-20 text-center">
                          <img src="/images/congratulations.png" class="wanda-mail" alt="Profile updated" />
                          <div class="title">Congratulations!</div>
                          <div class="text">
                              Your profile is updated!
                          </div>
                        </div>
                      </div>
                      <div class="container-buttons">
                        <div class="clearfix container-btn-continue">
                          <button 
                            className="col-xs-12 btn btn-primary cta_after_login_continue_button"
                            type="submit"
                            disabled={isLoading}
                            >
                            GET STARTED
                          </button>
                        </div>
                      </div>
                    </>                 
                    }
                  </form>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade in"></div>
    </> : null
  );
};

export default SignUpQuestions;
