import Slider from 'rc-slider';
import { debounce } from 'underscore';
import { useDispatch, useSelector } from 'react-redux';
import { JobsSelector, setFilters, setRate } from '../../redux/slices/jobs';

import 'rc-slider/assets/index.css';
import './RateSlider.style.scss';

const RateSlider = ({ isMobile, step, min, max, defaultRate = 500 }) => {
  const dispatch = useDispatch();
  const { filters: { rate } = {} } = useSelector(JobsSelector);
  const saveRate = (value) => {
    dispatch(
      setFilters({
        rate: value,
      })
    );
  };

  const debounceOnChange = isMobile ? saveRate : debounce(saveRate, 100);

  return (
    <>
      <Slider
        value={defaultRate}
        className="rate-slider"
        step={step}
        min={min}
        max={max}
        onChange={debounceOnChange}
      />
    </>
  );
};

export default RateSlider;
