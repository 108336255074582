import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as DayShiftIcon } from '../../assets/images/icons/dayShift.svg';
import { ReactComponent as MidShiftIcon } from '../../assets/images/icons/midShift.svg';
import { ReactComponent as NightShiftIcon } from '../../assets/images/icons/nightShift.svg';
import SortDropdown from '../SortDropdown';
import RateSlider from '../RateSlider';
import FacilityDropdown from '../FacilityDropdown';
import RecruiterDropdown from '../RecruiterDropdown';
import AgenciesDropdown from '../AgenciesDropdown';
import {
  clearSidebarFilters,
  clearTopFilters,
  JobsSelector,
  setAssignment,
  setShift,
} from '../../redux/slices/jobs';

import './Sidebar.index.scss';

const SidebarControls = ({ isMobile }) => {
  const dispatch = useDispatch();
  const {
    filters: { typeJob, rate, shift, assignment_length, profession_id } = {},
  } = useSelector(JobsSelector);

  const resetFilters = () =>
    dispatch(
      isMobile
        ? clearTopFilters({ profession_id, typeJob })
        : clearSidebarFilters()
    );

  const configByType = {
    domestic: {
      sliderLabel: '/wk',
      defaultRate: rate || 500,
      sliderStep: 100,
      sliderMin: 500,
      sliderMax: 9000,
    },
    otherType: {
      sliderLabel: '/hr',
      defaultRate: rate || 1,
      sliderStep: 10,
      sliderMin: 1,
      sliderMax: 100,
    },
    local: {
      sliderLabel: '/hr',
      defaultRate: 12,
      sliderStep: 1,
      sliderMin: 12,
      sliderMax: 150,
    },
    irp: {
      sliderLabel: '/hr',
      defaultRate: 15,
      sliderStep: 1,
      sliderMin: 15,
      sliderMax: 225,
    },
    perDiem: {
      sliderLabel: '/hr',
      defaultRate: 15,
      sliderStep: 1,
      sliderMin: 15,
      sliderMax: 225,
    },
  };

  const typeConfig = configByType[typeJob] || configByType.otherType;

  const { sliderLabel, defaultRate, sliderStep, sliderMin, sliderMax } =
    typeConfig;

  return (
    <div className="sidebar-controls">
      <div className="title-container">
        Sort By
        <button
          className="link-button clear-controls"
          onClick={resetFilters}
          style={{ float: 'right' }}
        >
          Clear
        </button>
      </div>
      <div className="control-container">
        <SortDropdown />
      </div>
      {typeJob === 'domestic' ? (
        <>
          <div className="title-container">Minimum Pay Rate</div>
          <div className="text-center rate-label-container">
            ${defaultRate} {sliderLabel}
          </div>
          <div className="control-container">
            <RateSlider
              isMobile={isMobile}
              step={sliderStep}
              min={sliderMin}
              max={sliderMax}
              defaultRate={defaultRate}
            />
          </div>
        </>
      ) : null}
      <div className="title-container">Shift</div>
      <div className="shift-container control-container">
        <button
          type="button"
          className={`link-button nurse-search-control shift-button ${
            shift.includes('Day') ? 'active' : ''
          }`}
          onClick={() => {
            dispatch(setShift('Day'));
          }}
        >
          <DayShiftIcon />
        </button>
        <button
          type="button"
          className={`link-button nurse-search-control shift-button ${
            shift.includes('Mid') ? 'active' : ''
          }`}
          onClick={() => {
            dispatch(setShift('Mid'));
          }}
        >
          <MidShiftIcon />
        </button>
        <button
          type="button"
          className={`link-button nurse-search-control shift-button ${
            shift.includes('Night') ? 'active' : ''
          }`}
          onClick={() => {
            dispatch(setShift('Night'));
          }}
        >
          <NightShiftIcon />
        </button>
      </div>
      {(typeJob === 'domestic' || typeJob === 'local') && (
        <>
          <div className="title-container">Assignment Length (weeks)</div>
          <div className="assignment-container control-container">
            <button
              type="button"
              className={`link-button nurse-search-control assignment-button ${
                assignment_length.includes(12) ? 'active' : ''
              } `}
              onClick={() => {
                dispatch(setAssignment(12));
              }}
            >
              1-12
            </button>
            <button
              type="button"
              className={`link-button nurse-search-control assignment-button ${
                assignment_length.includes(13) ? 'active' : ''
              } `}
              onClick={() => {
                dispatch(setAssignment(13));
              }}
            >
              13
            </button>
            <button
              type="button"
              className={`link-button nurse-search-control assignment-button ${
                assignment_length.includes(14) ? 'active' : ''
              } `}
              onClick={() => {
                dispatch(setAssignment(14));
              }}
            >
              14+
            </button>
          </div>
        </>
      )}
      {typeJob === 'permanent' && (
        <>
          <div className="title-container">Facilities</div>
          <div className="control-container">
            <FacilityDropdown />
          </div>
        </>
      )}
      <div className="title-container">Agencies & Hospitals</div>
      <div className="control-container">
        <AgenciesDropdown />
      </div>
    </div>
  );
};

export default SidebarControls;
