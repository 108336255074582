export const hasAccessToken = () => {
  try {
    const token = localStorage.getItem('accessToken');
    return token || false;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getUserItem = () => {
  try {
    const rawValue = localStorage.getItem('userAuth');
    if (rawValue) {
      return JSON.parse(rawValue) || null;
    }

    return null
  } catch (error) {
    console.log(error);
    return null;
  }
}

export const getCsrfToken = () => {
  try {
    const csrfToken = localStorage.getItem('csrfToken');

    return csrfToken || null;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export const setAccessToken = (value) => {
  try {
    localStorage.setItem('accessToken', value);
  } catch (error) {
    console.log(error);
  }
};

export const getCRSFToken = () => {
  try {
    const token = localStorage.getItem('csrfToken');
    return token || false;
  } catch (error) {
    console.log(error);
  }
};

export const getCookie = (name) => {
  const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
  if (match) return match[2];
};

