import { DropdownSelectAsync } from '@orionhcs/wanda-js-components';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'underscore';

import { ReactComponent as IconSpecialties } from '../../../assets/images/icons/specialties.svg';
import { Config } from '../../../config';
import SuperFetch from '../../../helpers/superFetch';
import { JobsSelector, setFilters } from '../../../redux/slices/jobs';
import {
  setSpecialitiesOptionsDropdown,
  SpecialtiesSelector,
} from '../../../redux/slices/specialties';

import './SpecialtyDropdown.scss';

const SpecialtyDropdown = ({
  placeholder,
  value,
  onChange,
  isControlled = false,
  profession = '',
  showIcon = true,
  isMulti = true,
  name = 'specialty',
  errors,
  isClearable = false,
  showProfessionPrefix = true,
  isDisabled = false,
  label = false,
  labelClass = '',
  className = '',
  showPlaceholder = true,
  icon,
  showCounters = true,
  isRequired = false,
}) => {
  const { filters: { profession_id, specialty_ids } = {} } = useSelector(JobsSelector);
  const { optionsDropdown } = useSelector(SpecialtiesSelector);
  const dispatch = useDispatch();

  const getProfession = (selectedProfession) =>
    selectedProfession ? `&profession=${selectedProfession}` : '';

  const getSpecialties = async (input, callback) => {
    if (!input || input.length === '') {
      return Promise.resolve({ options: [] });
    }

    const selectedProfession = isControlled ? profession : profession_id?.value;
    const url = `${Config.WAPI_URL}/jobs/suggestion/specialty/?text=${input}${getProfession(
      selectedProfession
    )}`;
    const response = await SuperFetch.get(url);

    const options = response.data.map((l, index) => ({
      value: l.id,
      fakeLabel: `${selectedProfession ? l.name : `${l.profession} - ${l.name}`}`,
      label: l.name,
      profession: l.profession,
      profession_id: l.profession_id,
    }));
    dispatch(setSpecialitiesOptionsDropdown(options));
    callback(options);
  };

  const onSelectOption = (value) => {
    if (isControlled) {
      onChange(value || null, '', name || 'specialties');
    } else {
      window.HasSpecialitiesSelected = true;
      dispatch(
        setFilters({
          specialty_ids: value,
          profession_id: profession_id || {
            value: value[0]?.profession_id,
            label: value[0]?.profession,
          },
        })
      );
    }
  };

  const resetOptions = () => {
    dispatch(setSpecialitiesOptionsDropdown([]));
  };

  const getOptions = debounce(getSpecialties, 800);

  const getValue = () => {
    if (isControlled) {
      return value;
    }

    return specialty_ids;
  };

  return (
    <>
      {label && (
        <label className={`label ${labelClass}`}>
          Specialty {(!isClearable || isRequired) && <span className="required-asterisk">*</span>}
        </label>
      )}
      <DropdownSelectAsync
        cacheOptions={false}
        classname={`specialty ${className}`}
        closeMenuOnSelect
        defaultOptions={optionsDropdown}
        getOptionLabel={(option) => (showProfessionPrefix ? option.fakeLabel : option.label)}
        hideSelectedOptions={false}
        icon={showIcon ? icon ? icon : <IconSpecialties /> : null}
        iconAlign="right"
        isClearable={isClearable}
        isDisabled={isDisabled}
        isMulti={isMulti}
        loadOptions={getOptions}
        maxMenuHeight={150}
        name={name}
        noOptionsMessage={() => null}
        onChange={onSelectOption}
        onMenuClose={resetOptions}
        placeholder={showPlaceholder ? placeholder : ''}
        showPlaceholder={showPlaceholder}
        value={getValue()}
        showCounters={showCounters}
      />
      {errors && (
        <div className="invalid-message" style={{ display: 'block' }}>
          {errors.message || 'This field is required'}
        </div>
      )}
    </>
  );
};

export default SpecialtyDropdown;
