import React, { useEffect, useState } from 'react';

import { ReactComponent as CloseIcon } from '../../../assets/images/icons/icon-cancel.svg';
import { ReactComponent as Verified } from '../../../assets/images/icons/icon-green-circle-checkmark.svg';
import ReactTooltip from 'react-tooltip';
import './Input.style.scss';

export const Input = ({
  disabled,
  icon,
  name,
  defaultValue,
  val,
  className = '',
  classNameContainer = '',
  classNameClose = '',
  formData,
  onChange,
  allowclear,
  closeicontitle,
  errors,
  type,
  onlyNumbers,
  maxLength = false,
  label = false,
  displayLabel = true,
  labelClass = '',
  isRequired = false,
  verified = false,
  tooltip='',
  allowZero = true,
  ...props
}) => {
  const [value, setValue] = useState(val || '');
  const inputRef = React.createRef();

  const getErrorMsg = (type) => {
    const config = {
      required: 'This field is required',
      minLength: 'Enter a correct phone number',
      pattern: 'Enter a correct email',
    };

    return config[type] || 'This field is required';
  };

  const handleTyping = (e) => {
    let { value } = e.target;

    if (onlyNumbers) {
      var regex = /[^0-9]/;
      value = value.replace(regex, '');
      if(!allowZero && parseInt(value) == 0){
        return;
      }
    }


    if (maxLength && value.length > maxLength) {
      value = value.slice(0, maxLength);
    }

    setValue(value);

    if (onChange) {
      onChange(e, value);
    }
  };

  const handleClear = (e) => {
    setValue('');
    inputRef.current.focus();

    if (onChange) {
      onChange(e, '');
    }
  };

  return (
    <div
      className={`relative custom-input-text ${classNameContainer} ${errors ? 'with-errors' : ''} ${
        icon ? 'box-with-icon' : ''
      } ${disabled ? 'disabled-input' : ''}`}
    >
      {(label && displayLabel) && (
        <label className={`${labelClass || ''} label`} htmlFor={name}>
          {label}{isRequired && <span className="required-asterisk">*</span>}
          {tooltip && 
            <span data-tip data-for='input-tooltip'>
               <img width='20px' height='20px' src={'/images/icons/icon-info.png'} className={'tooltip-icon tooltip-icon-verified'} />
            </span>}
        </label>
      )}
      {tooltip && (
        <>
          <ReactTooltip 
            className="rc-tooltip"
            effect="solid"
            place="top"
            multiline={true}
            id='input-tooltip'>
            {tooltip}
          </ReactTooltip>
        </>
      )}
      <input
        {...props}
        ref={inputRef}
        className={`${className} input-text ${disabled ? 'disabled-input' : ''}`}
        disabled={disabled}
        name={name}
        onChange={handleTyping}
        type={type || 'text'}
        value={value || defaultValue || ''}
      />
      {errors && (
        <div className="invalid-message" dangerouslySetInnerHTML={{__html: errors.message || getErrorMsg(errors.type)}} />
      )}
      {allowclear && value ? (
        <span className={`close-icon-container `}>
          <CloseIcon
            className={`close-icon ${classNameClose}`}
            fill="#a2a5ac"
            focusable="true"
            onClick={handleClear}
            title={closeicontitle || 'Clear value'}
          />
        </span>
      ) : null}
      {icon && icon}
      {verified && <Verified style={{width:'24px', height:'24px', position: 'absolute', bottom:'7px', right:'7px'}}/>}
    </div>
  );
};

export default Input;
