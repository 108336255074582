import React, { useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { ReactComponent as IconSpecialties } from "../../assets/images/icons/specialties.svg";
import {JobsSelector, setFilters} from "../../redux/slices/jobs";
import SuperFetch from "../../helpers/superFetch";
import {Config} from "../../config";
import {
  setSpecialitiesOptionsDropdown,
  setSpecialitiesOptionsOriginal,
  SpecialtiesSelector,
} from '../../redux/slices/specialties';

import './SpecialtyDropdown.scss';
import {
  populateJobsCount
} from '../../redux/slices/jobsCounter';
import DropdownControl from '../DropdownControl';

const SpecialtyDropdown = ({placeholder, value, onChange, isControlled = false, profession='', isDisabled=false}) => {
  const { jobsCount, filters: { profession_id, specialty_ids } = {} } = useSelector(JobsSelector);
  const { optionsDropdown } = useSelector(SpecialtiesSelector);
  const dispatch = useDispatch();

  useEffect(async () => {
    if (profession_id) {
      await getSpecialties('', null, true);
    }
  }, [profession_id, profession]);

  useEffect(async () => {
    await getSpecialties('', null, true)
  }, []);

  const getProfession = (selectedProfession) => {
    return selectedProfession ? `&profession=${selectedProfession}`: ''
  };

  const getSpecialties = async (input, callback, skipValidation = false) => {
    if (!skipValidation && (!input || input.length === '')) {
      return Promise.resolve({ options: [] });
    }

    const selectedProfession = isControlled ? profession : profession_id?.value;
    const url = `${Config.WAPI_URL}/jobs/suggestion/specialty/?text=${input}${getProfession(selectedProfession)}`;
    const response = await SuperFetch.get(url);

    const options = response.data.map((l, index) => (
      {
        value: l.id,
        fakeLabel: `${selectedProfession ? l.name : `${l.profession} - ${l.name}`}`,
        label: l.name,
        profession: l.profession,
        profession_id: l.profession_id
      }
    ));
    await dispatch(setSpecialitiesOptionsDropdown(options));
    await dispatch(setSpecialitiesOptionsOriginal(options));

    if (!isControlled) {
      await dispatch(populateJobsCount(jobsCount));
    }

    if (callback) {
      callback(options);
    }
  }

  const onSelectOption = (value) => {
    if (isControlled) {
      onChange( value ? value : null, '', 'specialties')
    } else {
      window.HasSpecialitiesSelected = true;
      dispatch(setFilters({
        specialty_ids: value,
        profession_id: profession_id ? profession_id : { value: value[0]?.profession_id, label: value[0]?.profession }
      }));
    }
  };

  const getValue = () => {
    if (isControlled) {
      return value;
    } else {
      return specialty_ids;
    }
  }

  const DropdownIndicator = () => null;
  const IndicatorSeparator = () => null;

  return (
    <DropdownControl
      isSearchable={true}
      isMulti
      noOptionsMessage={() => null}
      closeMenuOnSelect={true}
      hideSelectedOptions={false}
      icon={<IconSpecialties />}
      placeholder={placeholder}
      classname={"specialty"}
      name="specialties"
      options={optionsDropdown}
      onChange={onSelectOption}
      value={getValue()}
      maxMenuHeight={150}
      cacheOptions={true}
      getOptionLabel={(option) => option.fakeLabel}
      isDisabled={isDisabled}
      customComponents={{
        DropdownIndicator,
        IndicatorSeparator
      }}
    />
  );
};

export default SpecialtyDropdown;
