import React, { forwardRef } from 'react';
import DatePickerComp from 'react-datepicker';
import { addYears } from 'date-fns';
import { addClass, removeClass } from '../../helpers/dom';
import { ReactComponent as CalendarIcon } from '../../assets/images/icons/calendar.svg';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.style.scss';

const CustomDateInput = ({ value, onClick, disabled }, ref) => (
  <span
    ref={ref}
    className={`custom-input-field ${disabled ? 'bg-gray-200' : ''} ${
      !value ? 'placeholder' : ''
    }`}
    onClick={onClick}
  >
    <CalendarIcon />
    {value || 'Select a Date'}
  </span>
);

const Picker = forwardRef(CustomDateInput);

export const DatePicker = ({ className, disabled, ...props }) => {
  delete props.minDate;

  const handleCalendarClose = () => {
    if (document.getElementsByClassName('custom-input-field')) {
      removeClass('.custom-input-field', 'highlight-custom-input-field');
    }
  };

  const handleCalendarOpen = () => {
    if (document.getElementsByClassName('custom-input-field')) {
      addClass('.custom-input-field', 'highlight-custom-input-field');
    }
  };

  return (
    <div
      className={`custom-datePicker ${className || ''}`}
      id={props.id || null}
    >
      <DatePickerComp
        dateFormat="MMMM d, y"
        customInput={<Picker />}
        minDate={new Date()}
        maxDate={addYears(new Date(), 80)}
        disabled={disabled}
        onCalendarClose={handleCalendarClose}
        onCalendarOpen={handleCalendarOpen}
        {...props}
      />
    </div>
  );
};

export default DatePicker;
