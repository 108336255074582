import React from 'react';
import Slider from 'react-slick';
import { useDispatch } from 'react-redux';
import {
  setHideMobileResults,
  setInitialFilters,
  setShowProfessionUser,
} from '../../redux/slices/jobs';
import { NextArrow, PrevArrow, priceFormat } from '../../helpers/utils';
import { activeSearchMenu } from '../../helpers/dom';
import useUpdateParams from '../../hooks/useUpdateParams';

import './FeaturedDestinations.scss';
import { formatNumberWithCommas } from '../../utils/textFormat';

const FeaturedDestinations = ({ destinations: allDestinations }) => {
  const destinations =
    allDestinations && Array.isArray(allDestinations)
      ? allDestinations.slice(0, 4)
      : [];
  const [updateParams, redirectToSearchPage, redirectToLocationPage] = useUpdateParams();
  const dispatch = useDispatch();
  const settings = {
    autoplay: false,
    dots: false,
    centerMode: true,
    slidesPerRow: 4,
    slidesToScroll: 4,
    rows: 1,
    arrows: false,
    centerPadding: 0,
    dotsClass: 'carousel-dots',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesPerRow: 1,
          slidesToScroll: 1,
          rows: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  const openDestination = (destination) => {
    redirectToLocationPage(destination.city.replace(" ", '-').toLowerCase());
  };

  const sendToSearch = () => {
    dispatch(
      setInitialFilters({
        typeJob: 'domestic',
        profession_id: null,
        specialty_ids: null,
        location: null,
        date: null,
        is_asap: false,
        rate: null,
        shift: [],
        assignment_length: [],
        hospital: null,
        agency: null,
        recruiter_ids: null,
        order_by: null,
        order: 'desc',
        sort_dropdown: null,
      })
    );

    activeSearchMenu();
    redirectToSearchPage({});
  };

  return (
    <section className="featured-destinations-section p-4 py-lg-4 text-center">
      <h2 className="pb-3 font-weight-bold">Featured Destinations</h2>
      <Slider {...settings}>
        {destinations &&
          destinations.length &&
          destinations.map((destination, index) => {
            return (
              <div className="home-carousel-item mr-2" key={index}>
                <a onClick={() => openDestination(destination)}>
                  <div className="carousel-image">
                    <img
                      src={`/images/locations/${destination.image.replace('png', 'jpg')}`}
                      className="img-responsive"
                      alt=""
                    />
                  </div>
                  <div className="align-left px-4 pt-2 px-md-3 pt-lg-3 pb-lg-2">
                    <h4 className="m-0">{destination.city}</h4>
                    <div className='d-flex align-items-center'>
                      <small className="pt-md-1 pt-lg-1 mr-1">
                        Upto
                      </small>
                      <div>
                        <strong>${formatNumberWithCommas(destination.total, 0)}</strong> / wk
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            );
          })}
      </Slider>
      <button className="home-btn mt-md-2 cta_before_login_search_pay_packages_button" onClick={sendToSearch}>
        SEARCH JOBS
      </button>
    </section>
  );
};

export default FeaturedDestinations;
