import Slider from 'react-slick';
import { useDispatch } from 'react-redux';
import {
  setHideMobileResults,
  setInitialFilters,
  setShowProfessionUser,
} from '../../redux/slices/jobs';
import { ReactComponent as NextArrowIcon } from '../../assets/images/icons/carouselNextTeal.svg';
import { ReactComponent as PrevArrowIcon } from '../../assets/images/icons/carouselPrevTeal.svg';
import { activeSearchMenu } from '../../helpers/dom';
import useUpdateParams from '../../hooks/useUpdateParams';

import './FeaturedAgencies.scss';

const NextArrow = ({ onClick }) => (
  <div className="carousel-next" onClick={onClick}>
    <NextArrowIcon />
  </div>
);

const PrevArrow = ({ onClick }) => (
  <div className="carousel-prev" onClick={onClick}>
    <PrevArrowIcon />
  </div>
);

const FeaturedAgencies = ({ agencies }) => {
  const dispatch = useDispatch();
  const [updateParams, redirectToSearchPage] = useUpdateParams();

  const settings = {
    dots: true,
    initialSlide: 0,
    slidesToShow: 6,
    slidesToScroll: 6,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    arrows: true,
    dotsClass: 'carousel-dots m-0 pt-4',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: true,
          dots: false,
        },
      },
    ],
  };

  const openAgency = (agency, e) => {
    dispatch(
      setInitialFilters({
        agency: [{ value: agency.id, label: agency.name }],
        profession_id: null,
        initialRequest: true,
      })
    );

    dispatch(setHideMobileResults(false));
    dispatch(setShowProfessionUser(false));
    activeSearchMenu();

    redirectToSearchPage({
      agency: [{ value: agency.id, label: agency.name }],
    });
  };

  return (
    <section className="featured-agencies-section">
      <div className="home-container-fluid">
        <div className="pb-5 pt-3 mt-md-5 pb-lg-3">
          <h2 className="text-center font-weight-bold pb-3">
            Featured Agencies & Hospitals
          </h2>
          <div className="slider-container my-4">
            <Slider {...settings}>
              {agencies &&
                agencies.length > 0 &&
                agencies.map((agency) => {
                  if (!agency?.logo_thumb_url) return null;
                  return (
                    <a
                      key={agency.id}
                      className="agency-logo"
                      onClick={(e) => openAgency(agency, e)}
                    >
                      <img src={agency.logo_thumb_url} alt={agency.name} />
                    </a>
                  );
                })}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturedAgencies;
