// External Modules
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { components } from 'react-select';

// Internal Modules
import DropdownControl from '../DropdownControl';
import { getJobTypes, JobsSelector } from '../../redux/slices/jobs';

// Internal Components
import { ReactComponent as PermanentIcon } from '../../assets/images/icons/permanent.svg';
import { ReactComponent as IRPIcon } from '../../assets/images/icons/irp.svg';
import { ReactComponent as GlobeIcon } from '../../assets/images/icons/globe.svg';
import { ReactComponent as TravelIcon } from '../../assets/images/icons/travel.svg';
import { ReactComponent as PerDiemIcon } from '../../assets/images/icons/perDiem.svg';
import { ReactComponent as LocalContractIcon } from '../../assets/images/icons/localContract.svg';

// Styles
import './JobTypesDropdown.scss';

// Redux Actions
import { setFilters } from '../../redux/slices/jobs';
import LocationTypes from '../../helpers/enums/LocationTypes';
import {getUpdatedJobTypesData} from "../../helpers/utils";

const JOB_TYPES = {
  1: { icon: <TravelIcon className="icon" />, value: 'travel' },
  2: { icon: <PermanentIcon className="icon" />, value: 'permanent' },
  3: { icon: <LocalContractIcon className="icon" />, value: 'local' },
  4: { icon: <IRPIcon className="icon" />, value: 'irp' },
  5: { icon: <PerDiemIcon className="icon" />, value: 'perDiem' },
};

const Option = (props) => {
  const { data, selectProps } = props;
  const { isControlled } = selectProps;
  return (
    <components.Option {...props}>
      <span className="label-icon" id={data.job_type_name}>
        {JOB_TYPES[data.job_type_id].icon}
      </span>
      <label>{data.job_type_name}</label>
      {!isControlled && <span className={'jobs-count'}>{data.job_count}</span>}
    </components.Option>
  );
};

const JobTypesDropdown = ({ value, onChange, isControlled = false }) => {
  const dispatch = useDispatch();
  const { jobTypes } = useSelector(JobsSelector);
  const [selectedJobType, setSelectedJobType] = useState({ label: '... loading' });
  const [friendlyUrlData, setFriendlyUrlData] = useState(window?.friendlyUrlData);
  const { filters: { typeJob } = {} } = useSelector(JobsSelector);
  const [selectedIcon, setSelectedIcon] = useState(<GlobeIcon />);

  useEffect(async () => {
    await dispatch(getJobTypes());
    setSelectedJobType({ label: 'Select Job Type' });
  }, []);

  useEffect(async () => {
    if (isControlled) {
      if (jobTypes.length) {
        const type = LocationTypes[LocationTypes[value]] - 1;
        const formattedJobTypes = getUpdatedJobTypesData(jobTypes);
        await onSelectOption(formattedJobTypes[type]);
      }
    } else {
      if (typeJob && jobTypes.length && !friendlyUrlData) {
        const type = ['domestic', 'permanent', 'local', 'irp', 'perDiem'].indexOf(typeJob);
        const formattedJobTypes = getUpdatedJobTypesData(jobTypes);
        await onSelectOption(formattedJobTypes[type]);

      } else if (typeJob && friendlyUrlData) {
        const type = ['domestic', 'permanent', 'local', 'irp', 'perDiem'].indexOf(typeJob);
        const formattedJobTypes = getUpdatedJobTypesData(jobTypes);
        const selectedValue = formattedJobTypes[type];

        setSelectedJobType({
          ...selectedValue,
          label: selectedValue.job_type_name,
        });
        setSelectedIcon(JOB_TYPES[selectedValue.job_type_id].icon);
        setFriendlyUrlData(null);
      }
    }

  }, [typeJob, jobTypes, value]);

  const onSelectOption = async (selectedValue) => {
    if (isControlled) {
      onChange(selectedValue ? selectedValue.job_type_id : null, '', 'job_type');

      if (selectedValue) {
        setSelectedJobType({
          ...selectedValue,
          label: selectedValue.job_type_name,
        });
        setSelectedIcon(JOB_TYPES[selectedValue.job_type_id].icon);
      }
    } else {
      setSelectedJobType({
        ...selectedValue,
        label: selectedValue.job_type_name,
      });
      setSelectedIcon(JOB_TYPES[selectedValue.job_type_id].icon);

      // To handle legacy validations, lots of domestic references
      const typeJob =
        selectedValue.job_type_name === 'Travel'
          ? 'domestic'
          : JOB_TYPES[selectedValue.job_type_id].value;

      dispatch(
        setFilters({
          typeJob,
         // profession_id: null,
         // specialty_ids: null,
         // location: null,
          date: null,
          is_asap: false,
          rate: null,
          shift: [],
          assignment_length: [],
          hospital: null,
          agency: null,
          recruiter_ids: null,
          sort_dropdown: null,
        })
      );
    }
  };

  return (
    <DropdownControl
      icon={selectedIcon}
      classname={'job-types'}
      options={getUpdatedJobTypesData(jobTypes)}
      placeholder={'Select Job Type'}
      onChange={onSelectOption}
      name="job-types"
      value={selectedJobType}
      customComponents={{
        Option,
      }}
      isControlled={isControlled}
    />
  );
};

export default JobTypesDropdown;
