import {createSlice} from "@reduxjs/toolkit";
import {Config} from "../../config";
import SuperFetch from "../../helpers/superFetch";
import {fetchInterestedJobsFailure, getInterestedJobs} from "./nurse";

export const initialState = {
  selectedJob: null,
  eligibilityData: null,
  processingInterestedJob: false,
  detail: {
    isLoading: true,
    data: [],
    errors: false,
  },
  similarJobs: {
    isLoading: false,
    data: null,
    errors: null,
  },
}

const job = createSlice({
  name: 'job',
  initialState,
  reducers: {
    fetchJobDetail: state => {
      state.detail.isLoading = true;
    },
    fetchJobDetailSuccess: (state, {payload}) => {
      state.detail.isLoading = true;
      state.detail.data = payload;
      state.detail.errors = false;
    },
    fetchJobDetailFailure: (state, {payload}) => {
      state.detail.isLoading = true;
      state.detail.errors = payload;
    },
    startProcessingInterestedJob: (state) => {
      state.processingInterestedJob = true;
    },
    finishProcessingInterestedJob: (state) => {
      state.processingInterestedJob = false;
    },
    setSelectedJob: (state, { payload }) => {
      state.selectedJob = payload;
    },
    setEligibilityData: (state, { payload }) => {
      state.eligibilityData = payload;
    },
    fetchSimilarJobs: (state) => {
      state.similarJobs.isLoading = true;
    },
    fetchSimilarJobsSuccess: (state, { payload }) => {
      state.similarJobs.isLoading = false;
      state.similarJobs.data = payload;
      state.similarJobs.errors = false;
    },
    fetchSimilarJobsFailure: (state, { payload }) => {
      state.similarJobs.isLoading = false;
      state.similarJobs.errors = payload;
    },
  },
})

// A selector
export const JobSelector = state => state.job;

// Actions
export const {
  fetchJobDetail,
  fetchJobDetailSuccess,
  fetchJobDetailFailure,
  startProcessingInterestedJob,
  finishProcessingInterestedJob,
  setSelectedJob,
  setEligibilityData,
  fetchSimilarJobsFailure,
  fetchSimilarJobs,
  fetchSimilarJobsSuccess,
} = job.actions;

export const makeInterestedJob = (payPackagesIds, chat) => {
  return async (dispatch, getState) => {

    try {
      const state = getState();
      const { interestedJobs } = state.nurse;

      let job_ids;
      if (Array.isArray(interestedJobs.data) && interestedJobs.data.length > 0) {
        job_ids = payPackagesIds.filter((job) => !interestedJobs.data.includes(job));
      } else {
        job_ids = payPackagesIds;
      }

      const url = `${Config.WAPI_URL}/nurses/interested/batch`;
      try {
        await SuperFetch.post(url, {
          job_ids,
          chat,
        });
      } catch(e) {
        throw new Error(`Invalid data from interested jobs`);
      }

      dispatch(getInterestedJobs());
      const _$ = window.jQuery;
      let $thisButton = _$(`button.btn-interested[data-jobid="${job_ids.join(',')}"]`);
      $thisButton.html('<img src="/images/icons/checkmark-white.svg" class="checkmark"/> interested</button>');
      $thisButton.addClass('mark-interested');
      $thisButton.attr('disabled', 'disabled');

      if (chat) {
        dispatch(getJobDetails(job_ids[0]));
      } else {
        dispatch(finishProcessingInterestedJob());
      }
    } catch (error) {
      dispatch(fetchInterestedJobsFailure(error));
    }
  }
};

export const getJobDetails = (id) => {
  return async (dispatch, getState) => {
    dispatch(fetchJobDetail());

    try {
      const url =`/api/v1/jobs/${id}/fulldetails`;
      const response = await SuperFetch.get(url);

      dispatch(fetchJobDetailSuccess(response.data));
      dispatch(finishProcessingInterestedJob());
    } catch (error) {
      dispatch(fetchJobDetailFailure(error));
    }
  }
};

export const getSimilarJobs = (jobId, source) => async (dispatch, getState) => {
  dispatch(fetchSimilarJobs());

  try {
    const url = `${Config.WAPI_V2_URL}/jobs/${jobId}/similar${source ? '?source=' + source : ''}`;
    const { data, success } = await SuperFetch.get(url);

    if (success && data) {
      dispatch(fetchSimilarJobsSuccess(data));
    }
  } catch (error) {
    dispatch(fetchSimilarJobsFailure(error));
  }
};

// The reducer
export default job.reducer;
