import { useState } from 'react';

const useModal = () => {
  const [statusModal, setStatusModal] = useState(false);

  function toggle() {
    setStatusModal(prevState => !prevState);
  }

  function setStatus(state) {
    setStatusModal(state);
  }

  return [
    statusModal,
    toggle,
    setStatus,
  ];
};

export default useModal;
