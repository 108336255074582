import React from "react";
import { ReactComponent as QuoteIcon } from '../../assets/images/pages/home/quotes.svg';
import { StackedCarouselSlideProps } from "react-stacked-center-carousel";
import "./Slide.scss";

export const Slide = React.memo(function (StackedCarouselSlideProps) {
    const {
        data,
        dataIndex,
        isCenterSlide,
        swipeTo,
        slideIndex
    } = StackedCarouselSlideProps;

    const {author, speciality, review} = data[dataIndex];

    return (
        <div className="card-card" draggable={false}>
            <div className={`cover fill ${isCenterSlide ? "off" : "on"}`}>
                <div
                    className="card-overlay fill"
                    onClick={() => {
                        if (!isCenterSlide) swipeTo(slideIndex);
                    }}
                />
            </div>
            <div className="detail fill">
                <div className="description">
                    <p>{review}</p>
                </div>
                <div className="author-container">
                    <div className="author-name">{author},</div>
                    <div className="author-specialty">{speciality}</div>
                </div>
            </div>

            <QuoteIcon className="quote-icon" />
        </div>
    );
});