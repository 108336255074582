import React from "react";
import Pagination from "rc-pagination";

import "./Pagination.style.scss";

export const WrapperPagination = ( { current, pageSize, total, onSizeChange, onPageChange, itemsName } ) => {
  const paginationOptions = [
    { value: 10, text: "10", default: false },
    { value: 25, text: "25", default: true },
    { value: 50, text: "50", default: false }
  ];

  const onSelectChange = ( perPage ) => {
    const page = current > total / perPage ? Math.ceil( total / perPage ) : current;
    onSizeChange( page, perPage );
  };

  const totalPages = Math.ceil(total / pageSize);

  return (<>
      <div className="pagination-wrapper flex flex-row">
        <div className="container-label-results">

        </div>
        <div className="container-paginator">
            <Pagination
              current={+current}
              pageSize={pageSize}
              total={total}
              onChange={onPageChange}
              hideOnSinglePage={true}
              prevIcon={+current !== 1 ? <div className="text-greenBrand uppercase text-10px concourse-regular"> PREV </div> : ''}
              nextIcon={+current >= totalPages ? '' : <div className="text-greenBrand uppercase text-10px concourse-regular"> NEXT </div>}
              showTitle={false}
            />
        </div>
      </div>
    </>
  );
};

export default Pagination;
