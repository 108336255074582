import Slider from 'react-slick';
import './FeaturedJobs.style.scss';
import React, { useEffect, useState } from 'react';
import { getFeaturedJobs, JobsSelector } from '../../redux/slices/jobs';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as LoadingSpinner } from '../../assets/images/loading/spinner.svg';
import { sliderSettings } from './sliderSettings';
import ReactTooltip from 'react-tooltip';
import {getFullProfessionLabel, getProfession, getTypeJobJob} from '../../helpers/utils';

const FeaturedJobs = () => {
  const { fetchingFeaturedJobs, featuredJobs } = useSelector(JobsSelector);
  const dispatch = useDispatch();

  useEffect(async () => {
    await dispatch(getFeaturedJobs());
  }, []);

  if (!fetchingFeaturedJobs && featuredJobs.length === 0) {
    return null;
  }

  const maxSlidesToShow = 5;
  let mySlides = [...featuredJobs];
  // Dirty fix to solve the slick is not working when the item is less than slidestoshow
  if (featuredJobs.length < maxSlidesToShow && mySlides.length > 0) {
    while (mySlides.length < maxSlidesToShow) {
      mySlides = mySlides.concat(mySlides);
    }
  }

  return (
    <section className="featured-jobs-section">
      <div className="home-container-fluid">
        <div className="py-4">
          <h2 className="text-center font-weight-bold pb-2 pb-md-3">
            Featured Jobs
          </h2>
          <div className="slider-container">
            {fetchingFeaturedJobs ? (
              <LoadingSpinner style={{ margin: '0 auto', display: 'block' }} />
            ) : (
              <Slider {...sliderSettings}>
                {mySlides.map((job, i) => (
                  <div
                    key={i}
                    title="Go to Job"
                    className="job font-weight-bold cursor-pointer"
                    onClick={() => {
                      window.location.href = `/nurse/jobs/compare/?id=${
                        job.pay_packages && job.pay_packages.length > 0
                          ? job.pay_packages.join(',')
                          : job.job_id
                      }`;
                    }}
                  >
                    <div className="image">
                      <img src={job.image} alt={job.image} />
                    </div>
                    <div className="d-flex details mb-2 mt-2">
                      <div className="d-flex align-items-center info">
                        <ReactTooltip
                          className="rc-tooltip"
                          effect="solid"
                          multiline={true}
                          id={`icon-${job.job_id}`}
                        >
                          {getFullProfessionLabel(+job.job_type)}
                        </ReactTooltip>
                        {getTypeJobJob(job.job_type, job.job_id, '14px')}
                        <span className="ps-1 pe-2">
                          {getProfession(job.profession_id)}
                        </span>
                      </div>
                      <div className="d-flex ellipsis-text">
                        <ReactTooltip
                          className="rc-tooltip"
                          effect="solid"
                          multiline={true}
                          id={`specialties-${job.job_id}`}
                        >
                          {job.specialties.join(', ')}
                        </ReactTooltip>
                        <span
                          className="specialties"
                          data-tip
                          data-for={`specialties-${job.job_id}`}
                        >
                          {job.specialties.map((item, _i) => (
                            <span key={_i}>
                              <span>{'\u25CF'}</span> {item}
                            </span>
                          ))}
                        </span>
                      </div>
                    </div>
                    <span className="location">{`${job.city}, ${job.state}`}</span>
                  </div>
                ))}
              </Slider>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturedJobs;
