import React, {useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import DropdownControl from "../DropdownControl";
import {JobsSelector, setFilters} from "../../redux/slices/jobs";
import {ReactComponent as IconClose} from "../../assets/images/icons/closeDropdown.svg";
import {components} from "react-select";
import {sortOptions} from "../../helpers/utils";

import './ProfessionDropdown.scss'

const SortDropdown = () => {
  const dispatch = useDispatch();
  const { filters: { sort_dropdown, typeJob }  = {} } = useSelector(JobsSelector);
  const dropdownRef = useRef(null);

  const onSelectOption = (value) => {
    if (value) {
      dispatch(setFilters({
        order_by: value.order_by,
        order: value.order,
        sort_dropdown: value,
      }));
    }
  };

  const ClearIndicator = ({ children, ...props }) => {
    return (
      <components.Control {...props} className="">
        <IconClose
          className="icon-close"
          onClick={() => {
            dispatch(setFilters({
              order_by: null,
              order: 'desc',
              sort_dropdown: null,
            }));
          }}
        />
      </components.Control>
    )
  }

  return (
    <DropdownControl
      icon={null}
      options={sortOptions[typeJob]}
      placeholder={'Select Sort Option'}
      onChange={onSelectOption}
      name="profession"
      value={sort_dropdown}
      isClearable
      ref={dropdownRef}
      customComponents={{
        ClearIndicator
      }}
    />
  );
};

export default SortDropdown;
