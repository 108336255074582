import React from 'react';
import Slider from 'react-slick';
import AgencyFlexupImg from '../../assets/images/pages/home/agencyFlexup.png';
import AgencyEpicImg from '../../assets/images/pages/home/agencyEpic.png';
import AgencyVenturaImg from '../../assets/images/pages/home/agencyVentura.png';
import { activeSearchMenu } from '../../helpers/dom';

import './AgenciesCarousel.scss';

const AgenciesCarousel = () => {
  const settings = {
    autoplay: false,
    centerMode: true,
    dots: true,
    slidesPerRow: 1,
    rows: 1,
    arrows: false,
    nextArrow: null,
    prevArrow: null,
    centerPadding: 0,
    dotsClass: 'carousel-dots m-0',
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesPerRow: 1,
          rows: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 920,
        settings: {
          slidesPerRow: 1,
          rows: 1,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesPerRow: 1,
          rows: 1,
          arrows: false,
          dots: false,
        },
      },
    ],
  };

  const openAgency = () => {
    activeSearchMenu();
    window.location.href = `/nurse/agencies`;
  };

  return (
    <section className="agencies-carousel-section">
      <div className="home-container-fluid">
        <div className="row pt-4 pb-5 pt-md-5 pb-md-4 pb-lg-5">
          <div className="col-12 col-md-6">
            <div className="slider-container">
              <Slider {...settings}>
                <div className="home-carousel-item homeLocationSearchable">
                  <a onClick={openAgency}>
                    <img
                      src={AgencyEpicImg}
                      alt="Complete your profile"
                      className="img-responsive hide-mobile"
                    />
                  </a>
                </div>
                <div className="home-carousel-item homeLocationSearchable">
                  <a onClick={openAgency}>
                    <img
                      src={AgencyFlexupImg}
                      alt="Complete your profile"
                      className="img-responsive hide-mobile"
                    />
                  </a>
                </div>
                <div className="home-carousel-item homeLocationSearchable">
                  <a onClick={openAgency}>
                    <img
                      src={AgencyVenturaImg}
                      alt="Complete your profile"
                      className="img-responsive hide-mobile"
                    />
                  </a>
                </div>
              </Slider>
            </div>
          </div>
          <div className="col-12 col-md-6" style={{display: 'flex',alignItems: 'center'}}>
            <div className="info-container">
              <h2 className="font-weight-bold">Agencies You Can Trust</h2>
              <p className="agencies-info-desc">
                Browse Agency rating and reviews with verifiable blockchain to
                increase your chance of a positive healthcare travel experience.
              </p>
              <a className="home-btn" href="/nurse/agencies">
                AGENCIES REVIEWS
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AgenciesCarousel;
