import {createSlice} from "@reduxjs/toolkit";
import {Config} from "../../config";
import SuperFetch from "../../helpers/superFetch";
import {setInitialFilters} from "./jobs";

export const initialState = {
  isLoggedIn: false,
  user_id: null,
  auth: {
    data: null,
    isLoading: false,
  },
  profile: {
    isLoading: true,
    data: [],
    errors: false,
  },
  interestedJobs: {
    isLoading: true,
    data: [],
    errors: false,
  },
  profession: {
    isLoading: true,
    data: [],
    errors: false,
  },
}

const nurse = createSlice({
  name: 'nurse',
  initialState,
  reducers: {
    fetchInterestedJobs: state => {
      state.interestedJobs.isLoading = true
    },
    fetchInterestedJobsSuccess: (state, {payload}) => {
      state.interestedJobs.isLoading = true
      state.interestedJobs.errors = false
      state.interestedJobs.data = payload
    },
    fetchInterestedJobsFailure: (state, {payload}) => {
      state.interestedJobs.isLoading = false
      state.interestedJobs.errors = payload
    },
    setAuthData: (state, {payload}) => {
      state.user_id = payload.token.user_id;
      state.isLoggedIn=  true;
      state.auth.data = payload;
    },
    fetchProfessionData: state => {
      state.profession.isLoading = true
    },
    fetchProfessionDataSuccess: (state, {payload}) => {
      state.profession.isLoading = true
      state.profession.errors = false
      state.profession.data = payload
    },
    fetchProfessionDataFailure: (state, {payload}) => {
      state.profession.isLoading = false
      state.profession.errors = payload
    },
    setIsLoadingUserData: (state) => {
      state.auth.isLoading = true;
    },
    setUserData: (state, {payload}) => {
      state.user_id = payload.id;
      state.isLoggedIn =  true;
      state.auth.data = payload;
      state.auth.isLoading = false;
    },
  },
})

// A selector
export const NurseSelector = state => state.nurse;

// Actions
export const {
  fetchInterestedJobs,
  fetchInterestedJobsSuccess,
  fetchInterestedJobsFailure,
  setAuthData,
  fetchProfessionData,
  fetchProfessionDataSuccess,
  fetchProfessionDataFailure,
  setIsLoadingUserData,
  setUserData
} = nurse.actions;

export const getInterestedJobs = () => {
  return async (dispatch, getState) => {
    dispatch(fetchInterestedJobs());
    try {
      const url = `${Config.WAPI_URL}/nurses/interested`;
      const response = await SuperFetch.get(url);
      window.interestedJobs = response.data;

      dispatch(fetchInterestedJobsSuccess(response.data));
    } catch (error) {
      dispatch(fetchInterestedJobsFailure(error));
    }
  }
};


export const getProfessionData = () => {
  return async (dispatch, getState) => {
    dispatch(fetchProfessionData());
    try {
      const url = `${Config.WAPI_URL}/nurses/my/professions`;
      const response = await SuperFetch.get(url);

      if (response.data && response.data.length > 0) {
        const activeProfession = response.data.find((item) => item.default);
        if (activeProfession.profession_id !== 4) {
          dispatch(setInitialFilters({
            profession_id: {
              value: activeProfession.profession_id,
              label: activeProfession.profession_name,
            },
            initialRequest: false
          }));
        }

        dispatch(fetchProfessionDataSuccess(activeProfession));
      }
    } catch (error) {
      dispatch(fetchProfessionDataFailure(error));
    }
  }
};

// Asynchronous thunk action
export const makeLogin = async (data) => {
  try {
    const url = `${Config.WAPI_URL}/auth/login`;
    return await SuperFetch.post(url, data);
  } catch (error) {
    console.log(error)
  }
}

export const getUserData = () => async (dispatch)  => {
  dispatch(setIsLoadingUserData());

  try {
    const url = `${Config.WAPI_URL}/auth/me`;
    const data =  await SuperFetch.get(url);

    const {phone_verified, email_verified} = data;

    if(data?.profile){
      data.profile = {...data.profile, ...{
        phone_verified: !!phone_verified, 
        email_verified: !!email_verified
      }}
    }

    dispatch(setUserData(data));
  } catch (error) {
    console.log(error)
  }
}

// The reducer
export default nurse.reducer;
