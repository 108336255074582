import React from 'react';
import nursingCE from '../../assets/images/partners/nursing-ce.svg';
import furnishedFinder from '../../assets/images/partners/furnished-finder.png';
import textus from '../../assets/images/partners/textus.svg';
import sense from '../../assets/images/partners/sense.svg';
import nrpc from '../../assets/images/partners/nrpc.png';
import ehealth from '../../assets/images/partners/ehealth.png';
import laborEdge from '../../assets/images/partners/laborEdge.png';

import './Partners.scss';

const Partners = () => (
  <section className="container-fluid partners-section">
    <div className="text-center title-container">
      <div className="inline-block">
        <h5>PARTNERS</h5>
      </div>
    </div>
    <div className="align-center partners-images container">
      <div className="row first">
        <div className="col-sm-6 text-center">
          <div className="vcenter partner-image nursing-ce">
            <div className="align-center">
              <a
                href="https://www.nursingce.com/pricing?utm_source=wanderly&utm_medium=referral&utm_campaign=wanderly-happy-traveler"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={nursingCE} />
              </a>
            </div>
          </div>
        </div>
        <div className="col-sm-6 text-center">
          <div className="vcenter partner-image furnished">
            <div className="align-center">
              <a
                href="/nurse/resources/housing?location=Los+Angeles%2C+CA&page=1&furnished=1&utilities_included=1"
                target="_blank"
              >
                <img src={furnishedFinder} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="row second">
        <div className="col-sm-4 text-start">
          <div className="vcenter partner-image textus">
            <div className="align-center">
              <a
                href="https://textus.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={textus} />
              </a>
            </div>
          </div>
        </div>
        <div className="col-sm-4 text-center">
          <div className="vcenter partner-image nrpc">
            <div className="align-center">
              <a
                href="https://www.nationalreadmissionprevention.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={nrpc} />
              </a>
            </div>
          </div>
        </div>
        <div className="col-sm-4 text-end ">
          <div className="vcenter partner-image sense">
            <div className="align-center">
              <a
                href="https://www.sensehq.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={sense} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 text-center">
          <div className="vcenter partner-image ehealth">
            <div className="align-center">
              <a
                href="https://www.ehealthinsurance.com/?allid=gba6721000"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={ehealth} />
              </a>
            </div>
          </div>
        </div>
        <div className="col-sm-6 text-center">
          <div className="vcenter partner-image staffingreferrals">
            <div className="align-center">
              <a
                href="https://www3.laboredge.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={laborEdge} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Partners;
