import React, {useState, useEffect} from "react";
import {useDispatch} from "react-redux";
import {setShift} from "../../redux/slices/jobs";
import {ReactComponent as DayShiftIcon} from "../../assets/images/icons/dayShift.svg";
import {ReactComponent as MidShiftIcon} from "../../assets/images/icons/midShift.svg";
import {ReactComponent as NightShiftIcon} from "../../assets/images/icons/nightShift.svg";

import './shifts.style.scss';

const Shifts = ( { values = [], onChange } ) => {
  const [localValues, setLocalValues] = useState(values ? values : []);

  useEffect(() => {
    if (values && values.length > 0) {
      setLocalValues(values);
    }
  }, [values]);

  const onClick = (shift) => {
    let newValues = [];
    if (localValues.some((value) => value === shift)) {
      newValues = localValues.filter((value) => value !== shift);
    } else {
      newValues = [...localValues, shift]
    }

    onChange( newValues, '', 'shifts')
    setLocalValues(newValues);
  }

  return (<>
    <label className="label">Shift</label>
    <div className="shift-container control-container">
      <button
        type="button"
        className={`link-button nurse-search-control shift-button ${localValues.includes('day') ? 'active' : ''}`}
        onClick={() => { onClick('day') }}>
        <DayShiftIcon/>
      </button>
      <button
        type="button"
        className={`link-button nurse-search-control shift-button ${localValues.includes('mid') ? 'active' : ''}`}
        onClick={() => { onClick('mid') }}>
        <MidShiftIcon/>
      </button>
      <button
        type="button"
        className={`link-button nurse-search-control shift-button ${localValues.includes('night') ? 'active' : ''}`}
        onClick={() => {  onClick('night') }}>
        <NightShiftIcon/>
      </button>
    </div>
    </>);
}

export default Shifts;
