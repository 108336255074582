import React from 'react';

import { formatPayRate, getPaymentTypeAbbr } from '../../helpers/utils';

import css from './rate.module.scss';

const Rate = ({ data }) => {
  const isHourly = data.payment_type === 'hourly';

  if (!data.hide_pay_rate) {
    if (!data.pay_packages) {
      return (
        <div className={`${css.rate} text-end`}>
          <div className="sign">$</div>
          <span className="price">
            {isHourly ? formatPayRate(data.payment_min, 0) : formatPayRate(+data.payment_min, 0)}
          </span>
          <small>/ {getPaymentTypeAbbr(data.payment_type)}</small>
        </div>
      );
    }

    const minPayment = isHourly
      ? formatPayRate(data.payment_min, 0)
      : formatPayRate(+data.payment_min, 0);
    const maxPayment = isHourly
      ? formatPayRate(data.payment_max, 0)
      : formatPayRate(+data.payment_max, 0);

    return (
      <div className={`${css.rate} text-end`}>
        {maxPayment > minPayment ? (
          <>
            {minPayment > 0 && (
              <>
                <div className="sign">$</div>
                <span className="price">{minPayment}</span>
              </>
            )}
            {maxPayment > 0 && (
              <>
                <span className="separator"> - </span>
                <div className="sign">$</div>
                <span className="price">{maxPayment}</span>
              </>
            )}
          </>
        ) : (
          <>
            <div className="sign">$</div>
            <span className="price">{minPayment}</span>
          </>
        )}
        <small>/{getPaymentTypeAbbr(data.payment_type)}</small>
      </div>
    );
  }

  return (
    <div className={`${css.rate} text-end`}>
      {data.hidden_pay_rate_text ? data.hidden_pay_rate_text : 'Contact recruiter for more details'}
    </div>
  );
};

export default Rate;
