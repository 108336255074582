import React from 'react';
import './specialtyFaqs.scss'

const SpecialtyFaqs = ({ data }) => {
  const { title, excerpt, faqs } = data;

  const getMainEntity = () => {
    return faqs.map((faq) => (
      {
        "@type": "Question",
        "name": faq.question,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": faq.answer
        }
      }
    ))
  }

  const faqStructuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": getMainEntity()
  };

  return (
    <section className={`full-width bg-gray results-container specialty-faqs`}>
      <div className="container-custom flex specialty-faqs-container">
        <div className="specialty-faqs">
          <div className="specialty-faqs-title">{ title }</div>
          <div className="specialty-faqs-excerpt">{ excerpt }</div>
          {
            faqs.map((faq) => (
              <div className="question" key={faq.id}>
                <div className="question-title">{faq.question}</div>
                <div className="question-answer"  dangerouslySetInnerHTML={{
                  __html: faq.answer,
                }} />
              </div>
            ))
          }
        </div>
      </div>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(faqStructuredData),
        }}
      />
    </section>
  )
}

export default SpecialtyFaqs;
