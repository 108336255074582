import { createSlice } from "@reduxjs/toolkit";
import {Config} from "../../config";
import SuperFetch from "../../helpers/superFetch";

export const initialState = {
  optionsDropdown: []
}

// A slice for recipes with our 3 reducers
const recruiters = createSlice({
  name: 'recruiters',
  initialState,
  reducers: {
    setRecruitersOptionsDropdown : (state, {payload}) => {
      state.optionsDropdown =  payload;
    },
  },
})

// A selector
export const RecruitersSelector = state => state.specialties;

// Actions
export const {
  setRecruitersOptionsDropdown
} = recruiters.actions;

// Asynchronous thunk action
export const getRecruiters = async (term) => {
  if (!term || term.length === '') return;
  try {
    const url = `${Config.WAPI_URL}/jobs/suggestion/recruiter/?text=${term}`;
    const response = await SuperFetch.get(url);

    if (response.data.length > 0 ) {
      return {
        value: response.data[0].id,
        label: response.data[0].name,
        fakeLabel: `${response.data[0].name}, ${response.data[0].agency}`,
      };
    }
  } catch (error) {
    console.log(error)
  }
}

// Asynchronous thunk action
export const getRecruiterById = async (id) => {
  if (!id) return;

  try {
    const url = `${Config.WAPI_URL}/recruiters/${id}`;
    const response = await SuperFetch.get(url);

    if (response.data.length > 0 ) {
      const name = `${response.data[0].firstname} ${response.data[0].lastname}`;
      return {
        value: response.data[0].user_id,
        label: name,
        fakeLabel: `${name}`,
      };
    }
  } catch (error) {
    console.log(error)
  }
}

// The reducer
export default recruiters.reducer;
