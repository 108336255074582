import React, { useEffect, useState } from 'react';

import BasicInformationForm from '../BasicInformationForm/BasicInformationForm';
import { MobileNav } from './MobileNav';

const InterestedModal = ({ toggle, job, nurseProfile, eligibilityData, toggleSimilarJobsModal }) => {
  return (
    <>
      <div
        id="minimumInformationModal"
        className="modal fade modal-wanderly-chat profile-modal in"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="minimumInformationModalModalLabel"
        data-keyboard="false"
        data-backdrop="static"
        style={{ display: 'block' }}
      >
        <div className="modal-dialog" role="document">
          <MobileNav pageTitle={'Job Interested'} backClick={toggle} />
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="glyphicon glyphicon-remove close"
                onClick={toggle}
              ></button>
            </div>
            <div className="modal-body" style={{ margin: 0 }}>
              <div>
                <div id="minimum-information-modal-content">
                  <BasicInformationForm
                    profile={nurseProfile}
                    job={job}
                    toggle={toggle}
                    eligibilityData={eligibilityData}
                    toggleSimilarJobsModal={toggleSimilarJobsModal}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade in"></div>
    </>
  );
};

export default InterestedModal;
