import useSWR from 'swr';
import {fetcher} from "../helpers/utils";
import {Config} from "../config";

const GetFeaturedAgencies = () => {
    const { data, error } = useSWR(`${Config.WAPI_URL}/agencies/featured`, fetcher, {revalidateOnFocus: false});

    return {
        agenciesData: data ? data.data : data,
        agenciesError: error
    }
}

const GetFeaturedDestinations = () => {
    const { data, error } = useSWR(`${Config.WAPI_URL}/nurse/destinations`, fetcher, {revalidateOnFocus: false});

    return {
        destinationData: data,
        destinationError: error
    }
}

const GetBlogPosts = () => {
    const { data, error } = useSWR(`${Config.WAPI_URL}/blog/posts`, fetcher, {revalidateOnFocus: false});

    return {
        blogData: data ? data.data : data,
        blogError: error
    }
}

export { GetFeaturedAgencies, GetFeaturedDestinations, GetBlogPosts };