import {Config} from "../config";
import SuperFetch from "./superFetch";

export const sendAnalytics = async(jobId, rate, total) => {
  try {
    const tokenId = localStorage.getItem('tokenId');
    const url = `${Config.WAPI_URL}/jobs/track`;
    SuperFetch.post(url, {
      job_id: jobId,
      rate,
      total,
      user_id: tokenId ? tokenId : null
    });
  } catch (error) {
    console.log(error)
  }
};

export const fetchJobCounts = async(params) =>  {
  try {
    const url = `${Config.WAPI_URL}/jobs/count?${params}`;
    const response = await SuperFetch.get(url);

    return {
      data: response.data.total,
      totalPPCount: response.data.totalPPCount
    };
  } catch (error) {
    console.log(error);
  }
}
