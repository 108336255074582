import {ReactComponent as CrossIcon} from "../../assets/images/icons/cross.svg";
import {ReactComponent as FilesIcon} from "../../assets/images/icons/files.svg";
import {ReactComponent as NurseIcon} from "../../assets/images/icons/nurse.svg";
import {hasAccessToken} from "../../helpers/auth";

import  './HowItWorks.style.scss';

const HowItWorks = () => {
  const redirectUrl = () => (!window.isLogged && !hasAccessToken()) ? '/nurse/login' : '/nurse/profile';

  return (
    <section className="how-it-works-section">
      <div className="home-container-fluid">
        <div className="text-center px-2 py-4">
          <h2 className="text-center font-weight-bold">How It Works</h2>
          <div className='row'>
            <div className='col-12 col-md-6'>
              <div className="image-container d-flex align-items-center">
                <div className="image" />
              </div>
            </div>

            <div className="col-12 col-md-6 pt-lg-3">
              <div className='d-flex flex-column mt-3 flex-sm-row'>
                <div className="mb-3">
                  <NurseIcon />
                </div>
                <div className="info px-sm-3">
                  <h3 className='font-weight-bold'>Create your profile</h3>
                  <p>Save time by filling out your application information once, allowing you to apply for thousands of travel nursing positions all over the country.</p>
                </div>
              </div>
              <div className='d-flex flex-column mt-3 flex-sm-row'>
                <div className="mb-3">
                  <FilesIcon />
                </div>
                <div className="info px-sm-3">
                  <h3 className='font-weight-bold'>Search Jobs and compare pay packages</h3>
                  <p>Search and compare travel nursing assignments based on location, specialty, pay rate, and healthcare systems to find the best assignment for you.</p>
                </div>

              </div>
              <div className='d-flex flex-column mt-3 flex-sm-row'>
                <div className="mb-3">
                  <CrossIcon />
                </div>
                <div className="info px-sm-3">
                  <h3 className='font-weight-bold'>Find your next assignment</h3>
                  <p>Once you find your perfect assignment simply submit your application or begin a chat with the recruiter. The power is in your hands!</p>
                </div>
              </div>
            </div>
          </div>
          <a id={`${window.isLogged || hasAccessToken() ? '' : 'sign-in-btn' }`}
             className="sign-in-btn d-block home-btn mt-3 mb-sm-3 redirect-to-profile"
             href={`${window.isLogged || hasAccessToken() ? '/nurse/profile' : '#' }`}>COMPLETE PROFILE</a>
        </div>
      </div>
    </section>
  )
}

export default HowItWorks;