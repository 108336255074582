import React, { useEffect, useState } from 'react';
import {
  JobsSelector,
  clearTopFilters,
  setFilters,
} from '../../redux/slices/jobs';
import {
  getLocations,
  setLocationsOptionsDropdown,
} from '../../redux/slices/locations';
import { useDispatch, useSelector } from 'react-redux';
import ProfessionDropdown from '../ProfessionDropdown';
import SpecialtyDropdown from '../SpecialtyDropdown';
import LocationDropdown from '../LocationDropdown';
import JobTypesDropdown from '../JobTypesDropdown';
import TypeJobsContainer from '../TypeJobsContainer';
import { useMediaQuery } from 'react-responsive';

import DatePicker from '../DatePicker/DatePicker';
import searchIcon from '../../assets/images/icons/search.svg';

import './SearchControlBox.scss';
import SeoSearchCity from "../SeoSearchCity";


const SearchControlBox = ({ triggerSearch, showResultItemsClass, seoFriendlyUrlData, showSeoCity= true }) => {
  const dispatch = useDispatch();
  const { filters: { typeJob, date, is_asap } = {} } =
    useSelector(JobsSelector);
  const isLargeScreen = useMediaQuery({
    query: '(min-width: 992px)',
  });

  const setTypeOfJob = (type) => {
    const filters = {
      typeJob: type,
     // profession_id: null,
     // specialty_ids: null,
      //location: null,
      //date: null,
      //is_asap: false,
      rate: null,
      //shift: [],
      assignment_length: [],
      hospital: null,
      agency: null,
      recruiter_ids: null,
      //sort_dropdown: null,
      //order_by: null,
      order: 'desc',
    };
    let filtersToKeep = [];
    switch(type){
      case'domestic':
        filtersToKeep = ['rate','assignment_length', 'agency'];
        break;
      case'permanent':
        filtersToKeep = ['agency', 'hospital'];
        break;
      case'local': 
        filtersToKeep = ['assignment_length', 'agency'];
        break;
      case'irp':
      case'perDiem': 
        filtersToKeep = ['agency'];
        break;
    }
    filtersToKeep.map(f => {
      delete filters[f];
    })
    dispatch(
      setFilters(filters)
    );
  };

  const onChangeDate = (date) => {
    dispatch(
      setFilters({
        date: date,
        is_asap: false,
      })
    );
  };

  const onClickAsap = () => {
    dispatch(
      setFilters({
        is_asap: !is_asap,
        date: null,
      })
    );
  };

  const resetFilters = async () => {
    const locations = await getLocations('', true);
    await dispatch(setLocationsOptionsDropdown(locations));
    await dispatch(clearTopFilters());
  };

  return (
    <>
      {seoFriendlyUrlData?.location ? (
        <div>
           <span className={`section-seo-search-controls section-search-controls ${ !seoFriendlyUrlData?.location?.name || !seoFriendlyUrlData?.location?.image  ? 'section-seo-search-controls-no-padding' : '' }`}
                 role="img"
                 aria-label={seoFriendlyUrlData?.location?.name}
                 style={seoFriendlyUrlData?.location?.image ? {  backgroundImage: `${showSeoCity ? `url(${(seoFriendlyUrlData?.location?.image) || ""})` : ''}` } : {}} >
            {showSeoCity ? <SeoSearchCity data={seoFriendlyUrlData} /> : (<div className={`${showResultItemsClass} section-search-controls`}>
              <div className="container-seo-search--controls container-search--controls">
                <div className="container-seo-search--title-search">
                  <h1>Search Healthcare Jobs</h1>
                </div>
              </div>
            </div>)}

               <div className={`${showResultItemsClass} mb-5`}>
              {isLargeScreen && (
                <TypeJobsContainer
                  selectedTypeJob={typeJob}
                  setTypeOfJob={setTypeOfJob}
                  isLargeScreen={isLargeScreen}
                />
              )}
                <div className="container-seo-search--controls container-search--controls">
                <div className="container-seo-search--controls-flex">
                  {!isLargeScreen && (
                    <div
                      className="filter-container--job-types"
                      style={{ marginBottom: 20 }}
                    >
                      <JobTypesDropdown />
                    </div>
                  )}

                  <div className="filter-profession">
                    <ProfessionDropdown />
                  </div>

                  <div className="filter-specialties">
                    <SpecialtyDropdown placeholder="Enter Specialties, e.g. ICU, OB Tech, etc." />
                  </div>
                  {typeJob !== 'permanent' && (
                    <div className="filter-date">
                      <DatePicker
                        className="full-width"
                        minDate={new Date()}
                        selected={date ? new Date(date) : null}
                        onChange={onChangeDate}
                        isClearable
                      />
                      <button
                        className={`link-button nurse-search-control ${
                          is_asap ? 'active' : ''
                        }`}
                        onClick={onClickAsap}
                      >
                        asap
                      </button>
                    </div>
                  )}
                  <div
                    className={`filter-location ${
                      typeJob === 'permanent' ? 'only-permanent' : ''
                    }`}
                  >
                    <LocationDropdown />
                  </div>
                </div>
                <div className="container-seo-search--controls-clear hide-mobile">
                  <button
                    className="link-button clear-controls"
                    onClick={resetFilters}
                  >
                    Clear All
                  </button>
                </div>
              </div>

              <div className="container-seo-search--button">
                <button
                  className="btn btn-black search-action full-width"
                  onClick={triggerSearch}
                >
                  <img src={searchIcon} alt="Search Jobs" />
                </button>
              </div>
            </div>
          </span>
        </div>
        ) : (
        <div className={`${showResultItemsClass} section-search-controls`}>
          <div className="container-search">
            <div className="container-search--title">
              <h1>Search Healthcare Jobs</h1>
            </div>

            {isLargeScreen && (
              <TypeJobsContainer
                selectedTypeJob={typeJob}
                setTypeOfJob={setTypeOfJob}
                isLargeScreen={isLargeScreen}
              />
            )}
            <div className="container-search--controls">
              <div className="container-search--controls-flex">
                {!isLargeScreen && (
                  <div
                    className="filter-container--job-types"
                    style={{ marginBottom: 20 }}
                  >
                    <JobTypesDropdown />
                  </div>
                )}

                <div className="filter-profession">
                  <ProfessionDropdown />
                </div>

                <div className="filter-specialties">
                  <SpecialtyDropdown placeholder="Enter Specialties, e.g. ICU, OB Tech, etc." />
                </div>
                {typeJob !== 'permanent' && (
                  <div className="filter-date">
                    <DatePicker
                      className="full-width"
                      minDate={new Date()}
                      selected={date ? new Date(date) : null}
                      onChange={onChangeDate}
                      isClearable
                    />
                    <button
                      className={`link-button nurse-search-control ${
                        is_asap ? 'active' : ''
                      }`}
                      onClick={onClickAsap}
                    >
                      asap
                    </button>
                  </div>
                )}
                <div
                  className={`filter-location ${
                    typeJob === 'permanent' ? 'only-permanent' : ''
                  }`}
                >
                  <LocationDropdown />
                </div>
              </div>
              <div className="container-search--controls-clear hide-mobile">
                <button
                  className="link-button clear-controls"
                  onClick={resetFilters}
                >
                  Clear All
                </button>
              </div>
            </div>

            <div className="container-search--button">
              <button
                className="btn btn-black search-action full-width"
                onClick={triggerSearch}
              >
                <img src={searchIcon} alt="Search Jobs" />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SearchControlBox;
