import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {DropdownSelectAsync} from '@orionhcs/wanda-js-components';
import {JobsSelector, setFilters} from "../../redux/slices/jobs";
import {Config} from "../../config";
import SuperFetch from "../../helpers/superFetch";
import {debounce} from "underscore";

import './AgenciesDropdown.style.scss';

const AgenciesDropdown = () => {
  const [optionsDropdown, setOptionsDropdown] = useState([]);
  const {filters: { agency }  = {} } = useSelector(JobsSelector);
  const dispatch = useDispatch();

  const getFacilities = async (input, callback) => {
    if (!input || input.length === '') {
      return Promise.resolve({ options: [] });
    }

    const url = `${Config.WAPI_URL}/jobs/suggestion/agency/?text=${input}`;
    const response = await SuperFetch.get(url);
    const options = Object.values(response?.data).map((l) => ({ value: l.id, label: l.name }))

    setOptionsDropdown(options);
    callback(options);
  }

  const onSelectOption = (value) => {
    dispatch(setFilters({
      agency: value
    }));
  };

  const resetOptions = () => setOptionsDropdown([]);
  const getOptions = debounce(getFacilities, 800);

  return (
    <DropdownSelectAsync
      isMulti
      noOptionsMessage={() => null}
      closeMenuOnSelect={true}
      hideSelectedOptions={false}
      icon={null}
      placeholder={"Enter Organization Name"}
      classname={"facility"}
      name="facility"
      loadOptions={getOptions}
      cacheOptions={false}
      defaultOptions={optionsDropdown}
      onChange={onSelectOption}
      value={agency}
      maxMenuHeight={150}
      onMenuClose={resetOptions}
    />
  );
};

export default AgenciesDropdown;
