import { createSlice } from "@reduxjs/toolkit";
import {Config} from "../../config";
import SuperFetch from "../../helpers/superFetch";

export const initialState = {
  professions: [],
  professionsOriginal: [],
  errors: false,
  loading: false,
}

// A slice for recipes with our 3 reducers
const professionsSlice = createSlice({
  name: 'professions',
  initialState,
  reducers: {
    fetchProfessions: state => {
      state.loading = true
    },
    fetchProfessionsSuccess: (state, { payload }) => {
      state.professions = payload
      state.loading = false
      state.errors = false
    },
    fetchProfessionsFailure: state => {
      state.loading = false
      state.errors = true
    },
    setProfessionsOriginal: (state, { payload }) => {
      state.professionsOriginal = payload
    },
  },
})

// A selector
export const professionsSelector = state => state.professions;

// Actions
export const { fetchProfessions, fetchProfessionsSuccess, fetchProfessionsFailure, setProfessionsOriginal } = professionsSlice.actions;

// The reducer
export default professionsSlice.reducer;


// Asynchronous thunk action
export const getProfessions = () => {

  return async (dispatch) => {
    await dispatch(fetchProfessions());

    try {
      const url = `${Config.WAPI_URL}/professions/filtered`;
      const response = await SuperFetch.get(url);

      const professions = response.data.filter((item) => item.id !== 4).map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });

      await dispatch(fetchProfessionsSuccess(professions));
      await dispatch(setProfessionsOriginal(professions));
    } catch (error) {
      dispatch(fetchProfessionsFailure())
    }
  }
}
