import { createSlice } from "@reduxjs/toolkit";
import {Config} from "../../config";
import SuperFetch from "../../helpers/superFetch";

export const initialState = {
  optionsDropdown: [],
  optionsDropdownOriginal: []
}

// A slice for recipes with our 3 reducers
const specialties = createSlice({
  name: 'specialties',
  initialState,
  reducers: {
    setSpecialitiesOptionsDropdown : (state, {payload}) => {
      state.optionsDropdown =  payload;
    },
    setSpecialitiesOptionsOriginal : (state, {payload}) => {
      state.optionsDropdownOriginal =  payload;
    },
  },
})

// A selector
export const SpecialtiesSelector = state => state.specialties;

// Actions
export const {
  setSpecialitiesOptionsDropdown,
  setSpecialitiesOptionsOriginal
} = specialties.actions;

// Asynchronous thunk action
export const getSpecialties = async (term, profession= '', exact = false ) => {
  if (!term || term.length === '') return;
  try {
    const url = `${Config.WAPI_URL}/jobs/suggestion/specialty/?text=${term}${profession ? `&profession=${profession}`: '' }`;
    const response = await SuperFetch.get(url);

    if (!response?.data) return false;

    let specialtyToReturn;

    if (exact) {
      specialtyToReturn = response?.data?.find(
        (specialty) => specialty.name.toLowerCase() === term.toLowerCase() && profession === specialty.profession_id
      );
    } else {
      specialtyToReturn = response?.data[0];
    }

    return {
      value: specialtyToReturn.id,
      label: specialtyToReturn.name,
      fakeLabel: specialtyToReturn.name,
    };
  } catch (error) {
    console.log(error)
  }
}

// The reducer
export default specialties.reducer;
