import './NavSettings.scss';

const NavSettings = () => {

  return (
    <div  className="container-navbar-settings">
      <nav className="">
        <div className="navbar-search-jobs container-search">
          <div className="navbar-search-jobs--item">
            <a className="link" href="/nurse/accountsettings/notification">Notifications</a>
          </div>
          <div className="navbar-search-jobs--item">
            <a className="link selected" href="/nurse/accountsettings/preference">Job Preference</a>
          </div>
        </div>
      </nav>

      <nav className="container-fluid container-navbar-search-jobs mobile-nav only-mobile-md" style={{ marginTop: '14px'}}>
        <div className="navbar-search-jobs container-search">
          <div className={`navbar-search-jobs--item`}>
            <a className="link" href="/nurse/accountsettings/notification">
              <span className="title">Notifications</span>
            </a>
          </div>
          <div className={`navbar-search-jobs--item `}>
            <a className="link selected" href="/nurse/accountsettings/preference">
              <span className="title">Preferences</span>
            </a>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default NavSettings;
