import React from "react";
import {ReactComponent as LoadingSpinner} from "../../assets/images/loading/spinner.svg";
import InfiniteScroll from "react-infinite-scroll-component";

const MobileCards = ({ jobs,  jobCards, scrollFetchData, currentPage, total }) => {
  return (
    <InfiniteScroll
      dataLength={jobs.length}
      next={() => {
        currentPage++
        scrollFetchData(currentPage)
      }}
      hasMore={currentPage < Math.ceil(total / 20)}
      loader={<div className="text-center"> <LoadingSpinner /> </div>}
    >
      {jobCards}
    </InfiniteScroll>
  );
}

export default MobileCards;
