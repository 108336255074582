// External Modules
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Internal Modules
import SearchJobsBox from '../SearchJobsBox';

import wandaLogo from '../../assets/images/pages/home/wanda.png';

// Helpers
import { hasAccessToken } from '../../helpers/auth';

// Styles
import './HeroSection.style.scss';

// Redux Actions & Selectors
import {
  getNotifications,
} from '../../redux/slices/notifications';
import { getUserData, NurseSelector } from '../../redux/slices/nurse';
import NavigationMenu from "./NavigationMenu";

const HeroSection = ({ toggleModal }) => {
  const { auth: { data: user } } = useSelector(NurseSelector);

  return (
    <section className="hero-section">
      <NavigationMenu toggleModal={toggleModal} />

      {/* HERO */}
      <div className="home-container-fluid">
        <div className="pt-5 pb-1 pb-md-5">
          <div className="d-flex flex-column pt-lg-5">
            <div className="pt-5 text-center pt">
              <h1>Discover Your Perfect Job</h1>
              <h3>SEARCH THOUSANDS OF HEALTHCARE PAY PACKAGES</h3>
            </div>
            {!window.isLogged && !hasAccessToken() && (
              <div className="mobile-action-btn d-flex flex-column text-center py-4">
                <button
                  id="join-for-free-btn"
                  type="button"
                  className="join-btn mb-3 btn-join-react"
                >
                  JOIN FOR FREE
                </button>
                <a
                  id="sign-in-btn"
                  className="signInButtonLight sign-in-btn"
                  href="/nurse/login"
                >
                  SIGN IN
                </a>
              </div>
            )}
            <div className="search-jobs-container position-relative">
              <SearchJobsBox wandaLogo={wandaLogo} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
