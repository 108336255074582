import { Button, Checkbox } from '@orionhcs/wanda-js-components';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as AddIcon } from '../../assets/images/icons/add-file.svg';
import { ReactComponent as IconSpecialties } from '../../assets/images/icons/icon-search.svg';
import { ReactComponent as IconDelete } from '../../assets/images/icons/trashcan-icon.svg';

import { getSpecialties } from '../../redux/slices/specialties';
import ApplyFormConfig from './applyFormConfig';
import DropdownControl from '../DropdownControl';
import Input from '../Form/Input/Input';
import InputMask from '../Form/InputMask/';
import ProfessionDropdown from '../Form/ProfessionDropdown';
import SpecialtyDropdown from '../Form/SpecialtyDropdown';
import {
  getInterestedJobs,
  sendApplication,
  sendInterestedBatch,
  updateProfile,
} from './applicationRequests';
import css from './BasicInformationForm.module.scss';
import useBreakpoint from '../../hooks/useBreakpoint';
import { markAsInterestedButton, myMD5 } from '../../helpers/utils';

const BasicInformationForm = ({ job, profile, toggle, eligibilityData, toggleSimilarJobsModal  }) => {
  const screenSize = useBreakpoint();
  const [isReadyToFillExperience, setIsReadyToFillExperience] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { requirements } = eligibilityData || {};
  const isLargeScreen = useMediaQuery({
    query: '(min-width: 768px)',
  });

  const initialValues = {
    specialties: [],
    selectedProfession: null,
    userSpecialties: [],
    profile: null,
  };

  const [formValues, setFormValues] = useReducer(
    (curValues, newValues) => ({ ...curValues, ...newValues }),
    initialValues
  );

  const {
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    getValues,
    trigger,
    reset,
    control,
    unregister,
    setError
  } = useForm({ mode: 'onChange' });

  const getSpecialtiesNurse = async (jobSpecialties, professionId) => {
    try {
      const specialties = await Promise.all(
        jobSpecialties.map(async (item) => {
          if (!item) return false;
          const specialty = await getSpecialties(item, professionId, true);
          const specialtyWithData = { disallowToDelete: true, ...specialty };

          if (specialty.value) {
            setValue(`specialties[${specialty.value}]`, specialtyWithData);
          }

          return specialtyWithData;
        })
      );

      setFormValues({
        specialties,
      });
      setIsReadyToFillExperience(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (job?.profession_id) {
      setValue('profession', job?.profession_id);
      setFormValues({
        selectedProfession: job?.profession_id,
      });
    }

    if (job?.specialty.length) {
      getSpecialtiesNurse(job?.specialty, job?.profession_id);
    }
  }, [job]);

  /** Fill specialty Experience **/
  useEffect(async () => {
    if (isReadyToFillExperience && profile?.specialties?.length > 0) {
      setIsLoading(true);

      const specialtiesProfile = await Promise.all(
        profile?.specialties?.map(async (specialty) => {
          setValue(
            `specialties_experience[${specialty.speciality_id}]`,
            specialty.years_exp
          );
          await trigger(`specialties_experience[${specialty.speciality_id}]`);

          return specialty.speciality_id;
        })
      );

      formValues.specialties?.map((specialty) => {
        let match = false;

        if (specialtiesProfile.includes(specialty.value)) {
          match = true;
        }

        return { ...specialty, match };
      });

      setIsReadyToFillExperience(false);
    } else if (!isReadyToFillExperience) {
      setIsLoading(false);
    }
  }, [profile, isReadyToFillExperience]);

  useEffect(
    () => () => {
      reset({
        first_name: '',
        last_name: '',
        profession: '',
        specialties: '',
        phone: '',
        email: '',
        source_knowledge: '',
        current_working_traveler: '',
        current_facility: '',
        address: '',
        city: '',
        zip: '',
        state: '',
        legal_worker: false
      });
    },
    []
  );

  useEffect(async () => {
    let data = {};

    if (Object.keys(profile).length > 0) {
      if (profile?.first_name) {
        setValue('first_name', profile?.first_name);
        await trigger('first_name');
        data = { ...data, first_name: profile?.first_name };
      }

      if (profile?.last_name) {
        setValue('last_name', profile?.last_name);
        await trigger('last_name');
        data = { ...data, last_name: profile?.last_name };
      }

      if (profile?.home_phone_number) {
        const cleaned = profile.home_phone_number.replace(/[()\s-]+/g, '');
        const formatted = cleaned.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
        setValue('phone', formatted);
        await trigger('phone');
        data = { ...data, phone: formatted };
      }

      if (profile?.email) {
        setValue('email', profile?.email);
        await trigger('email');
        data = { ...data, email: profile?.email };
      }

      if (profile?.legal_worker) {
        setValue('legal_worker', profile?.legal_worker);
        await trigger('legal_worker');
        data = { ...data, legal_worker: profile?.legal_worker };
      }

      if (
        profile?.active_profession &&
        job?.profession_id == profile?.active_profession?.profession_id
      ) {
        setValue(
          'years_experience_profession',
          profile?.active_profession?.years_exp
        );
        await trigger('years_experience_profession');
        data = {
          ...data,
          years_experience_profession: profile?.active_profession?.years_exp,
        };
      }
    }
  }, [profile]);

  /**
   * Bind loading css class to sent element (e), if element it's not sent, a full screen spinner will be displayed
   * @return {void}
   * @param dataForm {Object} React Hook Form object
   */
  const onSubmit = async (dataForm) => {
    setIsLoading(true);

    // Prepare data before submit
    let specialties = [];
    let experience;

    if (
      dataForm.specialties?.length > 0 &&
      dataForm.specialties_experience?.length > 0
    ) {
      const filterSpecialties = dataForm.specialties;
      const filterSpecialtiesYears = dataForm.specialties_experience;

      for (const key in filterSpecialties) {
        if (filterSpecialties[key]) {
          for (const key2 in filterSpecialtiesYears) {
            if (filterSpecialtiesYears[key2]) {
              experience = +filterSpecialtiesYears[key];
            }
          }

          specialties.push({
            id: filterSpecialties[key].value,
            name: filterSpecialties[key].label,
            experience,
          });
        }
      }
    } else {
      return false;
    }

    // Merge Job Specialties with Profile Specialties
    if (profile?.specialties && profile?.specialties.length > 0) {
      const profileSpecialties = profile.specialties.map((s) => {
        return {
          experience: s.years_exp,
          id: s.speciality_id,
          name: s.speciality.name,
        };
      });

      const mergedSpecialties = [...specialties, ...profileSpecialties];
      specialties = Array.from(new Set(mergedSpecialties.map((a) => a.id))).map(
        (id) => {
          return mergedSpecialties.find((a) => a.id === id);
        }
      );
    }

    // Map parsed data form
    dataForm = {
      profession: dataForm.profession,
      experience: dataForm.years_experience_profession,
      profile: {
        first_name: dataForm.first_name,
        last_name: dataForm.last_name,
        home_phone_number: dataForm.phone,
        email: dataForm.email,
        legal_worker: dataForm.legal_worker
      },
      specialties,
      check_duplicate: true
    };

    // Update Profile
    const { success: successProfile, message } = await updateProfile(dataForm);

    if (successProfile) {
      const _$ = window.jQuery;
      const applicationResponse = await sendApplication({job_ids: job.pay_package_ids}, false, false, 3);
      const { success: successApplication } = applicationResponse;

      if (successApplication) {
        const $thisButton = _$(
          `button.btn-interested[data-jobid="${job.pay_package_ids.join(',')}"]`
        );

        if (job.agency_allows_interactions === true) {
          markAsInterestedButton($thisButton);
          await sendInterestedBatch(job.pay_package_ids, false);
          window.interestedJobs = await getInterestedJobs();
        }
        toggle();
        if (job.agency_allows_interactions === true) {
          _$(document).trigger('ShowSuccessCheckmarkEvt', 2500);
        }
        //Toggle Similar Jobs Modal
        if (job.agency_allows_interactions === false) {
          toggleSimilarJobsModal();
        }
      } else {
        setErrorMessage('An error has occurred, please try again later.');
        setTimeout(() => setErrorMessage(''), 3000);
      }
    } else if(message?.errors) {
      for (let key in message.errors) {
        const fieldName = key === 'home_phone_number' ? 'phone' : key;
        setError(fieldName, { type: 'custom', message: message.errors[key] })
      }
    }else{
      setErrorMessage('An error has occurred, please try again later.');
      setTimeout(() => setErrorMessage(''), 3000);
    }
    setIsLoading(false);
  };

  const onSelectOption = async (value, select, name) => {
    if (name === 'profession') {
      setValue('specialties', undefined);

      setFormValues({
        selectedProfession: value,
        specialties: [],
      });
    }

    setValue(name, value);
    await trigger();
  };

  const addSpecialtyToList = async () => {
    const oldSpecialties = formValues.specialties;
    const newSpecialtyId = oldSpecialties.length * Date.now();

    setValue(`specialties[${newSpecialtyId}]`, null);
    setValue(`specialties_experience[${newSpecialtyId}]`, null);

    const newSpecialties = [
      ...oldSpecialties,
      { value: newSpecialtyId, name: `specialties[${newSpecialtyId}]`, isClearable: true },
    ];

    setFormValues({
      specialties: newSpecialties,
    });
    await trigger(`specialties[${newSpecialtyId}]`);
    await trigger(`specialties_experience[${newSpecialtyId}]`);
  };

  const isDisabled =(field) => {
    return (field === 'email' && profile?.email_verified) ||(field === 'phone' && profile?.phone_verified)
  }

  const renderField = (field, props) => {
    switch (field.type) {
      case 'input':
        return (
          <Input
            className={`${errors[field.key] ? 'invalid-input' : ''} ${
              css['input-text']
            }`}
            defaultValue={props.field.value}
            disabled={isDisabled(field.key)}
            verified={isDisabled(field.key)}
            errors={errors[field.key]}
            isOptional={field.isOptional}
            isRequired={!field.isOptional}
            label={field.label}
            labelClass={`${css.label} ${
              field.key === 'years_experience_profession'
                ? css['label-exp']
                : ''
            }`}
            maxLength={field.key === 'years_experience_profession' ? 2 : false}
            name={field.name}
            onChange={async (e, value) => {
              setValue(props.field.name, value);
              await trigger(props.field.name);
            }}
            onlyNumbers={field.key === 'years_experience_profession'}
            placeholder={field.placeHolder}
            type={field.typeText}
            tooltip={(field.key == 'email' && isDisabled(field.key))?  'To update email, please contact support@wanderly.us' : ''} 
          />
        );
      case 'profession':
        return (
          <ProfessionDropdown
            classname={`${errors[field.key] ? 'invalid' : ''} ${
              css['input-dropdown']
            }`}
            errors={errors[field.key]}
            isControlled
            isDisabled
            label={field.label}
            labelClass={css.label}
            onChange={onSelectOption}
            showIndicator={false}
            showPlaceholder={false}
            value={props.field.value}
          />
        );
      case 'specialties':
        return (
          <SpecialtyDropdown
            classname={`${errors[field.key] ? 'invalid' : ''} ${
              css['input-dropdown']
            }`}
            errors={errors[field.key]}
            icon={<IconSpecialties />}
            isControlled
            isMulti={false}
            label={field.label}
            labelClass={css.label}
            name={field.name}
            onChange={onSelectOption}
            placeholder={field.placeHolder}
            profession={job?.profession_id}
            showIcon
            showPlaceholder={false}
            value={props.field.value}
            showCounters={false}
          />
        );
      case 'maskedInput':
        return (
          <InputMask
            className={`${css['input-text']}`}
            errors={errors[field.key]}
            label={field.label}
            labelClass={css.label}
            mask={field.mask}
            name={props.field.name}
            onChange={async (value) => {
              const cleanValue = value.replaceAll('_', '').trim();
              setValue(props.field.name, cleanValue);
              await trigger(props.field.name);
            }}
            placeholder={field.maskPlaceHolder}
            required
            value={props.field.value}
            disabled={isDisabled(field.key)}
            verified={isDisabled(field.key)}
            tooltip={(field.key == 'phone' && isDisabled(field.key))? 'To update Phone Number, please contact support@wanderly.us' : ''} 
          />
        );
      case 'dd':
        return (
          <DropdownControl
            classname={`${errors[field.key] ? 'invalid' : ''}`}
            errors={errors[field.key]}
            isOptional
            label={field.label}
            labelClass={css.label}
            name={field.key}
            onChange={onSelectOption}
            options={field.options}
            placeholder={field.placeHolder}
          />
        );
      case 'checkbox':
        return (
          <Checkbox
            className={`${errors[field.key] ? 'invalid' : ''} ${css['checkbox-authorized']}`}
            color="green"
            id="selectAll-checkbox"
            isRequired={!field.isOptional}
            label="I am legally authorized to work in the United States."
            name={field.name}
            onChange={async (e) => {
              setValue(field.name, e.target.checked);
              await trigger(field.name);
            }}
            value={field.name}
            isChecked={props.field.value}
          />
        );
      default:
        return null;
    }
  };

  const removeSpecialty = (specialtyToDelete) => {
    const oldSpecialties = formValues.specialties;

    const newSpecialties = oldSpecialties.filter(
      (specialty) => specialtyToDelete !== specialty.value
    );

    unregister(`specialties[${specialtyToDelete}]`);
    unregister(`specialties_experience[${specialtyToDelete}]`);

    newSpecialties.forEach((s) => {
      const dataDropdown = getValues(`specialties[${s.value}]`);
      const experience = getValues(`specialties_experience[${s.value}]`);

      setValue(`specialties[${s.value}]`, dataDropdown);
      setValue(`specialties_experience[${s.value}]`, experience);

      return s;
    });

    setFormValues({
      specialties: newSpecialties,
    });
  };

  const getErrors = (name, type) => {
    if (!errors?.[type]) {
      return null;
    }

    return Object.values(errors?.[type]).find(
      (error) => error?.ref?.name === name
    );
  };

  const applyFormFields = ApplyFormConfig;
  const { ...formFields } = {
    ...applyFormFields,
    years_experience_profession: {
      ...applyFormFields['years_experience_profession'],
      label:
        ['xs', 'sm'].indexOf(screenSize) !== -1
          ? 'Experience'
          : 'Years Experience',
      rules: {
        ...applyFormFields['years_experience_profession']['rules'],
        min: {
          value: requirements.requiredJobExperience || 1,
          message: requirements.professionMessage,
        },
      },
    },
  };

  return (
    <>
      <form
        className={`${css['interested-form']}`}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="row">
          {Object.values(formFields)
            .filter((f) => !f.hide)
            .map((f) => {
              const shouldRender = f.depensOn
                ? getValues(f.depensOn)?.value === f.depensValue
                : true;

              return shouldRender ? (
                <div className={f.colClass}>
                  <Controller
                    control={control}
                    defaultValue={getValues(f.key)}
                    name={f.key}
                    render={(props) => renderField(f, props)}
                    rules={f.rules}
                  />
                </div>
              ) : null;
            })}
        </div>

        {formValues.specialties.length > 0 &&
          formValues.specialties.map((specialty, index) => (
            <div key={specialty.value} className="row">
              <div
                className={`col-8 col-sm-6 order-5 order-sm-5 ${css['container-field']}`}
              >
                <Controller
                  control={control}
                  defaultValue={getValues(`specialties[${specialty.value}]`)}
                  name={`specialties[${specialty.value}]`}
                  render={(props) => (
                    <>
                      <div className="">
                        <div
                          className={`${css['specialty-container-input']} ${
                            index === 0
                              ? css['specialty-container-input-full']
                              : ''
                          }`}
                        >
                          <SpecialtyDropdown
                            classname={`${css['input-dropdown']}`}
                            errors={getErrors(
                              `specialties[${specialty.value}]`,
                              'specialties'
                            )}
                            icon={<IconSpecialties />}
                            isClearable={specialty.isClearable}
                            isControlled
                            isMulti={false}
                            label="Specialty"
                            labelClass={css.label}
                            name={`specialties[${specialty.value}]`}
                            onChange={onSelectOption}
                            profession={formValues.selectedProfession}
                            showIcon
                            showPlaceholder={false}
                            value={props.field.value}
                            showCounters={false}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  rules={
                  !specialty.isClearable
                    ? {
                    validate: (value) => typeof value !== 'undefined',
                    required: 'This field is required',
                    } : {
                      validate: (value) => {
                        const yearExp = getValues(
                        `specialties_experience[${specialty.value}]`
                        );

                        if (!yearExp) {
                          return true;
                        }
                        return !!value;
                    },
                  }
                }
                />
                {!isLargeScreen && !specialty.disallowToDelete && formValues.specialties.length > 1 && (
                  <IconDelete
                    className={css['delete-specialty-icon']}
                    onClick={() => removeSpecialty(specialty.value)}
                  />
                )}
              </div>

              <div
                className={`col-4 col-sm-6 order-5 order-sm-5 ${css['container-field']}`}
              >
                <div className="flex">
                  <Controller
                    control={control}
                    defaultValue={getValues(
                      `specialties_experience[${specialty.value}]`
                    )}
                    name={`specialties_experience[${specialty.value}]`}
                    render={(props) => (
                      <>
                        <Input
                          className={`${css['input-text']}`}
                          classNameContainer={`${css['input-text-container']}`}
                          defaultValue={props.field.value}
                          errors={getErrors(
                            `specialties_experience[${specialty.value}]`,
                            'specialties_experience'
                          )}
                          isOptional={false}
                          isRequired={!specialty.isClearable}
                          label="Years Experience"
                          labelClass={`${css.label} ${css['label-exp']}`}
                          maxLength={2}
                          name={`specialties_experience[${specialty.value}]`}
                          onChange={async (e, value) => {
                            setValue(props.field.name, value);
                            await trigger();
                          }}
                          onlyNumbers
                          type="text"
                        />
                      </>
                    )}
                    rules={
                      specialty.isClearable
                      ? {
                          maxLength: {
                            value: 2,
                            message: 'Only allow 2 numbers.',
                          },
                          min: {
                            value: 1,
                            message: ['xs', 'sm'].indexOf(screenSize) !== -1 ? `Experience 1` : `Years Experience 1`,
                          },
                          validate: (value) => {
                            const specialtySelected = getValues(
                            `specialties[${specialty.value}]`
                            );

                            if (!specialtySelected) {
                            return true;
                         }

                          return !!value;
                        },
                      }
                      : {
                        required: 'This field is required',
                        maxLength: {
                          value: 2,
                          message: 'Only allow 2 numbers.',
                        },
                        min: {
                          value: requirements?.agencyRequiredExperience,
                          message:
                            ['xs', 'sm'].indexOf(screenSize) !== -1
                              ? `Experience ${requirements?.agencyRequiredExperience}`
                              : `Years Experience ${requirements?.agencyRequiredExperience}`,
                        },
                    }
                  }
                  />
                  {isLargeScreen && !specialty.disallowToDelete && (
                    <IconDelete
                      className={`${css['delete-specialty-icon']} qaquy`}
                      onClick={() => removeSpecialty(specialty.value)}
                    />
                  )}
                </div>
              </div>
            </div>
          ))}

        <div className="col-12">
          <button
            className={`link-button ${css['add-specialties-btn']}`}
            onClick={addSpecialtyToList}
            type="button"
          >
            <AddIcon fill="#06759A" />{' '}
            <span className={'vcenter'}>ADD MORE SPECIALTIES</span>
          </button>
        </div>

        {errorMessage && (
          <div className="col-12 justify-center mt-2 alert alert-danger mb-4">
            {errorMessage}
          </div>
        )}

        <div className="col-12 text-center">
          <Button
            className={`${css['submit-btn']} ${
              !isValid ? `disabled ${css['disabled']}` : ''
            } ${isLoading ? css['loading'] : ''} m-0 py-2 px-5 cta_after_login_profile_form_im_interested`}
            type="submit"
            disabled={!isValid}
          >
            <span className={'ms-2 vcenter cta_after_login_profile_form_im_interested'}>I’m interested</span>
          </Button>
        </div>
      </form>
    </>
  );
};

export default React.memo(BasicInformationForm);
