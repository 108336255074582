var LocationTypes;
(function (JobTypes) {
  JobTypes[JobTypes["domestic"] = 1] = "domestic";
  JobTypes[JobTypes["permanent"] = 2] = "permanent";
  JobTypes[JobTypes["local"] = 3] = "local";
  JobTypes[JobTypes["irp"] = 4] = "irp";
  JobTypes[JobTypes["perDiem"] = 5] = "perDiem";
  JobTypes[JobTypes["locumTenens"] = 6] = "locumTenens";
})(LocationTypes || (LocationTypes = {}));

export default LocationTypes;