import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Switch from 'react-switch';
import NavSettings from "../../components/NavSettings/NavSettings";
import FrequencyDropdown from "../../components/FrequencyDropdown";
import ProfessionDropdown from "../../components/ProfessionDropdown";
import SpecialtyDropdown from "../../components/SpecialtyDropdown";
import LocationDropdown from "../../components/LocationDropdown";
import FacilityDropdown from "../../components/FacilityDropdown";
import DatePicker from "../../components/DatePicker/DatePicker"
import Shifts from "../../components/Shifts";
import AssignmentLenght from "../../components/Assignmenlenght";
import {AccountSettingsSelector, getPreference, savePreference} from "../../redux/slices/accountSettings";
import {addClass} from "../../helpers/dom";
import infoIcon from '../../assets/images/icons/information.svg'
import './Preferences.style.scss';
import {goToTop} from "../../helpers/utils";
import JobTypesDropdown from '../../components/JobTypesDropdown';
import SuperFetch from "../../helpers/superFetch";

const Preferences = () => {
  const dispatch = useDispatch();
  const { preference } = useSelector(AccountSettingsSelector);
  const [isAsap , setIsAsap] = useState(false);
  const [userSpecialties , setUserSpecialties] = useState([]);
  const [jobTypeValue , setJobTypeValue] = useState(null);
  const [localPreference , setLocalPreference] = useState(false);
  const [savedPreferences , setSavedPreferences] = useState(false);
  const matchMobile = window.matchMedia("(max-width: 992px)");

  useEffect(() => {
    if (matchMobile.matches) {
      addClass('.main-footer', 'visibility-hidden');
    }
  }, []);

  useEffect(() => {
    dispatch(getPreference());
  }, [dispatch])

  useEffect(() => {
    const {data} = preference;
    if (data) {
      const {is_asap, profession} = data;
      setIsAsap(is_asap);
      setLocalPreference(data);
      SuperFetch.get(`/api/v1/users/me/specialty/${profession}`)
      .then(response => {
        const _data = response.data;
        if(_data.specialties?.length){
          const latestSpecialty = _data.specialties.pop();
          setUserSpecialties([
            {
              value: latestSpecialty.id,
              label: latestSpecialty.speciality.name,
              fakeLabel: latestSpecialty.speciality.name,
            }
          ]);
        }
      });
    }
  }, [preference])

  const onClickAsap = () => {
    const oldIsAsap = isAsap;
    setIsAsap(!isAsap);

    const newLocalPreference = { ...localPreference };
    newLocalPreference.is_asap = !oldIsAsap;
    newLocalPreference.start_date = 0;

    setLocalPreference(newLocalPreference);
  }

  const onChange = (value, event, id) => {
    const newLocalPreference = { ...localPreference };

    if (id === 'start_date') {
      newLocalPreference.is_asap = false;
    }

    newLocalPreference[id] = value;
    setLocalPreference(newLocalPreference);
  }

  const removeAlert = () => {
    setTimeout(()=>{
      setSavedPreferences(false);
    }, 4000)
  }

  const savePreferences = async ( ) => {
    const data = { ...localPreference };

    const { specialties, locations, facility } = data;

    if (specialties && specialties.length > 0) {
      data.specialties = specialties.map((item) => {
        return { id: item.value, name: item.label,  profession: item.profession, profession_id: item.profession_id };
      });
    }

    if (locations && locations.length > 0) {
      data.locations = locations.map((item) => item.label);
    }

    if (facility && facility.length > 0) {
      data.hospitals = facility.map((item) => {
        return { id: item.value, name: item.label };
      });
      data.facility = facility.map((item) => {
        return { id: item.value, name: item.label };
      });
    }

    data.start_date = Math.floor(new Date(data.start_date).getTime()/1000);
    data.email_notifications = localPreference.email_notification ? '1' : '0';
    data.job_proposals = localPreference.job_proposals ? '1' : '0';
    data.job_type = localPreference.job_type;

    data.all = JSON.stringify({ ...data });

    await savePreference(data);
    setSavedPreferences(true);
    goToTop();
    removeAlert();
  }

  return (
    <div className="padding-sticky-bar">
      <section className="full-width">
        <NavSettings />
      </section>
      <section className={`full-width`}>
        <div className="container preferences-settings">
          <div className="row">
            {savedPreferences && <div className="col-sm-12 alert alert-success">
              Changes saved successfully!
            </div>}
          </div>
          <div className="row">
            <div className="col-sm-12">
              <h1 className="tooltip-text bolded title">Job Proposals</h1>
            </div>
            <div className="col-8">
              <p className="info">
                Allow recruiters to send jobs with transparent pay packages directly to your Wanderly inbox. Your identity will remain anonymous <b>unless you’ve applied to the agency (via Wanderly) or shared your contact info with a recruiter from that agency.</b>
              </p>
            </div>
            <div className="col-4 switch-container">
              <Switch
                activeBoxShadow={null}
                checked={localPreference?.job_proposals}
                checkedIcon={false}
                handleDiameter={18}
                height={22}
                id={'job_proposals'}
                offColor="#A2A5AC"
                onChange={onChange}
                onColor="#00B5CF"
                uncheckedIcon={false}
                width={40}
              />
            </div>
            <div className="col-md-12">
              <hr className="divider" />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <h1 className="tooltip-text bolded title">Email Job Alerts</h1>
            </div>
            <div className="col-8">
              <p className="info">
                Receive job recommendations to your email based on your profession, specialties, preferred pay package
                criteria, and your search history.
              </p>
            </div>
            <div className="col-4 switch-container">
              <Switch
                activeBoxShadow={null}
                checked={localPreference?.email_notification}
                checkedIcon={false}
                handleDiameter={18}
                height={22}
                id={'email_notification'}
                offColor="#A2A5AC"
                onChange={onChange}
                onColor="#00B5CF"
                uncheckedIcon={false}
                width={40}
              />
            </div>
            <div className="col-sm-12 col-lg-4">
              <FrequencyDropdown value={ localPreference?.email_frequency } onChange={onChange} />
            </div>
            <div className="col-sm-12">
              <hr className="divider" />
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-sm-12">
              <h1 className="tooltip-text bolded title mb-3">Job Criteria</h1>
            </div>
            <div className="col-sm-12 col-lg-4 profession-container">
              <label className="label">Job Type</label>
              <JobTypesDropdown value={ localPreference?.job_type } onChange={onChange} isControlled={true} />
            </div>
            <div className="col-sm-12 col-lg-8">
              <label className="label">Locations</label>
              <LocationDropdown  value={ localPreference?.locations }
                                 onChange={onChange}
                                 isControlled={true} />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-sm-12 col-lg-4">
              <label className="label">Profession</label>
              <ProfessionDropdown 
                value={ localPreference?.profession } 
                onChange={onChange} 
                isControlled={true} 
                isDisabled={true}
              />
              <div style={{marginTop:'10px', display: 'flex'}}>
                <img src={infoIcon} alt="infoText" style={{width:'15px', height: '15px'}}/>
                <span className="infoText">Visit <a target="_blank" href="/nurse/profile/professional?editProfession=true">profile page</a> to update profession and Specialty</span>
              </div>
            </div>
            <div className="col-sm-12 col-lg-8">
              <label className="label">Specialties</label>
              <SpecialtyDropdown
                placeholder="Enter Specialties, e.g. ICU, OB Tech, etc."
                value={ userSpecialties }
                onChange={onChange}
                isControlled={true}
                profession={localPreference?.profession}
                isDisabled={true}
              />
              <div style={{marginTop:'10px', display: 'flex'}}>
                <img src={infoIcon} alt="infoText" style={{width:'15px', height: '15px'}}/>
                <span className="infoText">Visit <a target="_blank" href="/nurse/profile/professional?editProfession=true">profile page</a> to update profession and Specialty</span>
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-sm-12 col-lg-4">
              <label className="label">Availability</label>
              <div className="filter-date">
                <DatePicker
                  className="full-width"
                  minDate={new Date()}
                  selected={localPreference?.start_date}
                  onChange={(date) => { onChange( date,  '', 'start_date') }}
                />
                <button
                  className={`link-button nurse-search-control ${localPreference?.is_asap ? 'active' : ''}`}
                  onClick={onClickAsap}>asap</button>
              </div>
            </div>
            <div className="col-sm-12 col-lg-8">
              <label className="label">Facilities</label>
              <FacilityDropdown value={ localPreference?.facility }
                                 onChange={onChange}
                                 isControlled={true} />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-sm-12 col-lg-4">
              <Shifts values={localPreference?.shifts} onChange={onChange}  />
            </div>
            <div className="col-sm-12 col-lg-4" >
              <AssignmentLenght values={localPreference?.assignment} onChange={onChange} />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-sm-12 col-lg-4">
              <button className="btn btn-black search-action full-width hide-mobile" style={{ color : 'white'}} onClick={savePreferences}>
                SAVE
              </button>
            </div>
            <div className="col-sm-12 col-lg-8" />
          </div>
        </div>
      </section>

      <footer className="footer-button only-mobile-md">
        <div className="row">
          <div className="col-sm-10 offset-sm-1">
            <button className="btn btn-black search-action full-width" style={{marginTop: '20px', color : 'white'}} onClick={savePreferences}>
              save
            </button>
          </div>
        </div>

      </footer>
    </div>
  );
}

export default Preferences;