import ReactTooltip from 'react-tooltip';
import { getRandom } from '../../helpers/utils';
import InfoIcon from '../../assets/images/icons/info.svg';

const TagJobCard = ({ job }) => {
  if (!job) return null;
  let type;
  const config = {
    rapidResponse: {
      msg: 'Rapid Response ® indicates an urgent need for immediate placement. Candidates are expected to be ready to begin the assignment within one week or less with minimal orientation.',
      title: 'Rapid Response ®',
      styleClass: 'rapid-response',
    },
    featured: {
      msg: null,
      title: 'Direct Contract',
      styleClass: 'featured',
    },
    featuredNoNFlexUp: {
      msg: null,
      title: 'Featured',
      styleClass: 'featured',
    },
    RapidStaff: {
      msg: 'RapidStaff jobs are short-term assignments created to ensure there are replacements for health care professionals that go on strike.',
      title: 'RapidStaff',
      styleClass: 'rapid-staff',
    },
    PotentialJob: {
      msg: 'Potential Job Action jobs are short-term assignments created to ensure there are replacements for health care professionals that go on strike. ',
      title: 'Potential Job Action',
      styleClass: 'workstoppage',
    },
    Interim: {
      msg: 'Clinical and Non-Clinical Leadership roles within the hospital setting. Charge, supervisor or manager level experience within the specific clinical specialty is required.',
      title: 'Interim',
      styleClass: 'interim',
    },
    Crisis: {
      msg: 'Aya Healthcare has rapid response positions throughout the country. 4 - 13 week contracts. Hospitals need you. Apply now!',
      title: 'Crisis Response',
      styleClass: 'crisis',
    },
  };

  if (job.is_rapid_response) {
    if (job.is_rapidstaff) {
      type = 'RapidStaff';
    } else if (job.is_work_stoppage) {
      type = 'PotentialJob';
    } else if (job.is_interim) {
      type = 'Interim';
    } else if (job.is_aya) {
      type = 'Crisis';
    } else {
      type = 'rapidResponse';
    }
  }

  // Check if the job is a featured job
  if (job.is_featured) {
    if (job.agency_id === '258') {
      // If the agency ID matches '258' (flexup agency), mark as 'featured'
      type = 'featured';
    } else {
      // If the agency ID doesn't match '258', mark as 'featuredNoNFlexUp'
      // Note: This approach may involve hardcoding the agency ID, but it's necessary based on the available data
      type = 'featuredNoNFlexUp';
    }
  }

  const tagInfo = config[type];
  if (!tagInfo) return null;
  const random = getRandom();

  return (
    <div className="tag-container">
      <div className={`tag-container--job ${tagInfo.styleClass}`}>
        {tagInfo.msg ? (
          <>
            <ReactTooltip
              className="rc-tooltip"
              effect="solid"
              multiline={true}
              id={`icon-${random}`}
            >
              {tagInfo.msg}
            </ReactTooltip>
            <img
              src={InfoIcon}
              data-tip
              data-for={`icon-${random}`}
              className="info-icon"
              alt="info"
            />
            <span>{tagInfo.title}</span>
          </>
        ) : (
          <>
            <span>{tagInfo.title}</span>
          </>
        )}
      </div>
    </div>
  );
};

export default TagJobCard;
