import { ProfessionDropdown as ProfessionLegacyDropdown } from '@orionhcs/wanda-js-components';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { JobsSelector, setFilters, setShowProfessionUser } from '../../../redux/slices/jobs';
import { getProfessions, professionsSelector } from '../../../redux/slices/professions';
import { setSpecialitiesOptionsDropdown } from '../../../redux/slices/specialties';

import './ProfessionDropdown.scss';

const ProfessionDropdown = ({
  value,
  onChange,
  isControlled = false,
  errors,
  name = 'profession',
  label = false,
  labelClass = '',
  className = '',
  showPlaceholder = true,
  showIndicator = true,
  isDisabled= false,
}) => {
  const dispatch = useDispatch();
  const { professions, professionsOriginal } = useSelector(professionsSelector);
  const { filters: { profession_id } = {} } = useSelector(JobsSelector);

  useEffect(() => {
    if (!professions || professions.length <= 0) {
      dispatch(getProfessions());
    }
  }, [dispatch]);

  const onSelectOption = (value) => {
    if (isControlled) {
      onChange(value ? value.value : null, '', name);
    } else {
      dispatch(
        setFilters({
          profession_id: value,
          specialty_ids: null,
        })
      );

      dispatch(setShowProfessionUser(false));
      dispatch(setSpecialitiesOptionsDropdown([]));
    }
  };

  const onClear = () => () => {
    dispatch(
      setFilters({
        profession_id: null,
      })
    );
  };

  return (
    <>
      {label && (
        <label className={`label ${labelClass}`}>
          Profession<span className="required-asterisk">*</span>
        </label>
      )}
      <ProfessionLegacyDropdown
        className={className}
        isControlled={isControlled}
        isDisabled={isDisabled}
        name="profession"
        onClear={onClear}
        onSelectOption={onSelectOption}
        options={professionsOriginal}
        professionId={profession_id}
        showIcon={false}
        showIndicator={showIndicator}
        showPlaceholder={showPlaceholder}
        value={value}
      />
      {errors && (
        <div className="invalid-message" style={{ display: 'block' }}>
          {errors.message}
        </div>
      )}
    </>
  );
};

export default ProfessionDropdown;
