import React, {useRef} from 'react';
import DropdownControl from "../DropdownControl";

import './FrequencyDropdown.scss'

const FrequencyDropdown = ( { value, onChange }) => {
  const options = [
    {
      value: 'twice_weekly',
      label: 'Twice a week - Highest Pay and Most Recent Jobs'
    },
    {
      value: 'once_weekly',
      label: 'Once a week - Most Recent Jobs'
    },
    {
      value: 'once_monthly',
      label: 'Once a month - Most Recent Jobs'
    }
  ];
  const dropdownRef = useRef(null);


  const onSelectOption = (value) => {
    onChange(value.value, '', 'email_frequency')
  };

  const ClearIndicator = ({ children, ...props }) => {
    return null
  }

  return (
    <DropdownControl
      icon={null}
      classname="frequency"
      options={options}
      placeholder={'Select Frequency'}
      onChange={onSelectOption}
      name="frequency"
      value={ value  ? options.filter((option) => option.value === value) : null}
      isClearable
      ref={dropdownRef}
      customComponents={{
        ClearIndicator
      }}
    />
  );
}

export default FrequencyDropdown;
