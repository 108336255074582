import React from 'react';
import { format } from 'date-fns';
import {
  formatPayRate,
  getExperienceLevel,
  getFullProfessionLabel,
  getIconShift,
  getPaymentTypeAbbr,
  getProfession,
  getTypeJobJob, markAsInterestedButton, myMD5,
  paramsToUrl,
} from '../../helpers/utils';
import TagJobCard from './TagJobCard';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as BedIconDarken } from '../../assets/images/icons/darken/bedsJobCard.svg';
import { ReactComponent as DateIconDarken } from '../../assets/images/icons/darken/dateJobCard.svg';
import { ReactComponent as DurationIconDarken } from '../../assets/images/icons/darken/durationJobCard.svg';
import { ReactComponent as ExperienceLevelIconDarken } from '../../assets/images/icons/darken/expLevelJobCard.svg';
import { ReactComponent as HospitalIconDarken } from '../../assets/images/icons/darken/hospitalJobCard.svg';
import { ReactComponent as CheckmarkIcon } from '../../assets/images/icons/checkmarkWhite.svg';

import { setIsRedirection } from '../../redux/slices/jobs';
import { setEligibilityData, setSelectedJob } from '../../redux/slices/job';
import ReactTooltip from 'react-tooltip';
import { sendAnalytics } from '../../helpers/requests';

import './JobCard.style.scss';
import {
  getInterestedJobs,
  minimumInformationCheck,
  sendApplication,
  sendInterestedBatch,
} from '../BasicInformationForm/applicationRequests';
import { hasAccessToken } from '../../helpers/auth';
import SuperFetch from '../../helpers/superFetch';
import { getUserData } from '../../redux/slices/nurse';
import { formatNumberWithCommas } from '../../utils/textFormat';

const JobCard = ({
  job,
  interestedJobs,
  onInterestedAction,
  filters,
  page,
  toggleInterestedModal,
  toggleSimilarJobsModal
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const totalPayPackages = useSelector((state) => state.jobs.payPackages);
  const _$ = window.jQuery;

  const getRate = () => {
    const isHourly = job.payment_type == 'hourly';

    if (!job.hide_pay_rate) {
      if (!job.pay_packages) {
        return (
          <>
            <div className="sign">$</div>
            <span className="price">{isHourly ? formatPayRate(job.payment_min) : formatPayRate(+job.payment_min)}</span>
            <small>/{getPaymentTypeAbbr(job.payment_type)}</small>
          </>
        );
      } else {
        const minPayment = isHourly ? formatPayRate(job.payment_min) : formatPayRate(+job.payment_min);
        const maxPayment = isHourly ? formatPayRate(job.payment_max) : formatPayRate(+job.payment_max);

        return (
          <>
            {maxPayment > minPayment ? (
              <>
                {minPayment > 0 && (
                  <>
                    <div className="sign">$</div>
                    <span className="price">{formatNumberWithCommas(minPayment)}</span>
                  </>
                )}
                {maxPayment > 0 && (
                  <>
                    <span className="separator"> - </span>
                    <div className="sign">$</div>
                    <span className="price">{formatNumberWithCommas(maxPayment)}</span>
                  </>
                )}
              </>
            ) : (
              <>
                <div className="sign">$</div>
                <span className="price">{formatNumberWithCommas(minPayment)}</span>
              </>
            )}
            <small>/{getPaymentTypeAbbr(job.payment_type)}</small>
          </>
        );
      }
    } else {
      return (
        <>
          <span className="price-contact">
            {job.hidden_pay_rate_text
              ? job.hidden_pay_rate_text
              : 'Contact recruiter for more details'}
          </span>
        </>
      );
    }
  };

  const isEnabledInterestedFlow = (job) => {
    if (job.hasOwnProperty('is_enabled_interested_flow')) {
      return job.is_enabled_interested_flow;
    }
    // as default return true
    return true;
  };

  const isAgencyAllowsInteractions = (job) => {
    if (job.hasOwnProperty('agency_allows_interactions')) {
      return job.agency_allows_interactions;
    }
    // as default return true
    return true;
  };

  const getInterestedBtn = (onlyMobile = false) => {
    const isInterested =
      interestedJobs &&
      job.pay_package_ids.some((job) => interestedJobs.includes(job));
    return (
      <button
        type="button"
        onClick={async (e) => {
          e.preventDefault();
          // If user is logged in, trigger I'm Interested logic
          // else, open Authentication Modal
          if (window.loggedInUser || hasAccessToken()) {
            let jobIds = job.pay_package_ids && job.pay_package_ids.length > 1 ? job.pay_package_ids : [job.job_id];
            _$(e.target).addClass('loading');
            dispatch(setSelectedJob(job));

            // If Agency's Job has configured redirect on apply, we have to show Modal and mark job as interested
            // Else, we have to validate if candidate has minimum required information
            if (job.apply_now_redirect_url) {
              jobIds = jobIds.filter(id => !window.interestedJobs.includes(id));

              // Toggle Similar Jobs Modal
              toggleSimilarJobsModal();
              // Mark job(s) as interested
              await sendInterestedBatch(jobIds, false);
              window.interestedJobs = await getInterestedJobs();

              /*if (_$('#candidateRedirectionModal .agency-name').length) {
                _$('#candidateRedirectionModal .agency-name').text(job.agency_name);
              }
              if (_$('#redirection-continue-btn').length) {
                _$('#redirection-continue-btn')
                  .data('redirectionType', 'apply')
                  .data('jobId', job.job_id);
              }
              _$('#candidateRedirectionModal').modal('show');
              _$('.modal-backdrop').addClass('authModal');*/
              markAsInterestedButton(_$(e.target));
            } else {
              const response = await minimumInformationCheck(job.job_id);
              const hasMinimumInformation = response.able_to_apply;
              dispatch(setEligibilityData(response))
              // If candidate doesn't have minimum required information, we have to display Basic Information Modal
              // else, if user HAS minimum required information, we have to send application on background
              if (!hasMinimumInformation) {
                await dispatch(getUserData());
                // Display Basic Information Modal
                toggleInterestedModal();
              } else {
                // Toggle Similar Jobs Modal
                if (isAgencyAllowsInteractions(job) === false) {
                  toggleSimilarJobsModal();
                }
                const applicationResponse = await sendApplication({job_ids: job.pay_package_ids}, false, false, 3);
                const { success: successApplication } = applicationResponse;
                if (successApplication) {
                  if (isAgencyAllowsInteractions(job) === true) {
                    markAsInterestedButton(_$(e.target));
                    await sendInterestedBatch(job.pay_package_ids, false);
                    window.interestedJobs = await getInterestedJobs();

                    _$(document).trigger('ShowSuccessCheckmarkEvt', 2500);
                    _$.cookie('questionarymodalshown', myMD5(window.loggedInUser), {
                      path: '/',
                    });
                  }
                } else {
                  console.log(applicationResponse);
                }
              }
            }
            _$(e.target).removeClass('loading');
          } else {
            const jobsString = job.pay_package_ids.join(',');
            const paramsSearch = paramsToUrl(filters);
            const url = window.location.origin + window.location.pathname + '?interestedIn=' + jobsString + '&' + paramsSearch.slice(0, -1);

            SuperFetch.post('/nurse/auth/session', {
              loginUrl: url,
            }).then(() => {
              const redirectUrl = window.location.origin + window.location.pathname + '?interestedIn=' + jobsString + '&' + paramsSearch;
              window.config = window.config || {};
              window.config.loginUrl = redirectUrl;
              _$('#redirectInput').val(redirectUrl);
              _$('#authModal').modal('show');
              _$('.modal-backdrop').addClass('authModal');
            });
          }
        }}
        className={`btn-black btn-interested interest-${job.id} ${
          onlyMobile ? 'only-mobile' : ''
        } ${isInterested ? 'active mark-interested' : ''} ${window.isLogged ? 'cta_after_login_im_interested' : 'cta_before_login_im_interested'}`}
        disabled={isInterested}
        data-jobIdSingle={job.job_id}
        data-isInterestedButton={true}
        data-jobId={job.pay_package_ids ? job.pay_package_ids.join(',') : job.id}
      >
        {isInterested ? (
          <>
            <CheckmarkIcon /> Interested
          </>
        ) : (
          "I'm Interested"
        )}
      </button>
    );
  };

  const makeCompare = async (e) => {
    const isInterestedProcess = e.target.getAttribute(
      'data-isInterestedButton'
    );
    // it will send request if it's interested or just a job card request
    sendAnalytics(job.pay_package_ids[0], job.payment_min, totalPayPackages);
    if (!isInterestedProcess) {
      dispatch(setIsRedirection(true));
    }
  };

  const getPayPackagesBanner = () => {
    return (
      <div className="full-width">
        <div className="pay-package-number">{job.pay_packages}</div>
        <div className="pay-package-label">
          {'package' + (job.pay_packages > 1 ? 's' : '')}
        </div>
      </div>
    );
  };

  const startDateNoTz = (start_date) => {
    var dTimezone = new Date();
    var offset = dTimezone.getTimezoneOffset() / 60;
    var date = new Date(Date.parse(start_date));
    date.setHours(date.getHours() + offset);
    return date;
  };

  const showTag = () => {
    // Show tag when the job is not featured;
    // If it's featured, we need to show the tag when no specialties are selected in the filter,
    // or the job has the same specialty as the selected specialty in the filter.

    // Get the selected specialties in the filter.
    let specialties = filters.specialty_ids;

    // for non-flexup and featured jobs
    if (job.agency_id !== '258' && job.is_featured) {
      return true;
    }

    // The function returns true if the job is not featured.
    if (!job.is_featured) {
      return true;
    }

    // The function also returns true if no specialties are specified (or the list is empty),
    // as in that case, all jobs are valid.
    if (!specialties || !specialties.length) {
      return true;
    }

    // The function returns true if at least one of the job's specialties matches the required specialties.
    if (specialties.some((elem) => job.specialty_ids.includes(elem.value))) {
      return true;
    }

    // If none of the above conditions are met, the job should not show the tag.
    return false;
  };

  const getAssignmentLength = (job) => {
    const jobType = +job.job_type;

    return (
      <>
        {jobType === 1 ? (
          <>
            <DurationIconDarken className="icon" /> {job.assignment_length}{' '}
            weeks
          </>
        ) : (
          <>
            <DurationIconDarken className="icon" />
            {jobType === 4 || jobType === 5 ? (
              <>
                {job.assignment_length > 0 ? (
                  <>
                    {job.assignment_length}
                    {job.assignment_length_type === 2 ? (
                      <>{job.assignment_length > 1 ? ' days' : ' day'}</>
                    ) : (
                      <>{job.assignment_length > 1 ? ' weeks' : ' week'}</>
                    )}
                  </>
                ) : (
                  'Ongoing'
                )}
              </>
            ) : (
              <>
                <ExperienceLevelIconDarken className="icon" />
                {getExperienceLevel(+job.experience_level)}
              </>
            )}
          </>
        )}
      </>
    );
  };

  const getRequirements = (job) => {
    const {
      id,
      job_type,
      location_city,
      start_date,
      experience_level,
      assignment_length,
      shifts_per_week,
      shift,
      hours_per_shift,
      beds,
      facility_name,
      shift_type,
      employment_type,
    } = job;

    const jobTypes = {
      1: () => {
        // travel
        return (
          <>
            <div>
              <HospitalIconDarken className="icon" /> {beds == 0 ? 'N/A' : `${beds} Beds`}
            </div>
            <div>
              <DurationIconDarken className="icon" /> {assignment_length} weeks
            </div>
            <div>
              {getIconShift(shift)}
              {shift == 'Rotating'
                ? 'Rot'
                : shift == 'Midshift'
                ? 'Mid'
                : shift}
              , {shifts_per_week} <small>x</small> {parseFloat(hours_per_shift)}{' '}
              hr
            </div>
            <div>
              <DateIconDarken className="icon" />{' '}
              {!start_date
                ? 'ASAP'
                : format(startDateNoTz(start_date), 'MMM d, yyyy')}
            </div>
          </>
        );
      },
      2: () => {
        // permanent
        return (
          <>
            <div>
              <BedIconDarken className="icon" /> {beds == 0 ? 'N/A' : `${beds} Beds`}
            </div>
            <div>
              <ExperienceLevelIconDarken className="icon" />{' '}
              {getExperienceLevel(experience_level)}
            </div>
            <div>
              {getIconShift(shift)}
              {shift == 'Rotating'
                ? 'Rot'
                : shift == 'Midshift'
                ? 'Mid'
                : shift}
              , {shifts_per_week} <small>x</small> {parseFloat(hours_per_shift)}{' '}
              hr
            </div>
            <div>
              <ReactTooltip
                className="rc-tooltip"
                effect="solid"
                multiline={true}
                id={`hospitals-${id}`}
              >
                {facility_name} - {location_city}
              </ReactTooltip>
              <div data-tip data-for={`hospitals-${id}`}>
                <HospitalIconDarken className="icon" /> {facility_name}
              </div>
            </div>
          </>
        );
      },
      3: () => {
        // local
        return (
          <>
            <div>
              <HospitalIconDarken className="icon" /> {job.beds == 0 ? 'N/A' : `${job.beds} Beds`}
            </div>
            <div>
              <DurationIconDarken className="icon" /> {job.assignment_length}{' '}
              weeks
            </div>
            <div>
              {getIconShift(shift)}
              {shift == 'Rotating'
                ? 'Rot'
                : shift == 'Midshift'
                ? 'Mid'
                : shift}
              , {shifts_per_week} <small>x</small> {parseFloat(hours_per_shift)}{' '}
              hr
            </div>
            <div>
              <DateIconDarken className="icon" />{' '}
              {!start_date
                ? 'ASAP'
                : format(startDateNoTz(start_date), 'MMM d, yyyy')}
            </div>
          </>
        );
      },
      4: () => {
        // irp
        return (
          <>
            <div>
              <HospitalIconDarken className="icon" /> {beds == 0 ? 'N/A' : `${beds} Beds`}
            </div>
            <div>{getAssignmentLength(job)}</div>
            <div>
              {getIconShift(shift)}
              {+shift_type !== 0
                ? +shift_type === 1
                  ? 'Reg'
                  : 'Wknd'
                : ''}{' '}
              {shift == 'Rotating'
                ? 'Rot'
                : shift == 'Midshift'
                ? 'Mid'
                : shift}
              , {shifts_per_week} <small>x</small> {parseFloat(hours_per_shift)}{' '}
              hr
            </div>
            <div>
              <DateIconDarken className="icon" />{' '}
              {!start_date
                ? 'ASAP'
                : format(startDateNoTz(start_date), 'MMM d, yyyy')}
            </div>
          </>
        );
      },
      5: () => {
        // perdiem
        return (
          <>
            <div>
              <HospitalIconDarken className="icon" /> {job.beds == 0 ? 'N/A' : `${job.beds} Beds`}
            </div>
            <div>{getAssignmentLength(job)}</div>
            <div>
              {getIconShift(shift)}
              {+shift_type !== 0
                ? +shift_type === 1
                  ? 'Reg'
                  : 'Wknd'
                : ''}{' '}
              {shift == 'Rotating'
                ? 'Rot'
                : shift == 'Midshift'
                ? 'Mid'
                : shift}
              , {shifts_per_week} <small>x</small> {parseFloat(hours_per_shift)}{' '}
              hr
            </div>
            <div>
              <DateIconDarken className="icon" />{' '}
              {!start_date
                ? 'ASAP'
                : format(startDateNoTz(start_date), 'MMM d, yyyy')}
            </div>
          </>
        );
      },
    };

    return jobTypes[job_type]();
  };

  const getEmploymentType = (employmentType) => {
    const config = {
      1: 'Full Time',
      2: 'Part Time',
      3: 'PRN',
    };

    return config[employmentType] || '';
  };

  return (
    <>
      <a
        href={`/nurse/jobs/${job.slug}/${job.pay_package_ids.join(',')}`}
        className="job-card cta_before_login_job_card"
        onClick={(e) => {
          makeCompare(e);
        }}
        data-interestedProcess={false}
      >
        {showTag() && <TagJobCard job={job} />}
        <div className="white-container">
          <div className="pay-package-container">
            {job.pay_packages > 1 ? (
              getPayPackagesBanner()
            ) : job?.agency_logo ? (
              <img className="img-responsive" src={job?.agency_logo} />
            ) : (
              getPayPackagesBanner()
            )}
          </div>
          <div className="job-info">
            <div className="job-info--location">
              <div className="flex-row overview-info">
                <span className="profession">
                  <ReactTooltip
                    className="rc-tooltip"
                    effect="solid"
                    multiline={true}
                    id={`icon-${job.id}`}
                  >
                    {getFullProfessionLabel(+job.job_type)}
                  </ReactTooltip>
                  {getTypeJobJob(+job.job_type, job.id)}{' '}
                  {getProfession(job.profession_id)}
                </span>

                <ReactTooltip
                  className="rc-tooltip"
                  effect="solid"
                  multiline={true}
                  id={`specialties-${job.id}`}
                >
                  {job.specialty.join(', ')}
                </ReactTooltip>
                <span
                  className="specialties"
                  data-tip
                  data-for={`specialties-${job.id}`}
                >
                  {job.specialty.map((item, index) => (
                    <span key={index}>• {item}</span>
                  ))}
                </span>

                {job.employment_type !== 0 &&
                  (+job.job_type === 4 || +job.job_type === 5) && (
                    <span
                      className={`employment_type employment_type--${job.employment_type}`}
                    >
                      {getEmploymentType(job.employment_type)}
                    </span>
                  )}
              </div>

              <div className="flex-row">
                <div className="location">
                  {job.location_city}, {job.location_state}
                </div>
              </div>
            </div>
            <div className="job-info--requirements">{getRequirements(job)}</div>
          </div>
          {isEnabledInterestedFlow(job) === true || isAgencyAllowsInteractions(job) === false ? (
            <div className="interested-container">{getInterestedBtn()}</div>
          ) : null}
        </div>
        <div className="gray-container full-width">
          <div className="price-container">{getRate()}</div>
          {isEnabledInterestedFlow(job) === true || isAgencyAllowsInteractions(job) === false ? (
            <div className="interested-container">{getInterestedBtn(true)}</div>
          ) : null}
        </div>
      </a>
    </>
  );
};

export default JobCard;
