import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {setAssignment} from "../../redux/slices/jobs";

import './assignment.style.scss';

const AssignmentLenght = ( { values = [], onChange } ) => {
  const dispatch = useDispatch();
  const [localValues, setLocalValues] = useState(values ? values : []);

  useEffect(() => {
    if (values && values.length > 0) {
      setLocalValues(values);
    }
  }, [values]);

  const onClick = (shift) => {
    let newValues = [];
    if (localValues && localValues.some((value) => value === shift)) {
      newValues = localValues.filter((value) => value !== shift);
    } else {
      newValues = [...localValues, shift]
    }

    onChange( newValues, '', 'assignment')
    setLocalValues(newValues);
  }

  return (<>
    <label className="label">Assignment Length (weeks)</label>
    <div className="assignment-container control-container" >
      <button
        type="button"
        className={`link-button nurse-search-control assignment-button ${localValues.includes(12) ? 'active' : ''} `}
        onClick={() => { onClick(12) }}
      >
        1-12
      </button>
      <button
        type="button"
        className={`link-button nurse-search-control assignment-button ${localValues.includes(13) ? 'active' : ''} `}
        onClick={() => { onClick(13) }}>
        13
      </button>
      <button
        type="button"
        className={`link-button nurse-search-control assignment-button ${localValues.includes(14) ? 'active' : ''} `}
        onClick={() => { onClick(14) }}>
        14+
      </button>
    </div>
  </>);
}

export default AssignmentLenght;
